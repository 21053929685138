//admin permissions
export const super_admin_only = 'super_admin_only';
export const manage_system_configuration = 'manage_system_configuration';
export const manage_facility_users = 'manage_facility_users';
export const manage_facility = 'manage_facility';
export const manage_organization = 'manage_organization';
export const manage_partners = 'manage_partners';
export const view_partners = 'view_partners';
export const manage_roles = 'manage_roles';
export const manage_compliance_settings = 'manage_compliance_settings';
export const manage_state_integrations = 'manage_state_integrations';
export const manage_alerts = 'manage_alerts';
export const manage_custom_fields = 'manage_custom_fields';
export const print_labels = 'print_labels';
export const manage_third_party_integrations = 'manage_third_party_integrations';
export const manage_internal_transfers = 'manage_internal_transfers';
export const manage_default_facility_login = 'manage_default_facility_login';
export const manage_inventory_audits = 'manage_inventory_audits';
export const manage_inventory_sync = 'manage_inventory_sync';

//cultivation permissions
export const manage_plants = 'manage_plants';
export const create_provisional_plants =  'create_provisional_plants';
export const create_plants = 'create_plants';
export const view_plants = 'view_plants';
export const process_plants = 'process_plants';

export const view_harvests = 'view_harvests';
export const manage_harvests = 'manage_harvests';

export const manage_organization_strains = 'manage_organization_strains';
export const manage_facility_strains = 'manage_facility_strains';

export const manage_feeding_schedules = 'manage_feeding_schedules';
export const manage_cultivation_locations = 'manage_cultivation_locations';
export const manage_package_sizes = 'manage_package_sizes';
export const manage_ingredients = 'manage_ingredients';

export const manage_testing = 'manage_testing';
export const manage_lab_testing = 'manage_lab_testing';
export const manage_plant_testing = 'manage_plant_batch_testing';
export const view_testing = 'view_testing';

export const manage_cultivation_compliance = 'manage_cultivation_compliance';
export const manage_plant_lineage = 'manage_plant_lineage';

//inventory
export const view_products = 'view_products';
export const manage_products  = 'manage_products';
export const manage_product_subcategories = 'manage_product_subcategories';
export const mass_update_products = 'mass_update_products';

export const view_inventory = 'view_inventory';
export const manage_inventory = 'manage_inventory';
export const manage_shared_inventory = 'manage_shared_inventory';

export const manage_storage_locations = 'manage_storage_locations';
export const manage_reservations = 'manage_reservations';

export const manage_package_cost = 'manage_package_cost';
export const manage_inventory_receipt_package_cost = 'manage_inventory_receipt_package_cost';

// notification
export const view_notifications = 'view_notifications';

//supply chain
export const manage_inventory_transfers = 'manage_inventory_transfers';
export const manage_inventory_receipts = 'manage_inventory_receipts';
export const manage_sales_orders = 'manage_sales_orders';
export const manage_purchase_orders = 'manage_purchase_orders';
export const manually_adjust_package_item = 'manually_adjust_package_item';
export const manage_reconciliations = 'manage_reconciliations';

//reporting
export const view_reports = 'view_reports';
export const view_tier2_reports = 'view_tier2_reports';
export const view_tier3_reports = 'view_tier3_reports';
export const view_tier4_reports = 'view_tier4_reports';
export const manage_accounting = 'manage_accounting';
export const analytics_reporting = 'analytics_reporting';
export const analytics_custom_reporting = 'analytics_custom_reporting';
export const manage_report_inventory_transactions = 'manage_report_inventory_transactions';
export const manage_report_current_inventory = 'manage_report_current_inventory';
export const manage_report_product_sales = 'manage_report_product_sales';
export const manage_report_metrc_sales = 'manage_report_metrc_sales';
export const manage_report_cash_on_hand = 'manage_report_cash_on_hand';
export const manage_report_canada = 'manage_report_canada';
export const manage_report_oklahoma = 'manage_report_oklahoma';

// retail reporting
export const manage_report_daily_transactions = 'request_daily_transaction_report';
export const manage_report_closing = 'manage_closing_report';
export const manage_report_sales_summary = 'manage_report_sales_summary';

//retail
export const manage_autoprice = 'manage_autoprice';
export const manage_customer_groups = 'manage_customer_groups';
export const view_queue = 'view_queue';
export const manage_customers = 'manage_customers';
export const view_customers = 'view_customers';
export const manage_registers = 'manage_registers';
export const verify_register_count = 'verify_register_count';
export const reopen_closed_register = 'reopen_closed_register';
export const manage_taxes = 'manage_taxes';
export const manage_pricing_classes = 'manage_pricing_class';
export const manage_pricing_groups =  'manage_pricing_groups';
export const manage_pricing_multipliers =  'manage_pricing_multipliers';
export const manage_sales_locations = 'manage_sales_locations';
export const apply_manual_discounts = 'apply_manual_discounts';
export const manage_physicians = 'manage_physicians';
export const manage_retail_compliance = 'manage_retail_compliance';
export const manual_sales_import = 'manual_sales_import';
export const manage_anonymous_orders = 'manage_anonymous_orders';
export const manage_retail_delivery_orders = 'manage_retail_delivery_orders';

//Menu

export const view_registers = 'view_registers';
export const manage_drivers = 'manage_drivers';
export const manage_equipment = 'manage_equipment';
export const manage_labels = 'manage_labels';

export const manage_supplies = 'manage_supplies';
export const manage_vehicles = 'manage_vehicles';

export const manage_plant_costing = 'manage_plant_costing';
export const manage_tasks = 'manage_tasks';
export const manage_forcasting = 'manage_forcasting';
export const rate_products = 'rate_products';

export const manage_orders = 'manage_orders';
export const cancel_locked_orders = 'cancel_locked_orders';
export const create_customers = 'create_customers';
export const edit_customers = 'edit_customers';
export const manage_queue = 'manage_queue';
export const create_orders = 'create_orders';
export const manage_users = 'manage_users';
export const manage_coupons = 'manage_coupons';
export const manage_rewards = 'manage_rewards';
export const manage_tracking = 'manage_tracking';

export const manage_pricing_weights = 'manage_pricing_weights';
export const manage_credit_card_integration = 'manage_credit_card_integration';
export const forecasting_yield_management = 'forecasting_yield_management';

// History
export const view_plant_history = 'view_plant_history';
export const view_inventory_history = 'view_inventory_history';
export const view_harvest_history = 'view_harvest_history';

//Manufacturing permissions

export const define_processing = 'define_processing';
export const manage_processing = 'manage_processing';
export const manage_assemblies = 'manage_assemblies';
export const manage_infusions = 'manage_infusions';
export const manage_preferred_mix = 'manage_preferred_mix';
export const manage_categories = 'manage_categories';
export const manage_manufacturing_compliance = 'manage_manufacturing_compliance';

//Merchant Account permissions
export const manage_merchant_accounts = 'manage_merchant_accounts';

// Closed-Loop Admin Permissions
export const manage_quarantined_inventory = 'manage_quarantined_inventory';
export const view_regulated_facility_list = 'view_regulated_facility_list';
export const manage_initial_inventory_settings = 'manage_initial_inventory_settings';
export const view_regulated_users_list = 'view_regulated_users_list';
export const manage_regulated_users = 'manage_regulated_users';
export const view_regulated_inventory_receipts_list = 'view_regulated_inventory_receipts_list';
export const manage_regulated_inventory_receipts = 'manage_regulated_inventory_receipts';

//International permissions
export const manage_international_settings = 'manage_international_settings';

//Leaf PA permissions
export const permissionsLeafPA = [
  'manage_pennsylvania_tax_notifications'
];

// Courier
export const manage_courier_delivery_orders = 'manage_courier_delivery_orders';
