import React, { useState } from 'react';
import omit from 'lodash.omit';
import PropTypes from 'prop-types';
import {Col, Row, Button} from 'react-bootstrap';
import {FaMinus, FaPlus} from 'react-icons/fa';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import NumericInput from '../../../../common/form/NumericInput';
import TextInput from '../../../../common/form/TextInput';
import ReactSelectInput from '../../../../common/form/ReactSelectInput';
import InternationalDateTimePickerInput from '../../../../common/form/InternationalDateTimePickerInput';
import PackagePurposeField from '../../../../common/form/redux-form/PackagePurposeField';
import InlineCheckBox from '../../../../common/form/InlineCheckBox';
import {isMetrcTracked} from '../../../../../selectors/itemMastersSelectors';
import ReduxMetrcIdField from '../../../../common/form/redux-form/ReduxMetrcIdField';
import ContentConcealer from '../../../../common/concealers/ContentConcealer';
import WillRender from '../../../../common/concealers/WillRender';
import InternationalCurrencyInput from '../../../../common/form/InternationalCurrencyInput';


const PrepackLineInventoryRows = (props) => {

  const {
    fields, line, inventoryRows, getValue, storageLocations, itemMasterChildren,
    parentFieldName, isLotTracked, locked, resetPackageCodeKey,
    isBiotrack, isCanada, inventoryTypes, isInitialInventoryCreation,
    onScannedTrackingId, isMetrc, itemMaster, trackingIds, isWaLeaf, displaySourceFields,
    hasLinkedTransferDetails, metrcIsImported, isMetrcStemHoldingsToggled, isReassignMode, change,
    getTotalLineItemPrice, hasCostEditPermission
  } = props;
  const lineInventory = line && line.inventory && line.inventory.length ? line.inventory : [];
  const isMedicated = getValue('contains_medicated');
  const medicatedReceiptRequiresMapping = getValue('medicatedReceiptRequiresMapping');
  const loadedFromSupplyChainMapping = getValue('loadedFromSupplyChainMapping');

  //if there is more than 1 package for the PP size and item it can be filled out by the PrepackLineInventory component
  let rowLength;
  if(inventoryRows) {
    rowLength = inventoryRows.length > 1 ? 1 : 0;
  }
  //if they are editing prices this will set the flag and
  const setLineToUnitCostAllAdjust = (inventoryIndex) => {
    line.unit_cost_all_adjust = 1;
    inventoryRows[inventoryIndex].unit_cost_all_adjust = 1;
  };

  const getLineTotal = (inventory) => {
    if (!inventory || !inventory.qty || !inventory.unit_cost) {
      return '0.00';
    }

    const total = inventory.qty * parseFloat(inventory.unit_cost);

    if (isReassignMode) {
      inventory.line_total = total.toFixed(2);
      getTotalLineItemPrice();
    }

    return total.toFixed(2);
  };

  const getAddInventoryRow = (inventoryIndex, lineInventory, fields, mdSize) => { //eslint-disable-line
    return (<Col md={mdSize}>
      <label style={{width: '100%'}}>&nbsp;</label>
      <WillRender ifTrue={inventoryIndex >= 0 && lineInventory.length < itemMasterChildren.length}>
        <Button
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
            const current = fields.get(inventoryIndex);
            fields.push(omit(current, ['id']));
          }}
          disabled={locked || isMedicated && medicatedReceiptRequiresMapping && loadedFromSupplyChainMapping}
          variant='primary'
          size='sm'
        >
          <FaPlus/>
        </Button>
      </WillRender>
      <WillRender ifTrue={inventoryIndex}>
        <Button
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            fields.remove(inventoryIndex);
          }}
          size='sm'
          disabled={locked}
        >
          <FaMinus/>
        </Button>
      </WillRender>
    </Col>);
  };


  return (
    <Col xs={12}>
      {fields.map((inventoryName, inventoryIndex) => {
        const [editButtonClick, setEditButtonClicked] = useState(true);
        const toggleEditButtonClick = () => {
          setLineToUnitCostAllAdjust(inventoryIndex);
          change(`${inventoryName}.unit_cost_all_adjust`, 1);
          setEditButtonClicked(!editButtonClick);
        };
        const changeLineUnitPriceAndTotal = (e) => {
          change(`${inventoryName}.unit_cost`, e);
        };
        return (
          <div key={inventoryIndex}>
            {
              isMetrc && isMetrcTracked(itemMaster)
                ? <Row>
                  <Col md={6}>
                    {
                      //Show the existing tracking id or the dropdown to select one if not present
                      hasLinkedTransferDetails
                        ? <Field
                          name={`${inventoryName}.state_integration_tracking_id`}
                          component={TextInput}
                          props={{
                            label: I18n.t('cultivation.transfers.form.tracking_id'),
                            disabled: true,
                          }}
                        />
                        : <ReduxMetrcIdField
                          name={`${inventoryName}.state_integration_tracking_id`}
                          onChange={(e, newValue, previousValue) => {
                            return onScannedTrackingId(newValue, previousValue, inventoryName);
                          }}
                          props={{
                            options: trackingIds,
                          }}
                        />
                    }
                  </Col>
                </Row>
                : null
            }

            <Row>
              <Col md={3}>
                <Field
                  name={`${inventoryName}.item_master_id`}
                  component={ReactSelectInput}
                  props={{
                    label: I18n.t('cultivation.transfers.form.prePackWeight'),
                    textKey: 'displayName',
                    valueKey: 'id',
                    options: itemMasterChildren,
                    isRequired: true,
                    disabled: locked || isMedicated && medicatedReceiptRequiresMapping && loadedFromSupplyChainMapping
                  }}
                />
              </Col>

              <ContentConcealer show={isMedicated && medicatedReceiptRequiresMapping && loadedFromSupplyChainMapping}>
                <Col md={3}>
                  <Field
                    name={`${inventoryName}.lot_number`}
                    component={TextInput}
                    props={{
                      label: I18n.t('cultivation.transfers.form.batch'),
                      disabled: true
                    }}
                  />
              </Col>
              </ContentConcealer>

              {/* This is legacy. Prepacks are always lot tracked, but it may have been co-opted for some other purpose I don't want to break */}
              <WillRender ifTrue={isLotTracked}>
                <Col md={3}>
                  <Field
                    name={`${inventoryName}.package_code`}
                    component={TextInput}
                    onBlur={(event) => {
                      const lotNumber = getValue(`${parentFieldName}.lot_number`);
                      const itemMasterId = getValue(`${inventoryName}.item_master_id`);
                      resetPackageCodeKey(inventoryName, itemMasterId, lotNumber, event.target.value, parentFieldName);
                    }}
                    props={{
                      label: I18n.t('cultivation.transfers.form.packageId'),
                      disabled: locked || isMedicated && medicatedReceiptRequiresMapping && loadedFromSupplyChainMapping
                    }}
                  />
                </Col>
              </WillRender>
              <Col md={3}>
                <Field name={`${inventoryName}.storage_location_id`} component={ReactSelectInput} props={{
                  label: I18n.t('cultivation.transfers.form.storageLocation'),
                  options: storageLocations,
                  isRequired: true,
                  disabled: locked
                }}/>
              </Col>
              <Col md={3}>
                <InternationalDateTimePickerInput
                  name={`${inventoryName}.packaged_at`}
                  props={{
                    label: I18n.t('packaging.form.packagedAt'),
                    isRequired: false,
                    inputProps: {disabled: locked},
                  }} />
              </Col>
            </Row>

            <Row>
              <Col md={3}>
                <PackagePurposeField
                  name={`${inventoryName}.purpose`}
                  disabled={locked}
                />
              </Col>
              <Col md={3}>
                <Field
                  name={`${inventoryName}.qty`}
                  component={NumericInput}
                  props={{
                    label: I18n.t('numberOfUnits'),
                    isRequired: true,
                    disabled: locked
                  }}
                />
              </Col>
              {rowLength && !locked ?
              <Col md={3}>
                <InternationalDateTimePickerInput
                  name={`${inventoryName}.date_item_expired`}
                  props={{
                    label: I18n.t('cultivation.transfers.form.expirationDatePackage'),
                    isRequired: false,
                    inputProps: {disabled: locked},
                    enableTodayButton: false,
                  }}
              />
              </Col> :
              <Col md={3}>
                <InternationalDateTimePickerInput
                  name={`${inventoryName}.date_item_expired`}
                  props={{
                    label: I18n.t('cultivation.transfers.form.expirationDate'),
                    isRequired: false,
                    inputProps: {disabled: locked}
                  }} />
                </Col>
              }
              <Col md={3}>
                <InternationalCurrencyInput
                  name={`${inventoryName}.unit_cost`}
                  props={{
                    label: I18n.t('cultivation.transfers.form.unitPrice'),
                    disabled: editButtonClick || !hasCostEditPermission || !isReassignMode,
                    onBlur: (e) => changeLineUnitPriceAndTotal(e.target.value),
                    rightAddon: (
                      isReassignMode && hasCostEditPermission && (
                        <span className={`btn btn-primary ${!isReassignMode || !hasCostEditPermission ? 'disabled' : ''}`} onClick={() => {
                          toggleEditButtonClick();
                        }}>
                          {editButtonClick ? 'Edit' : 'Calc'}
                        </span>
                      )
                    )
                  }}
                />
              </Col>
              <Col md={3} className='line-total'>
                <InternationalCurrencyInput
                  name={`${inventoryName}.line_total`}
                  props={{
                    label: I18n.t('lineItemPrice'),
                    readOnly: true,
                    value: getLineTotal(inventoryRows[inventoryIndex]),
                  }}
                />
              </Col>
            </Row>

            {
              isMetrc && isMetrcStemHoldingsToggled ? (
                <Row>
                  <Col md={3}>
                    <Field
                      name={`${inventoryName}.is_trade_sample`}
                      component={ReactSelectInput}
                      props={{
                        label: I18n.t('metrc.form.tradeSample'),
                        options: [
                          {text: 'Yes', value: 1},
                          {text: 'No', value: 0}
                        ],
                        disabled: metrcIsImported
                      }}
                    />
                  </Col>
                </Row>
              ) : null
            }

            <WillRender ifTrue={displaySourceFields}>
              <Row>
                {getAddInventoryRow(inventoryIndex, lineInventory, fields, 3)}
                <Col md={3}>
                  <Field
                    name={`${inventoryName}.plant_batch_number`}
                    component={TextInput}
                    props={{
                      label: I18n.t('cultivation.transfers.form.sourceBatch'),
                      disabled: locked || hasLinkedTransferDetails
                    }}
                  />
                </Col>
                <Col md={3}>
                  <Field
                    name={`${inventoryName}.source_strain`}
                    component={TextInput}
                    props={{
                      label: I18n.t('cultivation.transfers.form.sourceStrain'),
                      disabled: locked || hasLinkedTransferDetails
                    }}
                  />
                </Col>
                <Col md={3}>
                  <InternationalDateTimePickerInput
                    name={`${inventoryName}.harvested_at`}
                    props={{
                      label: I18n.t('cultivation.transfers.form.sourceHarvestDate'),
                      isRequired: false,
                      inputProps: {disabled: locked || hasLinkedTransferDetails}
                    }}
                  />
                </Col>
              </Row>
            </WillRender>

            <Row>
              <WillRender ifTrue={!displaySourceFields}>
                {getAddInventoryRow(inventoryIndex, lineInventory, fields, 3)}
              </WillRender>
              {isBiotrack ?
                <Col lg={3}>
                  <Field name={`${inventoryName}.integration_type`} component={ReactSelectInput} props={{
                    label: I18n.t('cultivation.transfers.form.inventoryType'),
                    options: inventoryTypes,
                    isRequired: true,
                    disabled: !isInitialInventoryCreation,
                  }}/>
                </Col> : null}
              {isWaLeaf ?
                <Col lg={3}>
                  <Field name={`${inventoryName}.integration_type`} component={TextInput} props={{
                    label: I18n.t('cultivation.transfers.form.inventoryType'),
                    disabled: true,
                  }}/>
                </Col>
              : null}
            </Row>
            {(isCanada) && (
              <Row>
                <Col offset={3} md={3}>
                  <Field name={`${inventoryName}.finished`} component={InlineCheckBox} props={{
                    label: I18n.t('common.form.finishedInventoryCheckbox'),
                  }}/>
                </Col>
              </Row>
            )}
            <hr/>
          </div>
        );
      })}
    </Col>
  );
};

PrepackLineInventoryRows.propTypes = {
  fields: PropTypes.object.isRequired,
  inventoryRows: PropTypes.array.isRequired,
  line: PropTypes.object.isRequired,
  getValue: PropTypes.func.isRequired,
  itemMasterChildren: PropTypes.array.isRequired,
  storageLocations: PropTypes.array.isRequired,
  itemMasters: PropTypes.array,
  parentFieldName: PropTypes.string.isRequired,
  isLotTracked: PropTypes.bool.isRequired,
  locked: PropTypes.bool.isRequired,
  editableLinePrice: PropTypes.bool.isRequired,
  resetPackageCodeKey: PropTypes.func.isRequired,
  isInitialInventoryCreation: PropTypes.bool,
  isBiotrack: PropTypes.bool.isRequired,
  inventoryTypes: PropTypes.array.isRequired,
  isWaLeaf: PropTypes.bool.isRequired,
  isCanada: PropTypes.bool,
  onlyEntireQuantityCanBeTransferred: PropTypes.bool,
  displaySourceFields: PropTypes.bool.isRequired,
  onScannedTrackingId: PropTypes.func.isRequired,
  isMetrc: PropTypes.bool.isRequired,
  itemMaster: PropTypes.object.isRequired,
  trackingIds: PropTypes.array.isRequired,
  hasLinkedTransferDetails: PropTypes.bool.isRequired,
  metrcIsImported: PropTypes.bool,
  isMetrcStemHoldingsToggled: PropTypes.bool,
  isReassignMode: PropTypes.bool,
  change: PropTypes.func.isRequired,
  getTotalLineItemPrice: PropTypes.func,
  hasCostEditPermission: PropTypes.bool.isRequired,
};

export default PrepackLineInventoryRows;
