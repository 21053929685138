import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {FieldArray} from 'redux-form';
import {Col, Row, Card} from 'react-bootstrap';
import get from 'lodash.get';
import filter from 'lodash.filter';
import find from 'lodash.find';
import first from 'lodash.first';
import cln from 'classnames';
import {connect} from 'react-redux';
import {FaCheckCircle, FaTimesCircle} from 'react-icons/fa';
import LinePackage from './LinePackage';
import LineInventory from './LineInventory';
import PrepackLineInventory from './PrepackLineInventory';
import ItemMasterDropDown from './common/ItemMasterDropDown';
import {
  findMetrcPackageByInventory,
  getMetrcTransferPackages
} from '../../../../../selectors/integration/metrcSelectors';
import {isFeatureEnabled} from '../../../../../selectors/featureToggles';
import {getPurchaseOrder} from '../../../../../selectors/fillPurchaseOrderSelectors';
import {updateInventoryReceiptLine} from '../../../../../actions/inventory-receipt';
import {updatePurchaseOrderLine} from '../../../../../actions/purchaseOrderActions';
import {getReassignMode} from '../../../../../selectors/inventoryReceiptSelectors';
import ReassignItemMasterDropDown from '../../../reassign-packages/ReassignItemMasterDropDown';
import {userHasPermission} from '../../../../../selectors/usersSelectors';
import * as p from '../../../../../constants/permissions';


class LinePackages extends React.PureComponent {

  constructor(props, context) {
    super(props, context);

    this.renderField = this.renderField.bind(this);
    this.renderInventory = this.renderInventory.bind(this);
    this.renderMetrcStatus = this.renderMetrcStatus.bind(this);
    this.renderLinePackage = this.renderLinePackage.bind(this);
    this.renderItemMasterComponent = this.renderItemMasterComponent.bind(this);
    this.renderReassignItemMasterComponent = this.renderReassignItemMasterComponent.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { metrcPackages, actions, integrationState } = this.props;

    if (
      (integrationState.isMetrc) &&
      (metrcPackages.length > 0) &&
      (metrcPackages !== prevProps.metrcPackages)
    ) {
      actions.rejectMetrcPackages(this.props);
    }
  }

  renderMetrcStatus(line) {
    const { findMetrcPackageByInventory } = this.props;
    const metrcPackage = findMetrcPackageByInventory(get(line, 'inventory', []));
    const status = get(metrcPackage, 'status');
    const message = (['Accepted', 'Rejected'].includes(status)) ? I18n.t('metrc.form.packageStatus', { status }) : null;
    const className = cln(
      'notify-message',
      { 'text-success': status === 'Accepted' },
      { 'text-danger': status === 'Rejected' }
    );

    return message && (
      <span className={className}>
        {status === 'Accepted'
          ? <FaCheckCircle/>
          : status === 'Rejected'
            ? <FaTimesCircle />
            : null
        }
        {message}
        </span>
    );
  }

  renderReassignItemMasterComponent(line, fieldName, index) {
    const { itemMasters, itemMasterChildren, change } = this.props;
    const itemMaster = get(line, 'itemMaster', {});
    const inventory = get(line, 'inventory', []);

    return (
      <ReassignItemMasterDropDown
        fieldName={fieldName}
        inventory={inventory}
        itemMaster={itemMaster}
        change={change}
        line={line}
        index={index}
        itemMasterChildren={itemMasterChildren}
        fieldProps={{
          label: I18n.t('cultivation.transfers.form.item'),
          options: itemMasters,
          textKey: 'name',
          valueKey: 'id',
        }}
      />
    );
  }

  renderItemMasterComponent(line, fieldName, index) {
    const { itemMasters, isTransferImported, integrationState, isReassignMode, actions } = this.props;
    const itemMaster = get(line, 'itemMaster', {});
    const { subcategory_id, uom_type, active } = itemMaster;

    const defaultDropDown = (
      <ItemMasterDropDown
        editable={(integrationState.isMetrc && isTransferImported)}
        fieldName={`${fieldName}.item_master_id`}
        fieldProps={{
          label: I18n.t('cultivation.transfers.form.item'),
          onChange: (value) => actions.updateInventoryReceiptLine(value, line, index),
          options: filter(itemMasters, {uom_type, subcategory_id, active}),
          textKey: 'name',
          valueKey: 'id',
        }}
      />
    );

    return (
      <Col xs={12} md={3}>
        {(isReassignMode && !get(line, 'is_ingredient', false))
          ? this.renderReassignItemMasterComponent(line, fieldName, index)
          : defaultDropDown
        }
        {(integrationState.isMetrc) && this.renderMetrcStatus(line)}
      </Col>
    );
  }

  renderLinePackage(line, fieldName, index) {
    const { change, fields, locked, uoms, integrationState, lineItemPrices, lineItemQuantities, internationalNumberFormat,
      itemMasterChildren, metrcIsImported, onlyEntireQuantityCanBeTransferred, receiptStatus, isReassignMode, getValue,
      prepackWeights, hasCostEditPermission} = this.props;
    const lineItemPrice = lineItemPrices[index] || '';
    const lineItemQuantity = lineItemQuantities[index] || '';
    const lineItemMasterChildren = itemMasterChildren[line.item_master_id] || [];
    return (
      <LinePackage
        line={line}
        uoms={uoms}
        index={index}
        change={change}
        fields={fields}
        locked={locked}
        fieldName={fieldName}
        component={this.renderItemMasterComponent(line, fieldName, index)}
        lineItemPrice={lineItemPrice}
        metrcIsImported={metrcIsImported}
        integrationState={integrationState}
        lineItemQuantity={lineItemQuantity}
        lineItemMasterChildren={lineItemMasterChildren}
        onlyEntireQuantityCanBeTransferred={onlyEntireQuantityCanBeTransferred}
        receiptStatus={receiptStatus}
        internationalNumberFormat={internationalNumberFormat}
        isReassignMode={isReassignMode}
        getValue={getValue}
        prepackWeights={prepackWeights}
        hasCostEditPermission={hasCostEditPermission}/>
    );
  }

  renderInventory(line, fieldName, index) {
    const {
      touch,
      locked,
      fields,
      getValue,
      orderLines,
      itemMasters,
      trackingIds,
      inventoryTypes,
      partnerFacility,
      sharedLocations,
      storageLocations,
      integrationState,
      biotrackSettings,
      displaySourceFields,
      itemMasterChildren,
      resetPackageCodeKey,
      onScannedTrackingId,
      hasLinkedTransferDetails,
      isInitialInventoryCreation,
      onlyEntireQuantityCanBeTransferred,
      metrcIsImported,
      isMetrcStemHoldingsToggled,
      change,
      isReassignMode,
      hasCostEditPermission
    } = this.props;

    const orderLine = orderLines[index] || {subitems: []};
    const filteredLocations = get(line, 'itemMaster.is_shared_item') ? sharedLocations : storageLocations;
    const itemMaster = line && itemMasters && itemMasters.length && itemMasters.find(itemMaster => itemMaster.id === line.item_master_id) || {};
    const isLotTracked = !!(itemMaster && itemMaster.inventory_attributes && itemMaster.inventory_attributes.lot_tracked);
    const isInventoryItem = !!(itemMaster && itemMaster.is_inventory_item);
    const editableLinePrice = (typeof fields.get === 'function') ? fields.get(index).editableLinePrice : false;
    const lineItemMasterChildren = itemMasterChildren[line.item_master_id] || [];
    const InventoryComponent = line.line_type === 'prepack' ? PrepackLineInventory : LineInventory;

    return (
      <FieldArray
        name={`${fieldName}.inventory`}
        line={line}
        index={index}
        touch={touch}
        locked={locked}
        getValue={getValue}
        orderLine={orderLine}
        component={InventoryComponent}
        itemMaster={itemMaster}
        itemMasters={itemMasters}
        trackingIds={trackingIds}
        isLotTracked={isLotTracked}
        inventoryTypes={inventoryTypes}
        parentFieldName={fieldName}
        partnerFacility={partnerFacility}
        isInventoryItem={isInventoryItem}
        storageLocations={filteredLocations}
        biotrackSettings={biotrackSettings}
        integrationState={integrationState}
        editableLinePrice={editableLinePrice}
        itemMasterChildren={lineItemMasterChildren}
        resetPackageCodeKey={resetPackageCodeKey}
        onScannedTrackingId={onScannedTrackingId}
        hasLinkedTransferDetails={hasLinkedTransferDetails}
        isInitialInventoryCreation={isInitialInventoryCreation}
        onlyEntireQuantityCanBeTransferred={onlyEntireQuantityCanBeTransferred}
        displaySourceFields={displaySourceFields}
        metrcIsImported={metrcIsImported}
        isMetrcStemHoldingsToggled={isMetrcStemHoldingsToggled}
        change={change}
        isReassignMode={isReassignMode}
        hasCostEditPermission={hasCostEditPermission}
      />
    );
  }

  renderField(fieldName, index) {
    const { getValue } = this.props;
    const line = getValue(fieldName) || {};

    return (
      <Card key={index}>
        <Card.Body>
          <Row>
            <Col xs={12}>
              {this.renderLinePackage(line, fieldName, index)}
            </Col>
            <Col xs={12}>
              {this.renderInventory(line, fieldName, index)}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }

  render() {
    const { fields } = this.props;

    return (
      <div>
        {fields.map(this.renderField)}
      </div>
    );
  }
}

LinePackages.propTypes = {
  isApplyStorageLocation: PropTypes.bool,
  itemMasterChildren: PropTypes.object.isRequired,
  itemMasters: PropTypes.array.isRequired,
  storageLocations: PropTypes.array.isRequired,
  sharedLocations: PropTypes.array.isRequired,
  partnerFacility: PropTypes.object,
  orderLines: PropTypes.array.isRequired,
  fields: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  touch: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  lineItemPrices: PropTypes.array.isRequired,
  lineItemQuantities: PropTypes.array.isRequired,
  locked: PropTypes.bool.isRequired,
  resetPackageCodeKey: PropTypes.func.isRequired,
  integrationState: PropTypes.object.isRequired,
  trackingIds: PropTypes.array.isRequired,
  uoms: PropTypes.array.isRequired,
  hasLinkedTransferDetails: PropTypes.bool,
  isInitialInventoryCreation: PropTypes.bool,
  onScannedTrackingId: PropTypes.func.isRequired,
  inventoryTypes: PropTypes.array.isRequired,
  isTransferImported: PropTypes.bool,
  findMetrcPackageByInventory: PropTypes.func,
  biotrackSettings: PropTypes.object,
  metrcIsImported: PropTypes.bool,
  isReassignMode: PropTypes.bool,
  onlyEntireQuantityCanBeTransferred: PropTypes.bool,
  displaySourceFields: PropTypes.bool,
  isMetrcStemHoldingsToggled: PropTypes.bool,
  internationalNumberFormat: PropTypes.string.isRequired,
  hasCostEditPermission: PropTypes.bool.isRequired,
};

export default connect(
  (state) => ({
    metrcPackages: getMetrcTransferPackages(state),
    isReassignMode: get(getReassignMode(state), 'enable', false),
    isTransferImported: get(getPurchaseOrder(state), 'is_imported') === 1,
    findMetrcPackageByInventory: findMetrcPackageByInventory(state),
    isMetrcStemHoldingsToggled: isFeatureEnabled(state)('feature_metrc_stem_holdings'),
    hasCostEditPermission: userHasPermission(state, {permissions: [p.manage_inventory_receipt_package_cost]}),
  }),
  (dispatch, ownProps) => ({
    actions: {
      /**
       * Find rejected metrc packages and set received amount as 0
       * @param props
       */
      rejectMetrcPackages(props) {
        const METRC_SHIPPED_PACKAGE_STATUS = 'Shipped';
        const METRC_REJECTED_PACKAGE_STATUS = 'Rejected';
        const METRC_RETURNED_PACKAGE_STATUS = 'Returned';
        const { fields, getValue, findMetrcPackageByInventory } = props;

        fields.map((fieldName) => {
          const line = getValue(fieldName) || {};
          const inventory = get(line, 'inventory', []);
          const metrcPackage = findMetrcPackageByInventory(inventory) || {};
          if (metrcPackage.status === METRC_SHIPPED_PACKAGE_STATUS || metrcPackage.status === METRC_RETURNED_PACKAGE_STATUS) {
            ownProps.change(`${fieldName}.metrc_status`, 'shipped');
          }
          if (metrcPackage.status === METRC_REJECTED_PACKAGE_STATUS) {
            inventory.forEach((invName, index) => {
              ownProps.change(`${fieldName}.inventory[${index}].qty`, 0);
              ownProps.change(`${fieldName}.inventory[${index}].prepack_inventory_rows[0].qty`, 0);
            });
          }
        });
      },

      /**
       * Update inventory receipt line, especially needs for update UI
       * if product has prepack type
       * @param item_master_id - id coming from dropdown
       * @param line - current inventory line, shat should be replaced
       * @param index - ordering number
       */
      updateInventoryReceiptLine(item_master_id, line, index) {
        if (item_master_id === line.item_master_id) {
          return false;
        }

        const { itemMasters, itemMasterChildren } = ownProps;
        const itemMaster = find(itemMasters, { id: item_master_id });
        const subitems = [];
        const inventory = [];

        if (get(itemMaster, 'inventory_attributes.is_prepack')) {
          const prepackChildren = get(itemMasterChildren, item_master_id, []);
          const sortHandler = (child) => {
            const weight = get(child, 'itemWeight.weight', 0);
            return (weight === 1) ? -1 : 1;
          };

          let ordered_qty = get(line, 'ordered_qty', 0);
          const forEachHandler = ({ id }) => {
            subitems.push({
              qty: ordered_qty,
              unit_price: 0,
              item_master_id: id,
            });

            ordered_qty = 0;
          };

          prepackChildren
            .sort(sortHandler)
            .forEach(forEachHandler);

          line.inventory.forEach((item) => {
            inventory.push({
              ...item,
              uom: 'EA',
              prepack_inventory_rows: [{
                ...item,
                ...first(subitems),
                package_code_key: null,
              }],
            });
          });
        } else {
          line.inventory.forEach((item) => {
            inventory.push({
              ...item,
              prepack_inventory_rows: undefined,
              uom: get(line, 'uom'),
              item_master_id,
              package_code_key: null,
            });
          });
        }

        dispatch(updateInventoryReceiptLine(index, { item_master_id, itemMaster, po_subitems: subitems, inventory }));
        dispatch(updatePurchaseOrderLine(index, { item_master_id, subitems }));
      },
    }
  })
)(LinePackages);
