import React from 'react';
import PropTypes from 'prop-types';
import {Col, Row, Button} from 'react-bootstrap';
import {FaMinus} from 'react-icons/fa';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';
import showFinishedInventoryFlag from '../../../../../selectors/rulesSelectors';
import {isMetrcTracked} from '../../../../../selectors/itemMastersSelectors';
import InternationalQuantityByUomInput from '../../../../common/form/InternationalQuantityByUomInput';
import TextInput from '../../../../common/form/TextInput';
import InternationalDateTimePickerInput from '../../../../common/form/InternationalDateTimePickerInput';
import ReactSelectInput from '../../../../common/form/ReactSelectInput';
import StaticPlaceholderInput from '../../../../common/form/StaticPlaceholderInput';
import InlineCheckBox from '../../../../common/form/InlineCheckBox';
import ReduxMetrcIdField from '../../../../common/form/redux-form/ReduxMetrcIdField';
import AddInventoryRow from './common/AddInventoryRow';
import PackagePurposeField from '../../../../common/form/redux-form/PackagePurposeField';
import ContentConcealer from '../../../../common/concealers/ContentConcealer';
import WillRender from '../../../../common/concealers/WillRender';
import {UOM_UNIT_VALUE_PRECISION} from '../../../../../constants/uoms';


const LineInventory = (props) => {

  const {
    fields, line, getValue, partnerFacility, storageLocations, isLotTracked, locked, itemMaster, touch, resetPackageCodeKey,
    integrationState, trackingIds, hasLinkedTransferDetails, isInitialInventoryCreation, onScannedTrackingId, isInventoryItem,
    inventoryTypes, onlyEntireQuantityCanBeTransferred, displaySourceFields, metrcIsImported, isMetrcStemHoldingsToggled, change
  } = props;

  const lineType = line.line_type || 'unit';
  const {isLeaf, isMetrc, isBiotrack, isCanada, isWaLeaf} = integrationState;
  const isMedicated = getValue('contains_medicated');
  const inventoryMapped = isMedicated && isLeaf;
  const medicatedReceiptRequiresMapping = getValue('medicatedReceiptRequiresMapping');
  const loadedFromSupplyChainMapping = getValue('loadedFromSupplyChainMapping');
  const default_uom = itemMaster.default_uom;
  const status = getValue('status');
  const category_code = get(itemMaster, 'category.category_code', '');
  const isOpenCbdFlowerOrHempWasteReceipt = (category_code === 'CBD_FLOWER' || category_code === 'HEMP_WASTE') && status === 'open';

  //change the expiration date for all packages for one item
  const updateDateItemExpired = (value, line) => {
    line.inventory.forEach((e) => {
      e.date_item_expired = value;
    });
  };
  //if there are more than 1 package per item then generate an option to add an expiration date for multiple packages
  let rowLength;
  if(line.inventory) {
    rowLength = line.inventory.length > 1 ? 1 : 0;
  }

  return (
    <Row style={{clear: 'both', borderTop: '1px dotted #ccc', paddingTop: '12px'}}>
      <Col xs={12} style={{padding: '10px', paddingBottom: '16px'}}>
        <AddInventoryRow
          line={line}
          push={fields.push}
          partnerFacility={partnerFacility || {}}
          locked={Boolean(locked || inventoryMapped || 0)}
          offset={3}
          integrationState={integrationState}
          onlyEntireQuantityCanBeTransferred={onlyEntireQuantityCanBeTransferred}
        />
      </Col>
      {/* if there is more than 1 package per item */}
      <WillRender ifTrue={rowLength && !locked}>
        <Col xs={12} className='d-flex justify-content-end' style={{ marginLeft: '-85px', marginBottom: '10px'}}>
          <Row>
            <Col md={12}>
              <div style={{marginBottom: '7px'}} className='d-flex justify-content-end'>{I18n.t('cultivation.transfers.form.expirationDateLine', {imName: line.name})}</div>
            </Col>
            <Col md={12} className='d-flex justify-content-end'>
              <InternationalDateTimePickerInput
                name={`${line}.date_item_expired`}
                props={{
                  inputProps: {disabled: locked},
                  enableTodayButton: false,
                  onChange: (value) => {
                    change('line.date_item_expired', value);
                    updateDateItemExpired(value, line);
                  }
                }}/>
            </Col>
          </Row>
        </Col>
        <Col xs={12} style={{borderTop: '1px dotted #ccc', paddingBottom: '16px'}}/>
      </WillRender>
      {fields.map((inventoryName, inventoryIndex) => {
        const id = line.inventory && line.inventory[inventoryIndex] && line.inventory[inventoryIndex].id;
        const showFinishedFlag = showFinishedInventoryFlag({country_code: isCanada ? 'ca' : ''}, itemMaster);
        return (
          <Col xs={12} key={inventoryName}>
            <Row>
              <Col md={{ span: 9, offset:3}}>
                <Row>
                  {isLotTracked ? <Col lg={3}>
                    <Field
                      name={`${inventoryName}.lot_number`}
                      component={TextInput}
                      onBlur={(event) => {
                        touch(`${inventoryName}.package_code`); // Touch so errors show if present
                        resetPackageCodeKey(inventoryName, itemMaster.id, event.target.value, getValue(`${inventoryName}.package_code`));
                      }}
                      props={{
                        label: I18n.t('cultivation.transfers.form.batch'),
                        disabled: locked || medicatedReceiptRequiresMapping && loadedFromSupplyChainMapping && isMedicated,
                        isRequired: isInventoryItem && !isOpenCbdFlowerOrHempWasteReceipt
                      }}
                    />
                  </Col> : null}
                  {isLotTracked ? <Col lg={3}>
                    <Field
                      name={`${inventoryName}.package_code`}
                      component={TextInput}
                      onChange={(event) => {
                        resetPackageCodeKey(inventoryName, itemMaster.id, getValue(`${inventoryName}.lot_number`), event.target.value);
                      }}
                      props={{
                        label: I18n.t('cultivation.transfers.form.packageId'),
                        disabled: locked || medicatedReceiptRequiresMapping && loadedFromSupplyChainMapping && isMedicated
                      }}
                    />
                  </Col> : null}
                  <Col lg={3}>
                    <InternationalDateTimePickerInput
                      name={`${inventoryName}.date_item_created`}
                      props={{
                        label: I18n.t('cultivation.transfers.form.createdDate'),
                        inputProps: {
                          disabled: locked,
                        }
                      }}/>
                  </Col>
                  <Col lg={3}>
                    <InternationalDateTimePickerInput
                      name={`${inventoryName}.date_item_expired`}
                      props={{
                        label: rowLength && !locked ? I18n.t('cultivation.transfers.form.expirationDatePackage') : I18n.t('cultivation.transfers.form.expirationDate'),
                        inputProps: {disabled: locked},
                        enableTodayButton: false,
                      }}/>
                  </Col>
                  {isMetrc && <Col lg={3}>
                    <InternationalDateTimePickerInput
                      name={`${inventoryName}.date_item_use_by`}
                      props={{
                        label: I18n.t('cultivation.transfers.form.useByDate'),
                        inputProps: {disabled: locked},
                        enableTodayButton: false,
                      }}/>
                  </Col> }
                </Row>
                <Row>
                  <Col lg={3}>
                    {lineType === 'bulk' ?
                      <InternationalQuantityByUomInput
                        name={`${inventoryName}.qty`}
                        label={I18n.t('netWeight')}
                        isRequired={true}
                        disabled={!isBiotrack && locked}
                        uom={line.uom}
                      /> :
                      <InternationalQuantityByUomInput
                        name={`${inventoryName}.qty`}
                        label={I18n.t('cultivation.transfers.form.unitsReceived')}
                        isRequired={true}
                        fractionPartSize={UOM_UNIT_VALUE_PRECISION}
                        disabled={!isBiotrack && locked}
                        uom={default_uom}
                      />}
                  </Col>
                  <Col lg={3}>
                    {lineType === 'bulk' && !line.is_ingredient
                      ?
                      <InternationalQuantityByUomInput
                        name={`${inventoryName}.gross_weight`}
                        label={I18n.t('cultivation.transfers.form.grossWeight')}
                        disabled={locked}
                        uom={default_uom}
                      />
                      : <StaticPlaceholderInput label='Weight' placeholder='Not Applicable' />
                    }
                  </Col>
                  <Col lg={3}>
                    <Field name={`${inventoryName}.storage_location_id`} component={ReactSelectInput} props={{
                      label: I18n.t('cultivation.transfers.form.storageLocation'),
                      options: line.is_ingredient ? storageLocations.filter((location) => !location.is_sales_location) : storageLocations,
                      disabled: locked,
                      isRequired: !isOpenCbdFlowerOrHempWasteReceipt
                    }}/>
                  </Col>
                  <Col lg={3}>
                    <InternationalDateTimePickerInput
                      name={`${inventoryName}.packaged_at`}
                       props={{
                         label: I18n.t('packaging.form.packagedAt'),
                         isRequired: false,
                         inputProps: {disabled: locked}
                       }} />
                  </Col>
                    {isMetrc && isMetrcTracked(itemMaster) ? <Col lg={6}>
                        {
                          hasLinkedTransferDetails
                            ? <Field
                            name={`${inventoryName}.state_integration_tracking_id`}
                            component={TextInput}
                            props={{
                              label:I18n.t('cultivation.transfers.form.tracking_id'),
                              disabled: true,
                            }}
                          />
                            : <ReduxMetrcIdField
                              name={`${inventoryName}.state_integration_tracking_id`}
                              onChange={(e, newValue, previousValue) => {
                                return onScannedTrackingId(newValue, previousValue, inventoryName);
                              }}
                            props={{
                              options: trackingIds,
                            }}
                          />
                        }

                    </Col> : null}
                  {/* We want to pass the global Leaf ID value to the back end without letting the user edit it. */}
                  {isLeaf || isBiotrack ? <Col lg={3} style={{display: 'none'}}>
                    <Field
                      name={`${inventoryName}.state_integration_tracking_id`}
                      component={TextInput}
                      props={{
                        label:I18n.t('cultivation.transfers.form.leafGlobalId'),
                        disabled: true,
                      }}
                    />
                  </Col> : null}
                  {isMetrc && isMetrcStemHoldingsToggled ?
                    <Col lg={3}>
                      <Field
                        name={`${inventoryName}.is_trade_sample`}
                        component={ReactSelectInput}
                        props={{
                          label: I18n.t('metrc.form.tradeSample'),
                          disabled: metrcIsImported,
                          options: [
                            {text: 'Yes', value: 1},
                            {text: 'No', value: 0}
                          ]
                        }}
                      />
                    </Col>
                    : null
                  }
                </Row>
                <WillRender ifTrue={displaySourceFields}>
                  <Row>
                    <Col md={3}>
                      <PackagePurposeField
                        name={`${inventoryName}.purpose`}
                        disabled={locked}
                      />
                    </Col>
                    <Col md={3}>
                      <Field
                        name={`${inventoryName}.plant_batch_number`}
                        component={TextInput}
                        props={{
                          label: I18n.t('cultivation.transfers.form.sourceBatch'),
                          disabled: locked || hasLinkedTransferDetails
                        }}
                      />
                    </Col>
                    <Col md={3}>
                      <Field
                        name={`${inventoryName}.source_strain`}
                        component={TextInput}
                        props={{
                          label: I18n.t('cultivation.transfers.form.sourceStrain'),
                          disabled: locked || hasLinkedTransferDetails
                        }}
                      />
                    </Col>
                    <Col md={3}>
                      <InternationalDateTimePickerInput
                        name={`${inventoryName}.harvested_at`}
                        props={{
                          label: I18n.t('cultivation.transfers.form.sourceHarvestDate'),
                          isRequired: false,
                          inputProps: {disabled: locked || hasLinkedTransferDetails}
                        }}
                      />
                    </Col>
                  </Row>
                </WillRender>
                <Row>
                  <WillRender ifTrue={!displaySourceFields}>
                    <Col md={3}>
                      <PackagePurposeField
                        name={`${inventoryName}.purpose`}
                        disabled={locked}
                      />
                    </Col>
                  </WillRender>
                  {isBiotrack ?
                  <Row>
                  <Col lg={3}>
                    <Field name={`${inventoryName}.integration_type`} component={ReactSelectInput} props={{
                      label: I18n.t('cultivation.transfers.form.inventoryType'),
                      options: inventoryTypes,
                      isRequired: !!isMedicated,
                      disabled: !isInitialInventoryCreation,
                    }}/>
                  </Col>
                  <Col lg={3}>
                    <Field
                      name={`${inventoryName}.state_integration_tracking_id`}
                      component={TextInput}
                      props={{
                        label: I18n.t('common.form.trackingId'),
                        disabled: true
                      }}
                    />
                  </Col>
                  </Row> : null}
                  {isWaLeaf ?
                    <Col lg={3}>
                      <Field name={`${inventoryName}.integration_type`} component={TextInput} props={{
                        label: I18n.t('cultivation.transfers.form.inventoryType'),
                        disabled: true,
                      }}/>
                    </Col>
                  : null}
                  <WillRender ifTrue={showFinishedFlag}>
                    <Col md={3}>
                      <div style={{padding: '18px 0'}}>
                        <Field name={`${inventoryName}.finished`} component={InlineCheckBox} props={{
                          label: I18n.t('common.form.finishedInventoryCheckbox'),
                        }}/>
                      </div>
                    </Col>
                  </WillRender>
                </Row>
              </Col>
              <ContentConcealer show={!isBiotrack}>
                <Col xs={12}>
                  <div className='float-right remove-row'>
                    <Button variant='primary' size='sm' onClick={() => fields.remove(inventoryIndex)} disabled={inventoryMapped || id > 0}>
                      <FaMinus/>
                    </Button>
                  </div>
                </Col>
              </ContentConcealer>
            </Row>
            <hr/>
          </Col>
        );
      })}
    </Row>
  );
};

LineInventory.propTypes = {
  fields: PropTypes.object.isRequired,
  line: PropTypes.object.isRequired,
  getValue: PropTypes.func.isRequired,
  storageLocations: PropTypes.array.isRequired,
  itemMasters: PropTypes.array.isRequired,
  partnerFacility: PropTypes.object,
  touch: PropTypes.func.isRequired,
  isLotTracked: PropTypes.bool.isRequired,
  locked: PropTypes.bool.isRequired,
  itemMaster: PropTypes.object,
  resetPackageCodeKey: PropTypes.func.isRequired,
  integrationState: PropTypes.object.isRequired,
  trackingIds: PropTypes.array.isRequired,
  hasLinkedTransferDetails: PropTypes.bool,
  isInitialInventoryCreation: PropTypes.bool,
  onScannedTrackingId: PropTypes.func.isRequired,
  isInventoryItem: PropTypes.bool.isRequired,
  inventoryTypes: PropTypes.array.isRequired,
  onlyEntireQuantityCanBeTransferred: PropTypes.bool,
  displaySourceFields: PropTypes.bool,
  metrcIsImported: PropTypes.bool,
  isMetrcStemHoldingsToggled: PropTypes.bool,
  change: PropTypes.func.isRequired
};

export default LineInventory;
