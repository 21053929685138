import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { FieldArray, reduxForm } from 'redux-form';
import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';
import ConnectedCouponSelect from './CouponSelect';
import ConnectedRewardSelect from './RewardSelect';
import ManualDiscount from './coupons/manual-discount/ManualDiscount';
import MmapPayment from './coupons/mmap-payment/MmapPayment';
import Products from './Products';
import Coupons from './Coupons';
import Totals from './Totals'; // eslint-disable-line import/no-named-as-default
import ReduxPayment from './ReduxPayment'; // eslint-disable-line import/no-named-as-default
import OrderButtons from './OrderButtons'; // eslint-disable-line import/no-named-as-default
import {validateCartForm, warnCartForm} from './CartValidate';
import PermissionWrapper from '../common/PermissionWrapper';
import {COUPON_LIMIT} from '../../constants/coupons';

const formName = 'checkoutPage';


export const Cart = (props) => {

  const {
    fillOrder,
    taxRate,
    pricingWeights,
    prepackWeights,
    packageCodes,
    order,
    inventoryItems,
    cancelOrder,
    editOrder,
    handleSubmit,
    handleRemoveItem,
    openAssignDriver,
    change,
    customer,
    checkout,
    coupons,
    removeCoupon,
    handleKeyPress,
    handleScanChange,
    itemMasters,
    handleRemoveProduct,
    getProductFulfillmentStatus,
    scanMode,
    toggleScanMode,
    couponIsAppliedToAllProducts,
    getOrderProductCoupons,
    quantityTotals,
    scanField,
    hasPermApplyManualDiscounts,
    salesLocations,
    currentLocation,
    couponUpdateRequired,
    reset,
    isOrderFulfilled,
    focusBarcode,
    couponsUpdated,
    rewardsUpdated,
    rewardUpdateRequired,
    showLoader,
    hideLoader,
    meetsComplianceLimits,
    changeMakePayment,
    goToPage,
    isCure,
    subcategoryFormOptions,
    validCureLimits,
    detailedOrderCoupons,
    rewardPointsOnOrder,
    disablingAddInventory,
    changeFulfillmentStatus,
    orderPackagingWorkflow,
    selectedChildItemMasters,
    processing,
    isOhMetrc,
    isRefillNumberRequired,
    onSubmit,
    lineItemCouponScanned,
    completeLineItemCouponScan,
    //blockExpiredProductAction,
    isLeafPaConfigPackClosedLoopFacility,
    featureLeafPaMmapEnabled,
    mmapPayments,
    removeMmapPayment,
    scanInputRef,
  } = props;

  const allowOrderUpdate = order.products.length > 0 && Object.keys(itemMasters).length > 0;

  const mmap_status = get(customer, 'mmap_status', false);

  return (
    <form className='cart' onSubmit={handleSubmit} noValidate={true}>
      <Row>
        <Col xs={12}>
          {order
           ? (
             <FieldArray
               name='products'
               component={Products}
               products={order.products || []}
               order={order}
               handleRemoveItem={handleRemoveItem}
               handleRemoveProduct={handleRemoveProduct}
               inventoryItems={inventoryItems}
               selectedChildItemMasters={selectedChildItemMasters}
               pricingWeights={pricingWeights}
               prepackWeights={prepackWeights}
               packageCodes = {packageCodes}
               quantityTotals={quantityTotals}
               scanField={scanField}
               change={change}
               checkout={checkout}
               coupons={coupons}
               itemMasters={itemMasters}
               getProductFulfillmentStatus={getProductFulfillmentStatus}
               couponIsAppliedToAllProducts={couponIsAppliedToAllProducts}
               getOrderProductCoupons={getOrderProductCoupons}
               salesLocations={salesLocations}
               currentLocation={currentLocation}
               handleKeyPress={handleKeyPress}
               handleScanChange={handleScanChange}
               reset={() => {
                 reset();
                 focusBarcode();
               }}
               customer={customer}
               isCure={isCure}
               subcategoryFormOptions={subcategoryFormOptions}
               scanMode={scanMode}
               toggleScanMode={toggleScanMode}
               changeFulfillmentStatus={changeFulfillmentStatus}
               disablingAddInventory={disablingAddInventory}
               orderPackagingWorkflow={orderPackagingWorkflow}
               processing={processing}
               isOhMetrc={isOhMetrc}
               isRefillNumberRequired={isRefillNumberRequired}
               allowUpdateCart={allowOrderUpdate}
               scanInputRef={scanInputRef}
               focusBarcode={focusBarcode}
             />)
          : null}
          <Row className='coupons-cart-totals'>
            <Col xs={12} md={6}>
              {!checkout ? (
                <div className='clearfix items-buttons'>
                  <div>
                    {detailedOrderCoupons && detailedOrderCoupons.length >= COUPON_LIMIT && <div className='alert alert-danger'>{I18n.t('cart.coupons.limitReached')}</div>}
                    <ConnectedCouponSelect
                      order={order}
                      order_id={order.id}
                      formName={formName}
                      couponsUpdated={couponsUpdated}
                      couponUpdateRequired={couponUpdateRequired}
                      showLoader={showLoader}
                      hideLoader={hideLoader}
                      products={order.products || []}
                      lineItemCouponScanned={lineItemCouponScanned}
                      completeLineItemCouponScan={completeLineItemCouponScan}
                    />
                    <ConnectedRewardSelect
                      order={order}
                      order_id={order.id}
                      formName={formName}
                      couponsUpdated={rewardsUpdated}
                      couponUpdateRequired={rewardUpdateRequired}
                      showLoader={showLoader}
                      hideLoader={hideLoader}
                      products={order.products || []}
                    />
                    <PermissionWrapper hasPermission={hasPermApplyManualDiscounts}>
                      <ManualDiscount showLoader={showLoader} hideLoader={hideLoader}/>
                    </PermissionWrapper>
                    {mmap_status && isLeafPaConfigPackClosedLoopFacility && featureLeafPaMmapEnabled
                      ? <MmapPayment showLoader={showLoader} hideLoader={hideLoader} goToPage={goToPage}/>
                      : null
                    }
                  </div>
                </div>
              ) : (
                <div />
              )}
              <div>
                {order && ((order.coupons && order.coupons.length) || (mmapPayments && mmapPayments.length)) && !checkout ? (
                  <Coupons
                    removeCoupon={removeCoupon}
                    coupons={detailedOrderCoupons}
                    rewardPointsOnOrder={rewardPointsOnOrder}
                    mmapPayments={mmapPayments}
                    removeMmapPayment={removeMmapPayment}
                  />
                ) : (
                  <div />
                )}
              </div>
            </Col>

            <Col xs={12} md={6}>
              <Row>
                <Col xs={12} md={4}>
                  <OrderButtons cancelOrder={cancelOrder} editOrder={editOrder} />
                </Col>
                <Col xs={12} md={8}>
                  {
                    order
                      ? <Totals
                        order={order}
                        meetsComplianceLimits={meetsComplianceLimits}
                        taxRate={taxRate}
                        couponIsAppliedToAllProducts={couponIsAppliedToAllProducts}
                        change={change}
                        openAssignDriver={openAssignDriver}
                        isOrderFulfilled={isOrderFulfilled}
                        changeMakePayment={changeMakePayment}
                        goToPage={goToPage}
                        fillOrder={fillOrder}
                        onSubmit={onSubmit}
                        handleSubmit={handleSubmit}
                        validCureLimits={validCureLimits}
                        allowOrderUpdate={allowOrderUpdate}
                      />
                      : null
                  }
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col md={4} xs={12} lg={4}>
          {checkout ? <ReduxPayment /> : <div />}
        </Col>
      </Row>
    </form>
  );
};

Cart.propTypes = {
  onSubmit: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  inventoryItems: PropTypes.object.isRequired,
  order: PropTypes.shape({
    id: PropTypes.number,
    order_source: PropTypes.string,
    fulfillment_method: PropTypes.string,
    order_total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    discount_total: PropTypes.string,
    balance_due: PropTypes.number,
    tax_total: PropTypes.number,
    coupon_total: PropTypes.number,
    order_status: PropTypes.string,
    locked: PropTypes.number,
    payment_complete: PropTypes.bool,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    started_by_user_id: PropTypes.number,
    started_by_user_name: PropTypes.string,
    completed_by_user_id: PropTypes.number,
    completed_by_user_name: PropTypes.string,
    products: PropTypes.array,
    payments: PropTypes.array,
    coupons: PropTypes.array,
    taxes: PropTypes.array,
    order_subtotal: PropTypes.number,
    payment_total: PropTypes.number
  }),
  pricingWeights: PropTypes.array.isRequired,
  prepackWeights: PropTypes.array.isRequired,
  quantityTotals: PropTypes.array.isRequired,
  itemMasters: PropTypes.object.isRequired,
  coupons: PropTypes.array.isRequired,
  removeCoupon: PropTypes.func.isRequired,
  packageCodes: PropTypes.array.isRequired,
  checkout: PropTypes.bool.isRequired,
  editOrder: PropTypes.func.isRequired,
  cancelOrder: PropTypes.func.isRequired,
  fillOrder: PropTypes.func.isRequired,
  handleRemoveItem: PropTypes.func.isRequired,
  handleRemoveProduct: PropTypes.func.isRequired,
  openAssignDriver: PropTypes.func.isRequired,
  taxRate: PropTypes.number.isRequired,
  handleKeyPress: PropTypes.func.isRequired,
  getProductFulfillmentStatus: PropTypes.func.isRequired,
  couponIsAppliedToAllProducts: PropTypes.func.isRequired,
  getOrderProductCoupons: PropTypes.func.isRequired,
  salesLocations: PropTypes.array,
  currentLocation: PropTypes.number,
  couponUpdateRequired: PropTypes.bool.isRequired,
  couponsUpdated: PropTypes.func.isRequired,
  rewardUpdateRequired: PropTypes.bool.isRequired,
  rewardsUpdated: PropTypes.func.isRequired,
  showLoader: PropTypes.func.isRequired,
  hideLoader: PropTypes.func.isRequired,
  meetsComplianceLimits: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  scanField: PropTypes.string,
  isOrderFulfilled: PropTypes.bool.isRequired,
  changeMakePayment: PropTypes.func.isRequired,
  handleScanChange: PropTypes.func,
  goToPage: PropTypes.func.isRequired,
  customer: PropTypes.object.isRequired,
  isCure: PropTypes.bool,
  subcategoryFormOptions: PropTypes.object,
  validCureLimits: PropTypes.bool,
  hasPermApplyManualDiscounts: PropTypes.bool,
  detailedOrderCoupons: PropTypes.array,
  rewardPointsOnOrder: PropTypes.number,
  focusBarcode: PropTypes.func,
  scanMode: PropTypes.bool.isRequired,
  toggleScanMode: PropTypes.func.isRequired,
  disablingAddInventory: PropTypes.bool.isRequired,
  changeFulfillmentStatus: PropTypes.func.isRequired,
  orderPackagingWorkflow: PropTypes.number,
  selectedChildItemMasters: PropTypes.array,
  processing: PropTypes.bool,
  isOhMetrc: PropTypes.bool,
  integrationState: PropTypes.object,
  testResults: PropTypes.array,
  isRefillNumberRequired: PropTypes.bool,
  lineItemCouponScanned: PropTypes.bool,
  completeLineItemCouponScan: PropTypes.func,
  blockExpiredProductAction: PropTypes.string,
  isLeafPaConfigPackClosedLoopFacility: PropTypes.bool,
  featureLeafPaMmapEnabled: PropTypes.bool,
  mmapPayments: PropTypes.array,
  removeMmapPayment: PropTypes.func,
  scanInputRef: PropTypes.func.isRequired
};

const WrappedComponent = reduxForm({ validate: validateCartForm, warn: warnCartForm })(Cart);

export default WrappedComponent;
