import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import get from 'lodash.get';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import {Button, Card} from 'react-bootstrap';
import validatePayments from './validatePayments';
import {setItem} from '../../../../actions/itemActions';
import * as itemNames from '../../../../constants/itemNames';
import {
  getChangePaymentInitialValues,
  getMmapPaymentsInitialValues,
  getComputedProductQuantity
} from '../../../../selectors/ordersSelectors';
import PaymentsForm from './PaymentsForm';
import ProductRows from './ProductRows';
import Pagination from './Pagination';
import TotalRows from './TotalRows';
import WillRender from '../../../common/concealers/WillRender';
import LabelPrinter from '../../../print-services/labels/LabelPrinter'; // eslint-disable-line
import ReceiptPrinter from '../../../print-services/labels/LabelPrinter'; //eslint-disable-line
import * as apiActions from '../../../../actions/apiActions';  // eslint-disable-line import/no-named-as-default
import {getUserHasPermissionFunc} from '../../../../selectors/usersSelectors';
import {isFeatureEnabled} from '../../../../selectors/featureToggles';
import ModalWrapper from '../../../common/ModalWrapper';
import MmapPaymentsForm from './MmapPaymentsForm';
import validateMmapPayments from './validateMmapPayments';
import {getPaymentOptions} from '../../../../selectors/salesSettingsSelectors';

const canPrintLabels = getUserHasPermissionFunc('print_labels');

class OrderDetails extends React.PureComponent{

  constructor(props, context) {
    super(props, context);

    this.state = {
      page: 1,
      first:1,
      last:0,
      perPage: 5,
      numRecords:0,
      detailedOrder: false,

      showLabelModal: false,
      showReceiptModal: false,
      autoPrintLabel: false,
      autoPrintReceipt: false,
    };

    this.productId = 'refunded_order_product_id';
    this.inventoryId = 'refunded_order_product_inventory_id';

    this.onPageChange = this.onPageChange.bind(this);
    this.getRefundCount = this.getRefundCount.bind(this);
    this.onHidePrintModal = this.onHidePrintModal.bind(this);
    this.showLabelModal = this.showLabelModal.bind(this);
    this.showReceiptModal = this.showReceiptModal.bind(this);
    this.showSingleLabelModal = this.showSingleLabelModal.bind(this);
  }

  componentWillReceiveProps(nextProps){

    this.setPageState(nextProps);
    this.renderDetailedOrder();

  }

  setPageState(nextProps, pageNumber){

    const numRecords = (this.state.detailedOrder) ? this.state.detailedOrder.products.length : nextProps.data.length;

    const curPage = (pageNumber === undefined) ? this.state.page : pageNumber;

    const first = (curPage === 1)
      ? 1
      : ((curPage - 1) * this.state.perPage) + 1;

    let last = (curPage === 1)
      ? (numRecords < this.state.perPage)
        ? numRecords
        : this.state.perPage
      : this.state.perPage * (curPage);

    if(last > numRecords) last = numRecords;

    this.setState({
      page: curPage,
      numRecords,
      first,
      last
    });

  }

  onPageChange(event, page){

    event.stopPropagation();
    event.preventDefault();

    const pageNumber = (page.number === undefined) ? page : page.number;

    this.setPageState(this.props, pageNumber);

  }

  detailedOrderIsValid(detailedOrder){

    if(!detailedOrder) return false;
    if(detailedOrder === undefined) return false;
    if(detailedOrder.products === undefined) return false;
    if(detailedOrder.products.length === 0) return false;
    if(detailedOrder.products[0].items === undefined && detailedOrder.order_type !== 'refund') return false;
    if(detailedOrder.products[0].items.length === 0 && detailedOrder.order_type !== 'refund') return false;
    return true;

  }

  renderDetailedOrder(){

    const order = this.props.getDetailedOrder(this.props.order);

    if(!this.detailedOrderIsValid(order)) return false;

    const detailedOrder = Object.assign({}, order);

    const products = detailedOrder.products.reduce( (acc, product) => {

      if(product.items === undefined){
        acc.push(product);
        return acc;
      }

      if(product.items.length === 0){
        acc.push(product);
        return acc;
      }

      // If you need to add original products back in as a line uncomment next
      //acc.push(product);

      const items = product.items.map( (item) => {
        // Added product_item_master_id because when doing refunds it should be the parent item_master... not the child
        // but this could break some shit... most specifically getting weights for prepacks
        const obj = Object.assign({}, product, item, {itemLine: true, product_item_master_id: product.item_master_id, order_name: detailedOrder.name});
        return obj;
      });

      acc = acc.concat(items);
      return acc;

    }, []);

    products.forEach( (product) => {

      let refundCount = 0;
      let isRefunded = 0;
      let refundingCount = 0;

      const refunded = this.getRefundedCount(product);

      if(refunded){
        refundCount = isRefunded = refunded.count;
        product.refundedOnOrderName = refunded.name;
        product.refundedDetails = refunded.names;
      }

      refundingCount = this.getRefundCount(product);
      refundCount += this.getRefundCount(product);

      product.refundingCount = refundingCount;
      product.isRefunded = isRefunded;
      product.refunded = refundCount;
      product.refundable = this.isRefundable(product);

    });

    detailedOrder.products = products;
    this.setPageState({data: detailedOrder.products});
    this.setState({detailedOrder});

  }

  haveRefundOrder(orderId) {
    const { refundOrder } = this.props;
    const refundProducts = get(refundOrder, 'products', []);

    let result = false;

    if(refundProducts.length === 0) {
      return result;
    }

    refundProducts.forEach((product) => {
      if (parseInt(get(product, 'refunded_order.order_id')) === parseInt(orderId)) {
        result = true;
      }
    });

    return result;
  }

  /**
   * Returning an amount of refunding items.
   * Can be from the different orders
   * @param data
   * @returns {number}
   */
  getRefundCount(data){

    const productId = this.productId;
    const inventoryId = this.inventoryId;

    if(this.haveRefundOrder(data.order_id) === false) {
      return 0;
    }

    let count = 0;

    this.props.refundOrder.products.forEach((product) => {
      if (data.order_product_id === undefined) {
        if (product[productId] === data.id) count++;
        return true;
      }
      if (product[productId] === data.order_product_id && product[inventoryId] === data.id) count += getComputedProductQuantity(product);
    });

    return count;

  }

  // Refunds that already exist on this order
  getRefundedCount(data){

    const productId = this.productId;
    const inventoryId = this.inventoryId;

    const r = {
      count: 0,
      names: {}
    };

    const addRefundOrder = (refund,qty) => {
      const key = refund.name.replace('.', '_');
      if(r.names[key] === undefined) r.names[key] = 0;
      r.names[key] += qty;
    };

    const key = '_' + data.order_id;

    if(this.props.ordersWithRefunds[key] !== undefined){
      this.props.ordersWithRefunds[key].forEach((refund) => {
        refund.products.forEach((product) => {
          const productQty = getComputedProductQuantity(product);
          if (data.order_product_id === undefined) {
            if (product[productId] === data.id) {
              r.count += productQty;
              addRefundOrder(refund,productQty);
            }
            return true;
          }
          if (product[productId] === data.order_product_id && product[inventoryId] === data.id) {
            r.count += productQty;
            addRefundOrder(refund,productQty);
          }
        });
      });
    }

    return r;
  }

  isRefundable(product) {

    if (this.haveRefundOrder(product.order_id) === false) {
      return true;
    }

    return (product.qty >= this.getRefundCount(product));
  }

  /************* Label And Receipt Printing ****************/

  showLabelModal(){
    if(this.props.order.order_type === 'refund'){
      this.setState({showNoLabelModal: true});
      return false;
    }
    const newState = {
      payload: {
        ids: [this.props.order.id],
        is_order: 1
      },
      showLabelModal: true,
    };
    this.setState(newState);
  }

  showSingleLabelModal(orderProductId, itemId){
    if(this.props.order.order_type === 'refund'){
      this.setState({showNoLabelModal: true});
      return false;
    }
    const newState = {
      payload: {
        ids: [this.props.order.id],
        is_order: 1,
        order_product_id: orderProductId,
        inventory_item_id: itemId,
      },
      showLabelModal: true,
    };
    this.setState(newState);
  }

  showReceiptModal(){
    this.props.actions.setItem(this.props.order, itemNames.order)
      .then(() => {
        this.setState({showReceiptModal: true});
      });
  }

  onHidePrintModal(){
    this.setState({showLabelModal: false, showReceiptModal: false, showNoLabelModal: false});
  }

  /************* END Label And Receipt Printing *************/

  render() {
    const {integrationState, nonMedicatedCategoryId, ordersWithRefunds, isRefundAndRestockUnificationToggled, setIsRefundAndRestock, paymentTypes} = this.props;
    const order = this.props.order;
    const detailedOrder = this.state.detailedOrder;
    const customer = this.props.customer;
    const refundOrders = ordersWithRefunds && ordersWithRefunds[`_${order.id}`] || [];
    const products = detailedOrder ? detailedOrder.products : this.props.data;
    const paymentsInitialValues = getChangePaymentInitialValues({ order: detailedOrder});
    const mmapPaymentsInitialValues = getMmapPaymentsInitialValues({ order: detailedOrder});
    const addMessage = this.props.addMessage;
    const spreadProps = {
      refundQueue: this.props.refundQueue,
      restockQueue: this.props.restockQueue,
      onRefundClick: this.props.onRefundClick,
      onRestockClick: this.props.onRestockClick,
      refundedOrderIds: this.props.refundedOrderIds,
      refundedInventoryItems: this.props.refundedInventoryItems,
    };
    const refundedOrderId = get(this.props, 'refundQueue.refunded_order_id');
    // In PA refunds are restricted to be for a single order (related to MMAP refund)
    const canRefundOrder = !integrationState.isPaLeaf || !refundedOrderId || refundedOrderId === order.id;

    return (
      <div className='child-section col-md-12'>
        <LabelPrinter
          showModal={this.state.showLabelModal}
          onHide={this.onHidePrintModal}
          labelTag='patient_label_large'
          payload={this.state.payload}
          autoPrint={this.state.autoPrintLabel}
        />

        <ReceiptPrinter
          showModal={this.state.showReceiptModal}
          onHide={this.onHidePrintModal}
          labelTag='RETAIL_RECEIPT'
          autoPrint={this.state.autoPrintReceipt}
          qzTrayOff={true}
          receiptOrderId={this.state.receiptOrderId}
        />

        <ModalWrapper
          dialogClassName='modal-sm'
          headerClass='bg-info-dark'
          onHide={() => this.setState({showNoLabelModal: false})}
          showModal={this.state.showNoLabelModal}
          title={I18n.t('orderReceipt.printing.noPrintingTitle')}
          version={2}
        >
          <p>{I18n.t('orderReceipt.printing.noLabelsForRefunds')}</p>
        </ModalWrapper>

        <PaymentsForm
          form={`orderPayments${order.id}`}
          initialValues={paymentsInitialValues}
          enableReinitialize={true}
          order={order}
          registers={this.props.registers}
          paymentTypes={paymentTypes}
          onSubmit={this.props.onPaymentSubmit}
          validate={validatePayments}
        />
        {mmapPaymentsInitialValues && get(mmapPaymentsInitialValues, 'mmapPayments', []).length > 0 &&
          <MmapPaymentsForm
            form={`orderMmapPayments${order.id}`}
            initialValues={mmapPaymentsInitialValues}
            enableReinitialize={true}
            order={detailedOrder}
            registers={this.props.registers}
            customer={customer}
            addMessage={addMessage}
            onSubmit={this.props.onMmapPaymentSubmit}
            validate={validateMmapPayments}
          />
        }
        <Card>
          <Card.Body>
            <div>
              <div style={{fontWeight: 'bold', marginBottom: '0.5rem'}}>
                {I18n.t('cultivation.salesOrders.orderHistory.productsIn')}: {this.props.order.name}
                <WillRender ifTrue={get(order, 'order_status') === 'completed'}>
                  <Button
                    title={!this.props.canPrintLabels ? I18n.t('orderReceipt.printing.noPermissions') : undefined}
                    variant='primary'
                    className='btn-sm'
                    style={{float: 'right', position: 'relative', top: '-5px', marginLeft: '8px'}}
                    disabled={!this.props.canPrintLabels}
                    onClick={(e) => {
                      this.showReceiptModal(order.id);
                    }}
                  >
                    <span style={{textTransform: 'capitalize'}}>{I18n.t('cart.totals.viewPrintReceipt').toLowerCase()}</span>
                  </Button>

                  <Button
                    title={!this.props.canPrintLabels ? I18n.t('orderReceipt.printing.noPermissions') : undefined}
                    variant='primary'
                    className='btn-sm'
                    style={{float: 'right', marginLeft: '8px', position: 'relative', top: '-5px'}}
                    disabled={!this.props.canPrintLabels}
                    onClick={(e) => {
                      this.showLabelModal();
                    }}
                  >
                    <span style={{textTransform: 'capitalize'}}>{I18n.t('cart.totals.viewPrintAllLabels').toLowerCase()}</span>
                  </Button>

                  {
                    isRefundAndRestockUnificationToggled ? (
                      <Button
                        variant='primary'
                        className='btn-sm'
                        style={{float: 'right', position: 'relative', top: '-5px', marginLeft: '8px'}}
                        disabled={!canRefundOrder}
                        onClick={(e) => {
                          setIsRefundAndRestock().then(() => {
                            this.props.onRefundAllClick(e, order, products);
                            this.props.onRestockAllClick(e, order, products);
                          });
                        }}
                      >
                        <span style={{textTransform: 'capitalize'}}>{I18n.t('cart.totals.refundAndRestockAll').toLowerCase()}</span>
                      </Button>
                    ) : (
                      <Button
                        variant='primary'
                        disabled={!canRefundOrder}
                        className='btn-sm'
                        style={{float: 'right', position: 'relative', top: '-5px', marginLeft: '8px'}}
                        onClick={(e) => {
                          this.props.onRestockAllClick(e, order, products);
                        }}
                      >
                        <span style={{textTransform: 'capitalize'}}>{I18n.t('cart.totals.restockAll').toLowerCase()}</span>
                      </Button>
                    )
                  }
                  <Button
                    variant='primary'
                    disabled={!canRefundOrder}
                    className='btn-sm'
                    style={{float: 'right', position: 'relative', top: '-5px', marginLeft: '8px'}}
                    onClick={(e) => {
                      this.props.onRefundAllClick(e, order, products);
                    }}
                  >
                    <span style={{textTransform: 'capitalize'}}>{I18n.t('cart.totals.refundAll').toLowerCase()}</span>
                  </Button>

                </WillRender>
              </div>
              <table className='table table-bordered table-striped product-detail-table'>
                <thead>
                <tr>
                  <th>{I18n.t('cultivation.salesOrders.orderHistory.product')}</th>
                  <th style={{width: '10%'}}>{I18n.t('cultivation.salesOrders.orderHistory.unitSize')}</th>
                  <th style={{width: '20%'}}>{I18n.t('cultivation.salesOrders.orderHistory.unitId')}</th>
                  <th style={{width: '20%', textAlign: 'right'}}>{I18n.t('cultivation.salesOrders.orderHistory.price')}</th>
                  <th style={{width: '30%', textAlign: 'center'}}>{I18n.t('cultivation.salesOrders.orderHistory.actions')}</th>
                </tr>
                </thead>
                <ProductRows
                  refundOrderInitiated={this.props.refundOrderInitiated}
                  restockOrderInitiated={this.props.restockOrderInitiated}
                  showSingleLabelModal = {this.showSingleLabelModal}
                  canPrintLabels={this.props.canPrintLabels}
                  prepackWeights = {this.props.prepackWeights}
                  getDetailedOrder={this.props.getDetailedOrder}
                  refundOrder={this.props.refundOrder}
                  onRestockProduct={this.props.onRestockProduct}
                  data={products}
                  order={detailedOrder}
                  first={this.state.first}
                  last={this.state.last}
                  integrationState={integrationState}
                  nonMedicatedCategoryId={nonMedicatedCategoryId}
                  refundOrders={refundOrders}
                  canRefundOrder={canRefundOrder}
                  {...spreadProps}
                />
                <TotalRows
                  order={detailedOrder}
                  taxes={this.props.taxes}
                />
              </table>
            </div>
          </Card.Body>
          <Pagination
            perPage={this.state.perPage}
            onPageChange={this.onPageChange}
            first={this.state.first}
            last={this.state.last}
            numRecords={this.state.numRecords}
            page={this.state.page}/>
        </Card>
      </div>
    );
  }
}

OrderDetails.propTypes = {
  prepackWeights: PropTypes.array.isRequired,
  ordersWithRefunds: PropTypes.object.isRequired,
  onRestockProduct: PropTypes.func.isRequired,
  refundOrder: PropTypes.object.isRequired,
  getDetailedOrder: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  order: PropTypes.object.isRequired,
  taxes: PropTypes.array.isRequired,
  collapseSelf: PropTypes.func.isRequired,
  refundQueue: PropTypes.object.isRequired,
  restockQueue: PropTypes.array.isRequired,
  onRefundClick: PropTypes.func.isRequired,
  onRestockClick: PropTypes.func.isRequired,
  onRefundAllClick: PropTypes.func.isRequired,
  onRestockAllClick: PropTypes.func.isRequired,
  refundedOrderIds: PropTypes.array.isRequired,
  registers: PropTypes.array.isRequired,
  integrationState: PropTypes.object.isRequired,
  nonMedicatedCategoryId: PropTypes.number,
  getPaymentModificationPaymentTypes: PropTypes.func.isRequired,
  onPaymentSubmit: PropTypes.func.isRequired,
  refundOrderInitiated: PropTypes.bool,
  restockOrderInitiated: PropTypes.bool,
  isRefundAndRestockUnificationToggled: PropTypes.bool.isRequired,
  setIsRefundAndRestock: PropTypes.func.isRequired,
  onMmapPaymentSubmit: PropTypes.func.isRequired,
  canRefundOrder: PropTypes.bool.isRequired
};

function mapStateToProps(state, ownProps){
  return {
    canPrintLabels: canPrintLabels(state),
    isRefundAndRestockUnificationToggled: ownProps.isRefundAndRestockUnificationToggled || isFeatureEnabled(state)('feature_refund_and_restock_unification'),
    paymentTypes: getPaymentOptions(state)
  };
}

function mapDispatchToProps(dispatch) {

  const actions = Object.assign({}, {setItem}, apiActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
