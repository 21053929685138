import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import BulkPackage from './BulkPackage';
import UnitPackage from './UnitPackage';

const LinePackage = ({
  line, change, fieldName, component, lineItemPrice, lineItemQuantity, lineItemMasterChildren, receiptStatus,
  locked, uoms, integrationState, metrcIsImported, internationalNumberFormat, onlyEntireQuantityCanBeTransferred, isReassignMode,
  getValue, hasCostEditPermission
}) => {
  const changeUnitCostAdjustAll = () => {
    line.unit_cost_all_adjust = 1;
  };

  const changeLineUnitPriceAndTotal = (value, fieldPrefix) => {
    // TodoCheck what to use
    line.unit_price = value;
    change(`${fieldPrefix}.unit_price`, value);

    const total = value * line.ordered_qty;
    line.line_item_price = total;
    change(`${fieldPrefix}.line_item_price`, total);

    // If line is a prepack we should change the prepack prices as well
    if (get(line, 'line_type') === 'prepack') {
      // Loop over inventory
      line.inventory.forEach((inventoryItem, inv_index) => {
        inventoryItem.prepack_inventory_rows.forEach((prepackInventoryRow, pp_index) => {
          const prepack_im = lineItemMasterChildren.find((line_im) => line_im.id === prepackInventoryRow.item_master_id);
          if (prepack_im) {
            const itemWeightGram = get(prepack_im, 'itemWeight.weight_base') / 1000000;
            const updated_pp_unit_cost = itemWeightGram * line.unit_price;
            const updated_pp_line_total = prepackInventoryRow.qty * updated_pp_unit_cost;
            change(`${fieldPrefix}.inventory[${inv_index}].prepack_inventory_rows[${pp_index}].unit_cost`, updated_pp_unit_cost);
            change(`${fieldPrefix}.inventory[${inv_index}].prepack_inventory_rows[${pp_index}].line_total`, updated_pp_line_total);
          }
        });
      });
    }
  };

  return (
    line.line_type === 'bulk' || line.line_type === 'prepack'
      ? (<BulkPackage
        onlyEntireQuantityCanBeTransferred={onlyEntireQuantityCanBeTransferred}
        receiptStatus={receiptStatus}
        component={component}
        locked={locked}
        change={change}
        lineType={line.line_type}
        fieldsLine={line}
        uoms={uoms}
        integrationState={integrationState}
        requestedAmountLocked={!line.editableRequestedAmount}
        lineItemPrice={line.editableLinePrice ? line.line_item_price : lineItemPrice}
        lineItemQuantity={lineItemQuantity}
        lineOrderedQuantity={line && line.ordered_qty !== undefined ? line.ordered_qty : '0.00'}
        metrcIsImported={metrcIsImported}
        internationalNumberFormat={internationalNumberFormat}
        editableLinePrice={line && line.editableLinePrice !== undefined ? line.editableLinePrice : false}
        line={fieldName}
        isReassignMode={isReassignMode}
        changeUnitCostAdjustAll={changeUnitCostAdjustAll}
        changeLineUnitPriceAndTotal={changeLineUnitPriceAndTotal}
        getValue={getValue}
        hasCostEditPermission={hasCostEditPermission}
    />)
      :
        (<UnitPackage
          component={component}
          locked={locked}
          change={change}
          integrationState={integrationState}
          line={fieldName}
          fieldsLine={line}
          lineItemPrice={lineItemPrice}
          lineItemQuantity={lineItemQuantity}
          lineOrderedQuantity={line && line.ordered_qty !== undefined ? line.ordered_qty : '0.00'}
          metrcIsImported={metrcIsImported}
          onlyEntireQuantityCanBeTransferred={onlyEntireQuantityCanBeTransferred}
          isReassignMode={isReassignMode}
          changeUnitCostAdjustAll={changeUnitCostAdjustAll}
          changeLineUnitPriceAndTotal={changeLineUnitPriceAndTotal}
          getValue={getValue}
          hasCostEditPermission={hasCostEditPermission}
          editableLinePrice={line && line.editableLinePrice !== undefined ? line.editableLinePrice : false}
        />)
    );
};

LinePackage.propTypes = {
  component: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  fields: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
  line: PropTypes.object.isRequired,
  lineItemPrice: PropTypes.string,
  lineItemQuantity: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  lineItemMasterChildren: PropTypes.array.isRequired,
  locked: PropTypes.bool.isRequired,
  uoms: PropTypes.array.isRequired,
  integrationState: PropTypes.object.isRequired,
  metrcIsImported: PropTypes.bool,
  onlyEntireQuantityCanBeTransferred: PropTypes.bool,
  receiptStatus: PropTypes.string,
  internationalNumberFormat: PropTypes.string.isRequired,
  isReassignMode: PropTypes.bool,
  getValue: PropTypes.func.isRequired,
  hasCostEditPermission: PropTypes.bool.isRequired
};

export default LinePackage;
