import React from 'react';
import {Link} from 'react-router';
import {I18n} from 'react-redux-i18n';
import {Row} from 'react-bootstrap';

const LoginFooter = () => {
  return (
    <Row>
      <span>{I18n.t('user.HavingTroubleLoggingIn')}</span>
      <span>{I18n.t('user.RecoverALost')}
        <Link to='/reset'>{I18n.t('user.usernameOrPassword')}</Link>
      </span>
    </Row>
  );
};


export default LoginFooter;
