import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Button, ButtonGroup} from 'react-bootstrap';

const OrderButtons = ({cancelOrder, editOrder}) => {

  return (
      <ButtonGroup vertical style={{width: '100%'}}>
        <Button block variant='primary' onClick={editOrder} className='edit-order'>{I18n.t('cart.totals.editOrder')}</Button>
        <Button block variant='primary' onClick={cancelOrder} className='cancel-order'>{I18n.t('cart.totals.cancelOrder')}</Button>
      </ButtonGroup>
  );
};

OrderButtons.propTypes = {
  editOrder: PropTypes.func.isRequired,
  cancelOrder: PropTypes.func.isRequired
};


export default OrderButtons;
