/* eslint-disable import/no-mutable-exports*/
import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, FieldArray, Field } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { FormGroup, Row, Col, ListGroup, ListGroupItem } from 'react-bootstrap';
import get from 'lodash.get';
import { inventory_receipt_document } from '../../../../constants/fileTypes';
import InboundTransferDetail from '../common-components/InboundTransferDetail';
import DriverAndVehicleInformation from '../common-components/InboundDriverAndVehicleDetail';
import LinePackages from './components/LinePackages';
import OrderTotals from './components/OrderTotals';
import SubmitSection from '../../../common/form/SubmitSection';
import validate from './validate';
import ReceiptFilesFieldArray from './components/receipt-files/ReceiptFilesFieldArray';
import TextAreaInput from '../../../common/form/TextAreaInput';
import { convertDbDateToFormInputDate } from '../../../../util/dateHelpers';
import AccordionPanel from '../../../common/AccordionPanel';
import ReassignSaveButton from '../../reassign-packages/ReassignSaveButton';
import ReactSelectInput from '../../../common/form/ReactSelectInput';

export const TransferForm = (props) => {
  const {
    reset,
    change,
    handleSubmit,
    onPrintLabels,
    createDriver,
    getValue,
    isInEditMode,
    canPrintLabels,
    vehicles,
    drivers,
    timezone,
    initialValues,
    pristine,
    supplyChainMapping,
    itemMasterChildren,
    saveStateText,
    storageLocations,
    sharedLocations,
    isApplyStorageLocation,
    itemMasters,
    purchaseOrder,
    lineItemPrices,
    lineItemQuantities,
    summaryTotals,
    submitting,
    locked,
    onCancel,
    resetPackageCodeKey,
    touch,
    integrationState,
    trackingIds,
    uoms,
    receiptStatus,
    handleKeyPress,
    hasPrintPermission,
    onScannedTrackingId,
    isReturn,
    pendingValidation,
    inventoryTypes,
    biotrackSettings,
    isMetrcTransfersEnabled,
    existingNotes,
    displaySourceFields,
    internationalNumberFormat,
    formValues
  } = props;

  const submitSectionSettings = {
    actionSettings: {
      reset: {
        text: I18n.t('common.form.reset'),
        action: () => {
          reset();
          // Force unit_price to original values... not sure why reset doesn't do the job
          purchaseOrder.lines.forEach((line, index) => {
            const lineKey = 'lines[' + index + ']';
            change(`${lineKey}.unit_price`, parseFloat(line.unit_price).toFixed(2));
          });
        },
        style: 'default'
      },
      saveAndPrint: {
        text: saveStateText['saveAndPrint'],
        disabled: !canPrintLabels,
        display: hasPrintPermission ? null : 'hide',
        style: 'success',
        action: () => {
          change('afterSubmit', 'saveAndPrint');
          onPrintLabels();
          handleSubmit();
        },
        invalid: submitting || pendingValidation
      },
      submit: {
        type: 'button',
        style: 'primary',
        text: saveStateText['submit'],
        display: saveStateText['state'] ? 'hide' : null,
        action: () => {
          handleSubmit();
          //change('status', 'completed');
        },
        invalid: submitting || pendingValidation
      },
      reassignPackages: {
        template: key => ( //eslint-disable-line
          <ReassignSaveButton
            key={key}
            timezone={timezone}
            isReturn={isReturn}
            purchaseOrder={purchaseOrder}
            initialValues={initialValues}
            lineTotals={lineItemPrices}
            itemMasters={itemMasters}
            itemMasterChildren={itemMasterChildren}
            supplyChainMapping={supplyChainMapping}
            handleSubmit={handleSubmit}
            disabled={submitting || pristine}
            integrationState={integrationState}
            change={change}
            formValues={formValues}
          />
        )
      },
    },
    align: 'right'
  };
  const { partnerFacility } = purchaseOrder;
  const lockStatus = getValue('lock_status');
  const onlyEntireQuantityCanBeTransferred = getValue('onlyEntireQuantityCanBeTransferred') || false;

  const hasLinkedTransferDetails = Boolean(purchaseOrder && purchaseOrder.linked_transfer_details) || false;
  const isInitialInventoryCreation = Boolean(purchaseOrder && purchaseOrder.is_initial);
  const metrcIsImported = integrationState.isMetrc && get(purchaseOrder, 'is_imported', 0);

  // Coming from the form initial values
  const isMedicated = getValue('contains_medicated');
  const medicatedReceiptRequiresMapping = getValue('medicatedReceiptRequiresMapping');
  const loadedFromSupplyChainMapping = getValue('loadedFromSupplyChainMapping');

  return (
    <form
      className='transfer-form inventory-receipt'
      onSubmit={handleSubmit}
      noValidate={true}
      onKeyPress={handleKeyPress}
    >
      <InboundTransferDetail
        locations={storageLocations}
        locked={locked}
        onCancel={onCancel}
        isInEditMode={isInEditMode}
        receiptStatus={receiptStatus}
        lockStatus={lockStatus}
        purchaseOrder={purchaseOrder}
        isReturn={isReturn}
        integrationState={integrationState}
        metrcIsImported={metrcIsImported}
        isMetrcTransfersEnabled={isMetrcTransfersEnabled}
        getValue={getValue}
      />
      {
        isMedicated && medicatedReceiptRequiresMapping && loadedFromSupplyChainMapping ? null :
          <DriverAndVehicleInformation
            drivers={drivers}
            vehicles={vehicles}
            change={change}
            createDriver={createDriver}
            locked={locked}
            getValue={getValue}
          />
      }



        <FormGroup className='transfer-packages'>
          <h4>{I18n.t('cultivation.transfers.form.packages')}</h4>

        <Row>
          <Col xs={6} sm={4} md={3}>
            Previously applied storage locations for ALL items will be over-written by this one
            <Field
              name='default_storage_location' component={ReactSelectInput}
              props={{
                label: I18n.t('purchaseOrders.form.applyStorageLocationToAllPackages'),
                options: [{value: null, text: I18n.t('none') }].concat(storageLocations),
                isRequired: false,
                placeholder: I18n.t('none')
              }} />
            </Col>
          </Row>

        <FieldArray
          name='lines'
          component={LinePackages}
          getValue={getValue}
          formValues={formValues}
          storageLocations={storageLocations}
          sharedLocations={sharedLocations}
          itemMasterChildren={itemMasterChildren}
          isApplyStorageLocation={isApplyStorageLocation}
          change={change}
          touch={touch}
          itemMasters={itemMasters}
          orderLines={purchaseOrder.lines || []}
          partnerFacility={partnerFacility}
          lineItemPrices={lineItemPrices}
          lineItemQuantities={lineItemQuantities}
          locked={locked}
          resetPackageCodeKey={resetPackageCodeKey}
          trackingIds={trackingIds}
          integrationState={integrationState}
          uoms={uoms}
          hasLinkedTransferDetails={hasLinkedTransferDetails}
          isInitialInventoryCreation={isInitialInventoryCreation}
          biotrackSettings={biotrackSettings}
          onScannedTrackingId={onScannedTrackingId}
          inventoryTypes={inventoryTypes}
          metrcIsImported={metrcIsImported}
          onlyEntireQuantityCanBeTransferred={onlyEntireQuantityCanBeTransferred}
          displaySourceFields={displaySourceFields}
          receiptStatus={receiptStatus}
          internationalNumberFormat={internationalNumberFormat}
        />
      </FormGroup>

      <div className='row'>
        <div className='col-md-12'>
          <h4 style={{ marginTop: '0' }}>{I18n.t('orderDocumentsAndSummary')}</h4>
        </div>
        <div className='col-md-6'>
          <FieldArray
            name='files'
            component={ReceiptFilesFieldArray}
            props={{
              change,
              locked,
              name: 'files',
              types: [inventory_receipt_document],
              files: getValue('files')
            }}
          />
          <OrderTotals summaryTotals={summaryTotals} />
        </div>
      </div>

      <AccordionPanel title='purchaseOrders.form.notes' className='accordion-panel'>
        <FormGroup className='notes'>
          {existingNotes && existingNotes.length ? (
            <ListGroup>
              {existingNotes.map((note, index) => (
                <ListGroupItem key={index}>
                  <b>{convertDbDateToFormInputDate(note.created_at)}</b> {note.note}
                </ListGroupItem>
              ))}
            </ListGroup>
          ) : null}

          {!saveStateText['state'] ? (
            <div>
              <Field
                name='new_note'
                component={TextAreaInput}
                props={{
                  placeholder: I18n.t('purchaseOrders.form.notePlaceholder'),
                  rows: 3,
                  label: ''
                }}
              />
            </div>
          ) : null}
        </FormGroup>
      </AccordionPanel>
      <SubmitSection settings={submitSectionSettings} />
    </form>
  );
};

TransferForm.propTypes = {
  form: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  reset: PropTypes.func,
  change: PropTypes.func,
  touch: PropTypes.func,
  onPrintLabels: PropTypes.func.isRequired,
  createDriver: PropTypes.func.isRequired,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  isApplyStorageLocation: PropTypes.bool,
  drivers: PropTypes.array.isRequired,
  vehicles: PropTypes.array.isRequired,
  storageLocations: PropTypes.array.isRequired,
  sharedLocations: PropTypes.array.isRequired,
  itemMasters: PropTypes.array.isRequired,
  itemMasterChildren: PropTypes.object.isRequired,
  purchaseOrder: PropTypes.object.isRequired,
  saveStateText: PropTypes.object.isRequired,
  lineItemPrices: PropTypes.array.isRequired,
  lineItemQuantities: PropTypes.array.isRequired,
  summaryTotals: PropTypes.object.isRequired,
  locked: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  resetPackageCodeKey: PropTypes.func.isRequired,
  canPrintLabels: PropTypes.bool.isRequired,
  integrationState: PropTypes.object.isRequired,
  trackingIds: PropTypes.array.isRequired,
  anyTouched: PropTypes.bool,
  isInEditMode: PropTypes.bool,
  uoms: PropTypes.array.isRequired,
  receiptStatus: PropTypes.string.isRequired,
  supplyChainMapping: PropTypes.object.isRequired,
  handleKeyPress: PropTypes.func,
  hasPrintPermission: PropTypes.bool,
  onScannedTrackingId: PropTypes.func.isRequired,
  isReturn: PropTypes.bool.isRequired,
  pendingValidation: PropTypes.bool.isRequired,
  inventoryTypes: PropTypes.array.isRequired,
  biotrackSettings: PropTypes.object,
  isMetrcTransfersEnabled: PropTypes.bool,
  existingNotes: PropTypes.array,
  displaySourceFields: PropTypes.bool,
  timezone: PropTypes.string.isRequired,
  initialValues: PropTypes.object,
  internationalNumberFormat: PropTypes.string.isRequired,
  internationalDateFormat: PropTypes.string.isRequired,
  formValues: PropTypes.object.isRequired
};

TransferForm.defaultProps = {
  receiptStatus: '',
  existingNotes: []
};

export default reduxForm({
  validate,
  enableReinitialize: true
})(TransferForm);
