import {I18n} from 'react-redux-i18n';

import {GET_DATA_SUCCESS} from '../constants/actionTypes';
import * as dataNames from '../constants/dataNames';
import {roundQtyCurried} from '../selectors/uomsSelectors';
import {isPaLeafIntegrator} from '../selectors/integration/leafSelectors';
import {isFeatureEnabled} from '../selectors/featureToggles';


/**
 * Transform api response data (by redux key) so incoming data is transformed application wide
 *
 * @param store
 * @returns {function(*): Function}
 */
export const transformApiResponseData = store => next => action => {
  if (action.type !== GET_DATA_SUCCESS) return next(action);

  const {name, payload: {data}} = action;
  if (typeof data !== 'undefined') {
    let transformedData = [...data];
    const roundQty = roundQtyCurried(store.getState());
    let featureName;
    const state = store.getState();

    switch (name) {
    case dataNames.harvests:
      transformedData.forEach(harvest => (applyPrecisionForAttrs(harvest, ['current_weight'], roundQty)));
      break;
    case dataNames.partnerFacilities:
      featureName = 'feature_append_partner_facility_types_to_facility_names_for_distinction';

      if (isFeatureEnabled(state)(featureName)) {
        transformedData = transformPartnerFacilities(transformedData, isPaLeafIntegrator(state));
      }

      break;
    }

    action.payload.data = transformedData;
  }

  return next(action);
};


/**
 * Round specified attributes for a given object using a specified rounding function
 *
 * @param obj
 * @param attrs
 * @param roundFunc
 * @returns {*}
 */
const applyPrecisionForAttrs = (obj, attrs, roundFunc) => {
  return attrs.reduce((obj, attr) => {
    obj[attr] = roundFunc(obj[attr]);
    return obj;
  }, obj);
};


/**
 * Transforms partner facility names for distinguishment for Leaf PA
 *
 * @param partnerFacilities
 * @param state
 * @returns {*}
 */
export const transformPartnerFacilities = (partnerFacilities, isPaLeaf) => {
  // Deep clone the array of objects before any transformation is done
  partnerFacilities = JSON.parse(JSON.stringify(partnerFacilities));

  if (!isPaLeaf) return partnerFacilities;

  return partnerFacilities.reduce((result, partnerFacility) => {
    let facilityType;
    partnerFacility = {...partnerFacility};

    if (partnerFacility.is_cultivator) {
      facilityType = 'cultivator';
    } else if (partnerFacility.is_processor) {
      facilityType = 'processor';
    }

    const appendStr = `(${I18n.t(`facilities.type.${facilityType}`)})`;

    if (facilityType && !partnerFacility.facility_name.includes(appendStr)) {
      partnerFacility.facility_name += ` ${appendStr}`;
    }

    result.push(partnerFacility);

    return result;
  }, []);
};

export default transformApiResponseData;
