import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getCurrentUser} from '../../selectors/usersSelectors';
import getCurrentFacility from '../../selectors/facility/getCurrentFacility';

class IntercomWidget extends React.PureComponent {
  constructor (props, context) {
    super(props, context);
  }

  componentDidMount () {
    const {app_id, user, facility} = this.props;
    if (app_id) {
      (function(w, d, id, s, x) {
        function i() {
          i.c(arguments);
        }
        i.q = [];
        i.c = function(args) {
          i.q.push(args);
        };
        w.Intercom = i;
        s = d.createElement('script');
        s.async = 1;
        s.src = `https://widget.intercom.io/widget/${id}`;
        d.head.appendChild(s);
      })(window, document, app_id);
    }

    if (window.Intercom) {
      this.configure(user, facility);
      window.Intercom('boot');
    }
  }

  componentWillUnmount () {
    if (window.Intercom) {
      window.Intercom('shutdown');
      delete window.intercomSettings;
      delete window.Intercom;
    }
  }

  configure (user, facility) {
    const {app_id} = this.props;
    const organization_name = (typeof user.userOrganizations[0] !== 'undefined' ? user.userOrganizations[0].name : '');
    if (window.Intercom) {
      window.intercomSettings = {
        name: `${user.firstName} ${user.lastName}`,
        user_name: user.name,
        email: user.email,
        module_type: facility.type,
        company_name: facility.name,
        facility_province: facility.province_code,
        facility_id: facility.id,
        created_at: user.createdAt,
        app_id,
        company: {
          id: facility.organizationId,
          name: organization_name,
          company_province: facility.province_code,
        }
      };
    }
  }

  render () {
    return false;
  }
}

function mapStateToProps (state) {
  return {
    user: getCurrentUser(state),
    facility: getCurrentFacility(state),
    app_id: state.env.intercomAppId
  };
}

IntercomWidget.propTypes = {
  user: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(IntercomWidget);
