import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {Col, FormGroup, Row, Button} from 'react-bootstrap';
import {FaCalendarAlt} from 'react-icons/fa';
import get from 'lodash.get';
import TextInput from '../../../common/form/TextInput';
import InternationalDateTimePickerInput from '../../../common/form/InternationalDateTimePickerInput';
import ReactSelectInput from '../../../common/form/ReactSelectInput';
import ReassignPackageButton from '../../reassign-packages/ReassignPackageButton';

const statuses = [
  {value: 'open', text: I18n.t('cultivation.transfers.form.created')},
  {value: 'completed', text: I18n.t('cultivation.transfers.form.received')},
  {value: 'cancelled', text: I18n.t('cultivation.transfers.form.cancelled')}
];

const approvalStatuses = [
  {value: '', text: I18n.t('cultivation.transfers.form.pending')},
  {value: 'approved', text: I18n.t('cultivation.transfers.form.approved')},
  {value: 'denied', text: I18n.t('cultivation.transfers.form.denied')}
];

const InboundTransferDetail = (props) => {
  const {
    locked, onCancel, isInEditMode, receiptStatus, lockStatus, purchaseOrder, isReturn,
    integrationState, metrcIsImported, isMetrcTransfersEnabled, getValue, integrationState: {isMetrc}
  } = props;

  const isMedicated = getValue('contains_medicated');
  const containsOnlyCbdFlowerOrHempWaste = getValue('contains_only_cbd_flower_or_hemp_waste');
  const medicatedReceiptRequiresMapping = getValue('medicatedReceiptRequiresMapping');
  const loadedFromSupplyChainMapping = getValue('loadedFromSupplyChainMapping'); // Not sure if this works
  const status = getValue('status');
  const isMjpTransfer = !!get(purchaseOrder, 'partnerFacility.connect_facility_code');

  const warningMessage =
    purchaseOrder.internal_sales_order_linked && !purchaseOrder.linked_transfer_details ?
    'This Purchase Order is linked to your partner\'s Sales Order but the transfer has not yet been created.' :
    purchaseOrder.internal_sales_order_linked && purchaseOrder.linked_transfer_details || isMedicated && medicatedReceiptRequiresMapping && loadedFromSupplyChainMapping ?
      'This Purchase Order is linked to your partner\'s Sales Order.  Many of the details below have been pre-completed for you from the Transfer Details.' :
      '';

  return (
    <FormGroup className='incoming-transfer'>
      {integrationState.isMetrc && !metrcIsImported && isMetrcTransfersEnabled &&
      <div className='alert alert-warning'>{I18n.t('supplyChain.metrcWarningInventoryReceipt')}</div>
      }
      <Field component={TextInput} name='is_return' value={!!isReturn} type='hidden'/>
      <Row>
        <Col xs={6} md={4}>
          <Row>
            <Col xs={12} sm={12} md={12}>
              <h3>{I18n.t('cultivation.transfers.form.incomingTransfer')}</h3>
            </Col>
          </Row>
          <Row>
            <Col xs={8} sm={8} md={8}>
              <Field
                name='po_number'
                component={TextInput}
                props={{
                  label: I18n.t('cultivation.transfers.form.purchaseOrder'),
                  disabled: true
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={6} md={4}>
          <Row>
            <Col xs={6} sm={6} md={12}>
              <Field
                name='vendor_name'
                component={TextInput}
                props={{
                  label: I18n.t('cultivation.transfers.form.partner'),
                  disabled: true
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={6} sm={6} md={6}>
              <Field
                name='license_number'
                disabled
                component={TextInput}
                props={{
                  label: I18n.t('cultivation.transfers.form.licenseNumber'),
                  disabled: true
                }}
              />
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Field
                name='vendor_facility_name'
                disabled
                component={TextInput}
                props={{
                  label: I18n.t('cultivation.transfers.form.DBA'),
                  disabled: true
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col sm={12} md={4} className='text-right'>
          {(!isMetrc && locked && receiptStatus === 'completed') && (
            <ReassignPackageButton />
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={4} sm={3} md={3} lg={2}>
          <InternationalDateTimePickerInput
            name='date_received'
            props={{
              label: I18n.t('cultivation.transfers.form.dateReceived'),
              leftAddon: (<FaCalendarAlt/>),
              inputProps: {disabled: locked || !!isReturn},
              isRequired: status === 'completed',
            }}
          />
        </Col>
        <Col xs={4} sm={3} md={3} lg={2}>
          <InternationalDateTimePickerInput
            name='estimated_departure_time'
            props={{
              dateFormat: false,
              timeFormat: true,
              enableTodayButton: false,
              label: I18n.t('cultivation.transfers.form.departureTime'),
              inputProps: {disabled: locked}
            }}
          />
        </Col>
        <Col xs={4} sm={3} md={3} lg={2}>
          <InternationalDateTimePickerInput
            name='estimated_arrival_time'
            props={{
              dateFormat: false,
              timeFormat: true,
              enableTodayButton: false,
              label: I18n.t('cultivation.transfers.form.arrivalTime'),
              inputProps: {disabled: locked}
            }}
          />
        </Col>
        <Col xs={4} sm={3} md={3} lg={2}>
          <Field name='status' component={ReactSelectInput} props={{
            label: I18n.t('cultivation.transfers.form.status'),
            options: statuses,
            disabled: locked || lockStatus || integrationState.isWaLeaf,
          }}/>
        </Col>
        {
          // Don't show approval_status for CBD / Hemp transfers within MJP (i.e. connectFacilityCode exist)
          (!!containsOnlyCbdFlowerOrHempWaste && !isMjpTransfer) &&
            <Col xs={4} sm={3} md={3} lg={2}>
              <Field name='approval_status' component={ReactSelectInput} props={{
                label: I18n.t('cultivation.transfers.form.approvalStatus'),
                options: approvalStatuses,
                disabled: true,
              }}/>
            </Col>
        }
      </Row>
      {receiptStatus !== 'cancelled' && isInEditMode && onCancel && <Row>
        <Col xs={6} sm={4} md={3}>
          <Button onClick={onCancel} variant='danger' type='button' disabled={locked}>
            {I18n.t('salesOrders.form.cancelTransfer')}
          </Button>
        </Col>
      </Row>}
      {
        warningMessage.length ?
          (<div className='alert alert-info'>
              <div>{warningMessage}</div>
          </div>) :
        null
      }
    </FormGroup>
  );
};

InboundTransferDetail.propTypes = {
  locked: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  isInEditMode: PropTypes.bool,
  isReturn: PropTypes.bool,
  receiptStatus: PropTypes.string.isRequired,
  lockStatus: PropTypes.bool,
  purchaseOrder: PropTypes.object.isRequired,
  integrationState: PropTypes.object.isRequired,
  metrcIsImported: PropTypes.bool,
  isMetrcTransfersEnabled: PropTypes.bool,
  getValue: PropTypes.func,
};

export default InboundTransferDetail;
