import {getLocalStorage} from '../util/localStorage';


const getUserMetaData = (responseData) => {
  return {
    //organizationId: responseData.organization_id,
    //facilityId: responseData.facility_id,
    id: responseData.user_id,
    isMasterUser: responseData.is_master_user
  };
};

const getFacilityIds = (facilities) => {
  return Object.keys(facilities).map((key) => {
    return facilities[key].id;
  });
};

const getOrganizationIds = (organizations) => {
  return Object.keys(organizations).map((key) => {
    return organizations[key].id;
  });
};

const getRoleIds = (roles) => {
  return [roles.id];
};

const normalizeUserData = (userData) => {
  return {
    createdAt: userData.created_at,
    email: userData.email,
    firstName: userData.first_name,
    language: userData.language,
    lastName: userData.last_name,
    name: userData.name,
    stateId: userData.state_id,
    status: userData.status,
    updatedAt: userData.updated_at,
    image_file_id: userData.image_file_id,
    facilities: getFacilityIds(userData.facilities),
    permissions: userData.facilities,
    organizations: getOrganizationIds(userData.organizations)
  };
};

const normalizeUser = (responseData) => {
  return Object.assign({}, getUserMetaData(responseData), normalizeUserData(responseData.user));
};

const translateFacility = (facility) => {
  return {
    id: facility.id,
    key: facility.facility_key,
    name: facility.name,
    type: facility.type,
    organizationId: facility.organization_id,
    roles: facility.roles ? getRoleIds(facility.roles) : [],
    city: facility.city,
    postal_code: facility.postal_code,
    province_code: facility.province_code,
    street_address_1: facility.street_address_1,
    street_address_2: facility.street_address_2,
    timezone: facility.timezone,
    country_code: facility.country_code,
    retail_type: facility.retail_type,
    configuration_packs: facility.configuration_packs,
    has_leaf_pa_config_pack_and_closed_loop: facility.has_leaf_pa_config_pack_and_closed_loop,
    has_leaf_utah_config_pack_and_closed_loop: facility.has_leaf_utah_config_pack_and_closed_loop
  };
};

const normalizeFacilities = (facilities) => {
  return Object.keys(facilities).map((key) => {
    return translateFacility(facilities[key]);
  });
};

const normalizeOrganizations = (organizations) => {
  return Object.keys(organizations).map((key) => {
    return {
      id: organizations[key].id,
      key: organizations[key].organization_key,
      name: organizations[key].name
    };
  });
};

const normalizeRoles = (facilities) => {
  return Object.keys(facilities).map((key) => {
    return facilities[key].roles
      ? {
        id: facilities[key].roles.id,
        name: facilities[key].roles.role_name
      }
      : {};
  });
};

const getDefaultFacility = (facilities) => {
  const keys = Object.keys(facilities);
  // Skip facility selection screen if this user only has one facility
  if (keys.length == 1) {
    return translateFacility(facilities[keys[0]]);
  }

  // For now a user only lives in one org
  const organizationId = facilities[keys[0]].organization_id;
  const defaultFacilityId = getLocalStorage(`localSettings.org_${organizationId}.defaultFacility`);
  // Will only set the facility if the user has access to it.
  const defaultFacility = Object.values(facilities).find((facility) => (facility.id == defaultFacilityId) );

  if (defaultFacility) {
    return translateFacility(defaultFacility);
  }


  return {};
};

const normalizeLoginResponse = (responseData) => {
  return {
    token: responseData.token,
    user: normalizeUser(responseData),
    facilities: normalizeFacilities(responseData.user.facilities),
    facility: getDefaultFacility(responseData.user.facilities),
    organizations: normalizeOrganizations(responseData.user.organizations),
    roles: normalizeRoles(responseData.user.facilities)
  };
};

export default normalizeLoginResponse;
