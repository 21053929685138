import React from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {goBack, push} from 'react-router-redux';
import {functionallyEmpty} from '../../../../../util/dataHelpers';
import * as itemNames from '../../../../../constants/itemNames';
import * as dataNames from '../../../../../constants/dataNames';
import {getItem, getUnpaginatedData, putData, putItem, postItem, getSearchData} from '../../../../../actions/apiActions';
import {unsetItem} from '../../../../../actions/itemActions';
import {getRewardInitialValues} from '../../../../../selectors/rewardsSelectors';
import RewardFormWrapper from './components/RewardFormWrapper';

export class RewardEditor extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.callbackHandler = this.callbackHandler.bind(this);
  }

  componentDidMount() {
    const { actions, rewardId } = this.props;
    actions.unsetItem(itemNames.reward);

    // fetch products
    const params = {
      sort: 'name asc, display_name asc',
      query: 'matchall',
      size: '10000',
      start: '0',
      filter: 'active:1 AND is_draft:0 AND prepack_weight_id:0 AND is_ingredient:0 AND !subcategory_id:74'
    };
    this.props.actions.getSearchData('/api/search/item_masters', dataNames.products, {failed: 'products.get.failed'}, params);
    // fetch partners
    actions.getUnpaginatedData('/api/partners', dataNames.partners, {failed: 'partners.get.failed'}, null, function(){
      // fetch customer groups
      actions.getUnpaginatedData('/api/consumer_groups', dataNames.customerGroups, {failed: 'customers.groups.get.failed'});
      actions.getUnpaginatedData('/api/product_lists', dataNames.productLists);
      if(rewardId > 0) {
        actions.getItem(`/api/rewards/${rewardId}`, itemNames.reward, {failed: 'retail.rewards.reward.messages.get.failed'}, null, this.callbackHandler);
      }
    });

  }

  callbackHandler(data) {
    const fEmpty = functionallyEmpty; // checks for empty object, empty array, undefined, and null; uses lodash.get to safely access

    if(fEmpty(this.props, dataNames.products) && !fEmpty(data, 'item_master_ids')){
      const params = {
        sort: 'name asc, display_name asc',
        query: 'matchall',
        size: '10000',
        start: '0',
        filter: 'active:1 AND is_draft:0 AND prepack_weight_id:0 AND is_ingredient:0 AND !subcategory_id:74'
      };
      this.props.actions.getSearchData('/api/search/item_masters', dataNames.products, {failed: 'products.get.failed'}, params);
      if(fEmpty(this.props, dataNames.categories)){
        this.props.actions.getUnpaginatedData('/api/categories', dataNames.categories, {failed: 'categories.get.failed'});
      }
    }
  }

  onSubmit(formData) {
    const url = (formData.id !== undefined) ? `/api/rewards/${formData.id}` : '/api/rewards';
    const method = (formData.id !== undefined) ? 'putItem' : 'postItem';
    const messages = {
      failed: (formData.id !== undefined) ? 'retail.rewards.reward.messages.modify.failed' : 'retail.rewards.reward.messages.create.failed'
    };

    const normalizeFormData = (formData) => {
      let vendorIds = [];
      if (formData.vendor_ids) {
        vendorIds = formData.vendor_ids.map(vendor => {
          if (typeof vendor === 'number') {
            return vendor;
          }
          return vendor.id;
        });
      }
      let itemMasterIds = [];
      if (formData.item_master_ids) {
        itemMasterIds = formData.item_master_ids.map(item_master => {
          if (typeof item_master === 'number') {
            return item_master;
          }
          return item_master.id;
        });
      }
      let productListIds = [];
      if (formData.product_list_ids) {
        productListIds = formData.product_list_ids.map(product_list => product_list.id);
      }

      return {
        ...formData,
        vendor_ids: vendorIds,
        item_master_ids: itemMasterIds,
        product_list_ids: productListIds
      };
    };

    const normalizedFormData = normalizeFormData(formData);

    this.props.actions[method](url, normalizedFormData, itemNames.reward, messages, null, () => {
      this.props.actions.goBack();
    });
  }

  render() {
    const {initialValues} = this.props;
    return (
      <RewardFormWrapper
        initialValues={initialValues}
        onSubmit={this.onSubmit}
      />
    );
  }
}

RewardEditor.propTypes = {
  actions: PropTypes.object,
  initialValues: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    initialValues: getRewardInitialValues(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {getItem, getUnpaginatedData, goBack, push, putData, unsetItem, putItem, postItem, getSearchData};

  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RewardEditor);
