import React from 'react';
import {Field} from 'redux-form';
import {Col, Row} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import NumericInput from '../../common/form/NumericInput';

const PackagingJobWeightPanel = (props) => {
  const { disabled } = props;

  return (
    <Row>
      <Col xs={12}>
        <Field name='total_packed_weight' component={NumericInput} props={{
          label: I18n.t('packages.prePack.totalPacked'),
          fractionPartSize: 2,
          disabled: true,
          isRequired: true,
          rightAddon: <span>GR</span>,
        }}/>
      </Col>
      <Col xs={12}>
        <Field name='waste_reported' component={NumericInput} props={{
          label: I18n.t('packages.prePack.wasteReported'),
          fractionPartSize: 2,
          disabled: disabled,
          allowNegativeNumber: false,
          rightAddon: <span>GR</span>,
        }}/>
      </Col>
      <Col xs={12}>
        <Field name='packaged_loss_amt' component={NumericInput} props={{
          label: I18n.t('packages.prePack.lossAmount'),
          fractionPartSize: 2,
          disabled: disabled,
          allowNegativeNumber: false,
          rightAddon: <span>GR</span>,
        }}/>
      </Col>
      <Col xs={12}>
        <Field name='remaining_weight' component={NumericInput} props={{
          label: I18n.t('packages.prePack.remainingWeight'),
          fractionPartSize: 2,
          disabled: true,
          isRequired: true,
          allowNegativeNumber: false,
          rightAddon: <span>GR</span>,
        }}/>
      </Col>
    </Row>
  );
}

PackagingJobWeightPanel.propTypes = {
};
export default PackagingJobWeightPanel;
