import { createSelector } from 'reselect';
import uniqBy from 'lodash.uniqby';
import get from 'lodash.get';
import { I18n } from 'react-redux-i18n';

import * as itemNames from '../constants/itemNames';
import * as dataNames from '../constants/dataNames';
import { isLeafIntegrator } from './integration/leafSelectors';
import { isMetrcIntegrator } from './integration/metrcSelectors';
import { getIntegrationState } from './integration/integrationSelectors';
import { getHighestRankedEntityLock } from '../util/entity_locks';

const getEntityLocks = (state) => state[dataNames.entityLocks];
export const getPlant = (state) => state[itemNames.plant];
export const getPlantMapping = (state) => state[itemNames.plantMapping];
const getStats = (state) => get(state.meta, 'plantStats.facets.facet_fields.stage_name', {});
const getPlants = (state) => state.plants;
const getInvComplianceSettings = (state) => state[itemNames.invComplianceSettings];
const getCupos = (state) => state[dataNames.cupos];
export const getSelectedPlants = (state) => state.selectedPlants || [];
export const trackPlantsAsGroup = (state) =>
  state.complianceSettings &&
  state.complianceSettings.cult_track_plants_as_groups &&
  state.complianceSettings.cult_track_plants_as_groups.value;
export const createPlantFromInventory = (state) =>
  state.complianceSettings &&
  state.complianceSettings.cult_create_plant_from_inventory &&
  state.complianceSettings.cult_create_plant_from_inventory.value;

const getPlantId = (_, props) => props.plant_id;
const getMotherPlantId = (_, props) => props.mother_plant_id;
const getMotherPlants = (state, _) => state.motherPlants;
const getItemsPerPage = (_, props) => props.itemsPerPage;
const getActivePage = (_, props) => props.activePage;

export const getAllStages = (state) => state[dataNames.stages];

export const getStages = createSelector(
  getPlants,
  (plants) => uniqBy(plants.map((plant) => ({ field: 'stage_name', value: plant.stage_name })), 'value')
);

export const getPlantIds = createSelector(
  getPlants,
  (plants) => plants.map((plant) => ({ value: plant.id, text: plant.plant_id }))
);

export const getSelectedPlantIds = createSelector(
  getSelectedPlants,
  (selectedPlants) => selectedPlants.map((selectedPlant) => selectedPlant.id)
);

export const getSelectedPlantStrainIds = createSelector(
  [getSelectedPlants],
  (plants) =>
    plants.reduce(
      (strains, plant) => (strains.indexOf(plant.strain_id) > -1 ? strains : [...strains, plant.strain_id]),
      []
    )
);

export const getFormattedPlantCost = (state) => {
  const cost = get(state, itemNames.plantCost, 0);
  return (typeof cost === 'number') ? cost.toFixed(2) : '0.00';
};

export const getArchiveInactiveAfter = createSelector(
  [getInvComplianceSettings],
  (settings) => {
    return parseInt(get(settings, 'inv_compliance_settings.value.0.archive_inactive_inventory_after', '0')) || 0;
  }
);

export const getPlantByMotherPlantId = createSelector(
  [getMotherPlantId, getPlants],
  (motherPlantId, plants) => {
    return plants.reduce((previous, current) => {
      return current.id === motherPlantId ? current : previous;
    }, null);
  }
);

export const getPlantExternalId = createSelector(
  [getPlant, getPlantMapping, isLeafIntegrator, isMetrcIntegrator],
  (plant, mapping, isLeaf, isMetrc) => {
    if (isLeaf) {
      return (plant.id === Number(mapping.internal_identifier) && mapping.external_identifier) || null;
    } else if (isMetrc) {
      return plant.state_integration_tracking_id;
    }
    return null;
  }
);

// const getStrain = (_, props) => props.strain_id;
// export const getPlantsByStrain = createSelector([getPlants, getStrain], (plants, strain_id) => plants.filter( plant => plant.strain_id === strain_id));
// export const getSelectedPlants = createSelector(
//   [getSelectedPlantIds, getPlants],
//   (selectedPlantIds, plants) =>  plants.filter(plant => selectedPlantIds.indexOf(plant.id) > -1)
// );

export const getPlantIdRange = createSelector(
  [getPlantId, getPlants],
  (plantId, plants) => {
    return plants.map((plant) => {
      const { plant_id } = plant;
      const dotIndex = plant_id.indexOf('.');
      return plant_id.substring(dotIndex);
    });
  }
);

export const getSelectedPlantPageData = createSelector(
  [getSelectedPlants, getItemsPerPage, getActivePage],
  (selectedPlants, itemsPerPage, activePage) => {
    if (!selectedPlants.length) {
      return {
        items: [],
        start: 0,
        end: 0,
        total: 0,
        pagesCount: 0
      };
    }
    const total = selectedPlants.length;

    const pagesCount = Math.ceil(total / itemsPerPage);
    const start = itemsPerPage * (activePage - 1) < total ? itemsPerPage * (activePage - 1) : total - 1;
    const end = itemsPerPage * activePage < total ? itemsPerPage * activePage : total;
    const items = selectedPlants.slice(start, end);

    return {
      activePage,
      items,
      start: start + 1,
      end,
      total,
      pagesCount
    };
  }
);

export const getPlantStats = createSelector(
  getStats,
  (stats) => {
    const decorated = {
      total: 0,
      veget: 0,
      propag: 0,
      flower: 0
    };

    Object.values(stats).map((i) => {
      decorated.total = decorated.total + i;
    });
    return { ...decorated, ...stats };
  }
);

const getSelectedPlantCupos = createSelector(
  [getSelectedPlants, getCupos, getIntegrationState],
  (plants, cupos, integrationState) => {
    if (integrationState.isColombia) {
      let cupo_ids = plants && plants.map((plant) => plant.cupo_id);

      if (cupo_ids) {
        cupo_ids = [...new Set(cupo_ids)];
        return cupos.filter((cupo) => ~cupo_ids.indexOf(cupo.id));
      }
    }

    return [];
  }
);

export const getModifyPlantsInitialValues = createSelector(
  [getSelectedPlants, getSelectedPlantCupos],
  (selectedPlants, plantCupos) => ({
    selectedPlants: selectedPlants.map((plant) => ({
      ...plant,
      initial_state_integration_tracking_id: plant.state_integration_tracking_id
    })),
    room_ids: [],
    zone_ids: [],
    num_plants: 0,
    modality: null,
    location_id: [],
    building_ids: [],
    plant_cupos: plantCupos
  })
);

export const getMotherPlantById = createSelector(
  [getMotherPlants, getMotherPlantId],
  (plants, plant_id) => {
    return plants.find((plant) => plant.id === plant_id);
  }
);

export const getPlantsError = (errors) => {
  const response = get(errors, 'response.data');
  const error_starting_stage_id = get(
    response,
    'errors.VALIDATION.starting_phase_id.0',
    get(response, 'errors.VALIDATION.starting_stage_id.0')
  );
  const error_starting_qty = get(response, 'errors.VALIDATION.starting_qty.0');
  if (response.errors && Object.keys(response.errors).length === 0) {
    return { message: true };
  }
  return { message: error_starting_stage_id || error_starting_qty || I18n.t('plants.create.fail') };
};

export const canPlantsBeActivated = createSelector(
  [getSelectedPlants, getIntegrationState],
  (plants, { isBiotrack }) =>
    !isBiotrack || plants.every((plant) => plant.is_destroyed && !plant.bt_destroyed_at && plant.external_identifier)
);

export const maturePlantsRequireTracking = (state) => {
  return (
    state[itemNames.complianceSettings] &&
    state[itemNames.complianceSettings].cult_mature_plants_require_tracking_id &&
    state[itemNames.complianceSettings].cult_mature_plants_require_tracking_id.value &&
    state[itemNames.complianceSettings].cult_mature_plants_require_tracking_id.value.value === true
  );
};

export const maturePlantsRequireTrackingErrorMessage = (state) => {
  return state[itemNames.complianceSettings] &&
    state[itemNames.complianceSettings].cult_mature_plants_require_tracking_id &&
    state[itemNames.complianceSettings].cult_mature_plants_require_tracking_id.value
    ? state[itemNames.complianceSettings].cult_mature_plants_require_tracking_id.value.error_message
    : '';
};

export const getDecoratedPlants = (plants, entityLocks) => {
  const entityLocksLookup = entityLocks.reduce((acc, entityLock) => {
    const plantId = entityLock.entity_id;
    if(!acc[plantId]){
      acc[plantId] = [];
    }
    acc[plantId].push(entityLock);
    return acc;
  }, {});
  return plants.map((plant) => {
    if(typeof plant === 'object') {
      const entityLocks = entityLocksLookup[plant.id] ? entityLocksLookup[plant.id] : [];
      plant.entity_locks = entityLocks;
      plant.highest_ranked_entity_lock = getHighestRankedEntityLock(entityLocks);
    }
    return plant;
  });
};

export const getDecoratedPlantsSelector = createSelector([getPlants, getEntityLocks], (plants, entityLocks) => {
  return getDecoratedPlants(plants, entityLocks);
});
