import toArray from 'lodash.toarray';
import isEmpty from 'lodash.isempty';
import get from 'lodash.get';
import map from 'lodash.map';
import { createSelector } from 'reselect';
import moment from 'moment';
import {
  convertFormInputDateToDbDate,
  formatDBDate, convertDbToClientTZ
} from '../util/dateHelpers';
import * as dataNames from '../constants/dataNames';
import { getCategories } from './categorySelectors';
import { isLeafIntegrator, isPaLeafIntegrator, isWaLeafIntegrator } from './integration/leafSelectors';
import { getItemMastersWithInventoryType, getProductType, isMedicated, isWaste } from './itemMastersSelectors';
import * as itemNames from '../constants/itemNames';
import { getSalesOrdersIsLab } from './salesOrdersSelectors';
import { getFlattenedLocations, getLocationsForSharedProducts } from './locationsSelectors';
import { getFormPackages } from './forms/modifyPackageFormSelectors';
import { isMetrcIntegrator } from './integration/metrcSelectors';
import { convertFromBase } from '../util/uomHelpers';
import {convertTerms} from '../util/solrHelpers';
import {isFeatureEnabled} from './featureToggles';

const getItemReservations = (state) => state[dataNames.reservations];
const getTimezone = (state) => state.timezone;
const getInventoryItems = (state, selected) => (!selected ? state.inventoryItems : state.selectedInventoryItems);
//the code above can lead to unexpected behavior if some depending selector would use the second param
const getItems = (state) => state[dataNames.inventoryItems];
const getLots = (state) => state[dataNames.lots];
const getItemsMapping = (state) => state[dataNames.inventoryItemsMapping];
const getActiveTabEventKey = (state) => state[itemNames.activeTabEventKey];

/**
 * For item reservation level we're using two diff dataNames instead of one
 * for backward compatibility was left two
 * @param state
 * @returns {*}
 */
const getReservations = (state) =>
  isEmpty(state[dataNames.hardReservations]) ? state[dataNames.reservations] : state[dataNames.hardReservations];
export const getTransfers = (state) => state[dataNames.transfers];
export const getOpenOrders = (state) => state[dataNames.orders];

/**
 * Returned inventories with their active reservations
 * @type {Reselect.Selector<any, any>}
 */
export const getInventoryItemsWithReservation = createSelector(
  [getInventoryItems, getReservations],
  (items, reservations) =>
    items.map((item) => ({
      ...item,
      reservations: reservations.filter((r) => r.item_id == item.id)
    }))
);

export const getPrepackWeights = (state) => state[dataNames.prepackWeights];
export const canSetSourceFacility = (lot) => Boolean(lot.partner_facility_source === 'import');
export const getInventoryItem = (state) => state[itemNames.inventoryItem];

export const getMappingsByPackage = createSelector(
  [getInventoryItem, getItems, getItemsMapping, isLeafIntegrator],
  (item, items, mappings, isLeaf) =>
    items.reduce((acc, inventoryItem) => {
      if (isLeaf && inventoryItem.package_id === item.package_id) {
        const mapping = mappings.find((mapping) => Number(mapping.internal_identifier) === inventoryItem.id);
        if (mapping) {
          acc.push(mapping);
        }
      }
      return acc;
    }, [])
);

export const getItemExternalId = createSelector(
  [getMappingsByPackage, getInventoryItem],
  (mappings, inventoryItem) => getExternalIdByItemId(mappings, inventoryItem.id)
);

export function getExternalIdByItemId(mappings, itemId) {
  const mapping = mappings.find((mapping) => Number(mapping.internal_identifier) === itemId);
  return (mapping && mapping.external_identifier) || '';
}

export const groupedByLot = createSelector(
  getInventoryItemsWithReservation,
  (inventoryItems) => {
    const lots = {};
    inventoryItems.forEach((item) => {
      if (Object.keys(lots).indexOf(item.lot_id.toString()) === -1) {
        lots[item.lot_id] = {
          id: item.lot_id,
          lot_number: item.lot_number,
          packages: []
        };
      }
      lots[item.lot_id].packages.push(
        Object.assign({}, item, {
          qty: item.qty
        })
      );
    });
    return toArray(lots);
  }
);

export const groupedByItemMasterId = createSelector(
  [getInventoryItemsWithReservation, getSalesOrdersIsLab, isPaLeafIntegrator],
  (inventoryItems, isLab, isPaLeaf) => {
    const revampItems = (groups, item) => {
      //skip on-hold items unless we are fulfilling lab order
      if (!isPaLeaf && item.on_hold === 1 && !isLab) {
        return groups;
      }

      if (!groups[item.item_master_id]) {
        groups[item.item_master_id] = [];
      }

      groups[item.item_master_id].push({
        ...item,
        package_code: item.package_code || `${item.id}.${item.item_master_id}`
      });

      return groups;
    };

    return inventoryItems.reduce(revampItems, {});
  }
);

//Get Producer. We assume that items without producer are being produced by the current facility
export function getItemProducer(item, activeFacility, partners) {
  if (!item.producer_id) {
    return activeFacility;
  }
  return partners.find((partner) => partner.id === item.producer_id);
}

export function getItemProducerName(item, activeFacility, partners) {
  const producer = getItemProducer(item, activeFacility, partners);
  return (producer && producer.name) || '';
}

export const getReconciliationInitialValues = createSelector(
  [getInventoryItems, getCategories, getPrepackWeights, getItemReservations, isMetrcIntegrator, getActiveTabEventKey],
  (items, categories, prepackWeights, reservations, isMetrc, activeTabEventKey) => {
    // Aggregate reservations into a lookup by id
    const isCategoryExisting = (categories, newCategory) => {
      return categories.some(category =>
        category.name === newCategory.name || category.id === newCategory.id);
    }
    const addCategoryIfNotExists = (categories, newCategory) => {
      if (!isCategoryExisting(categories, newCategory)) {
        categories.push(newCategory);
      }
    }

    const AllCategory = {
      id: 0,
      category_code: 'All',
      name: 'Audit',
      is_archived: 0,
      deleted_at: null,
      subcategories: []
    };

    // Add the new category only if it doesn't exist already
    addCategoryIfNotExists(categories, AllCategory);

    const reservationsLookup = reservations.reduce((acc, res) => {
      const itemId = res.item_id;
      if(!acc[itemId]){
        acc[itemId] = Object.assign({}, res);
      } else {
        acc[itemId].qty_base += res.qty_base !== undefined ? res.qty_base : 0;
      }
      const tmp = acc[itemId];
      tmp.qty_reserved = convertFromBase(tmp.qty_base, tmp.uom_display);
      tmp.qty_reserved_base = `${tmp.qty_base}`;
      return acc;
    }, {});

    // TODO: in case still need Metrc Reconciliation logic, this can be removed after confirm
    if (isMetrc && activeTabEventKey) {
      items = map(items, (item) => {
        return {
          ...get(item, 'doclist.docs.0'),
          children: get(item, 'doclist.docs')
        };
      });
    }

    const prepackLookup = prepackWeights.reduce((acc, weight) => {
      acc[weight.id] = weight;
      return acc;
    }, {});

    return {
      categories: categories
        .map((category) => {
          return Object.assign({}, category, {
            items: items
              .filter((item) => item.category_id === category.id)
              .map((item) => {
                const prepackWeight = prepackLookup[item.prepack_weight_id];
                const prepackWeightUom = get(prepackWeight, 'uom', null);
                const reservation = reservationsLookup[item.item_id];
                if (reservation && prepackWeightUom) {
                  reservation.qty_reserved = reservation.qty_base / get(prepackWeight, 'weight_base');
                }
                return Object.assign({}, item, {
                  received_by_user_id: null,
                  item_master: Object.assign({}, item, {id: item.item_master_id}),
                  vendor: {name: item.vendor_name},
                  quantity: item.physical_count || item.qty,
                  discrepancy: item.discrepancy || '0.00',
                  item_name: item.name,
                  acc_back_to_parent: 0,
                  prepack_uom: prepackWeightUom,
                  prepack_weight: prepackWeightUom ? convertFromBase(get(prepackWeight, 'weight_base'), prepackWeightUom) : null,
                  prepack_weight_raw: get(prepackWeight, 'weight_base'),
                  qty_reserved: reservation && reservation.qty_reserved ? reservation.qty_reserved : 0,
                  qty_reserved_base: reservation && reservation.qty_reserved_base ? reservation.qty_reserved_base : '0'
                });
              })
          });
        })
        .filter((category) => category.items.length)
    };
  }
);

const prepareLotsData = (lot) => {
  return {
    ...lot,
    disabled: lot.partner_facility_id && lot.partner_facility_source === 'import'
  };
};

export const getEditableLots = createSelector(
  [getLots],
  (lots) => lots.filter((lot) => canSetSourceFacility(lot)).map(prepareLotsData)
);

export const getModifyPackagesInitialValues = createSelector(
  [getItems, getItemMastersWithInventoryType, getTimezone, getEditableLots, getTransfers],
  (items, itemMasters, timezone, lots, transfers) => {
    if (!timezone) timezone = 'UTC';
    return {
      integration_adjustment_reason: '',
      packages: items.map((item) => {
        const itemMaster = itemMasters.find(
          (itemMaster) => itemMaster.id === item.item_master_id || itemMaster.id === item.item_master_parent_id
        );
        const itemName = get(itemMaster, 'display_name', null) || get(itemMaster, 'name', null) || item.name;

        const itemType = getProductType(
          Object.assign({}, itemMaster, { inventory_attributes: { is_prepack: item.is_prepack } })
        );

        return {
          itemType,
          itemMaster,
          id: item.id,
          packaged_at: convertDbToClientTZ(item.packaged_at, timezone), // In this case, yes to timezone
          package_created_at: moment(item.package_created_at), // Always just a date
          package_expires_at: item.package_expires_at !== null ? moment(item.package_expires_at) : '', // Always just a date
          initial_packaged_at: convertDbToClientTZ(item.packaged_at), // Used to see if there was a change... should not need to be changed
          initial_package_created_at: moment(item.package_created_at), // Always just a date
          initial_package_expires_at: moment(item.package_expires_at), // Always just a date
          package_code: item.package_code,
          package_id: item.package_id,
          item_master_id: item.item_master_id,
          new_item_master_id: item.item_master_id,
          new_item_master_display_name: itemName,
          itemMasterChanged: 0,
          qty: item.qty,
          qty_reserved: item.qty_reserved,
          newQty: item.qty,
          qtyChanged: 0,
          transacted_qty: itemType === 'bulk' ? '0.00' : 0,
          state_integration_tracking_id: item.state_integration_tracking_id,
          initial_state_integration_tracking_id: item.state_integration_tracking_id,
          integration_id_platform_correction: false,
          hasTrackingId: item.state_integration_tracking_id,
          uom: item.uom,
          is_waste: item.is_waste || 0,
          integration_type: item.integration_type,
          add_back_to_parent: 0,
          purpose: item.purpose,
          finished: item.finished || 0,
          medically_compliant: isWaLeafIntegrator && item.medically_compliant ? item.medically_compliant : 0,
          medically_compliant_status:
            isWaLeafIntegrator && item.medically_compliant_status ? item.medically_compliant_status : false,
          cupo_id: item.cupo_id,
          modality: item.modality,
          hasOutForDeliveryTransfers:
            isWaLeafIntegrator &&
            transfers.some((transfer) => {
              return (
                transfer.status === 'out_for_delivery' &&
                item.package_id == get(transfer, 'lines.0.inventory.0.package_id')
              );
            }),
          is_test_package: item.is_test_package,
          is_trade_sample: item.is_trade_sample,
          is_donation: item.is_donation,
          is_produced: item.is_produced,
          unit_cost: item.unit_cost
        };
      }),
      lots
    };
  }
);

export const getModifyPackagesPayload = (formData, timezone) => {
  return {
    on_hold: formData.on_hold,
    is_reserved: formData.is_reserved,
    inventory_location_id: formData.inventory_location_id || undefined,
    notes: formData.notes || undefined,
    adjustment_reason: formData.adjustment_reason || undefined,
    tag_requested:
      formData.tag_requested_dirty == 1 && formData.tag_requested != undefined ? formData.tag_requested : undefined,
    integration_adjustment_reason: formData.integration_adjustment_reason || undefined,
    received_by_user_id: formData.received_by_user_id || undefined,
    manufacturing_new_phase_id: formData.new_phase_id || undefined,
    event_date: formData.event_date ? convertFormInputDateToDbDate(formData.event_date, timezone) : undefined,
    items: formData.packages.map((item) => {
      const dateFields = {};
      const fields = ['packaged_at', 'package_created_at', 'package_expires_at', 'medically_compliant'];
      let includePackageId = true; // if not included package does not update
      fields.forEach((field) => {
        if (item[`${field}_dirty`] === undefined) {
          dateFields[field] = undefined; // if not changed - don't include in post
          return true;
        }
        //This allows clients to remove the expiration date for packages. Only gets called when it goes from a date to ''
        if (item[`${field}_dirty`] && field == 'package_expires_at' && item[field] == '') {
          dateFields[field] = 'should_be_null';
          return true;
        }

        // Only includes date fields if they've been changed, if not don't update package on the BE
        const value =
          item[field] === undefined // While dirty - may be unchanged
            ? undefined
            : field === 'packaged_at'
            ? convertFormInputDateToDbDate(item.packaged_at, timezone) !== formData[`initial_${field}`]
              ? convertFormInputDateToDbDate(item.packaged_at, timezone) //
              : undefined
            : formatDBDate(item[field]) !== formData[`initial_${field}`] // DO NOT NEED TIMEZONE
            ? formatDBDate(item[field]) // DO NOT NEED TIMEZONE
            : undefined;
        dateFields[field] = value;
        if (value !== undefined) includePackageId = true;
      });

      if (item.integration_id_platform_correction) {
        // Send only what is needed and no more
        return {
          id: item.id,
          state_integration_tracking_id: item.state_integration_tracking_id || undefined,
          integration_id_platform_correction: 1
        };
      }

      return {
        // Normal payload
        id: item.id,
        inventory_location_id: formData.inventory_location_id || undefined,
        notes: formData.notes || undefined,
        is_reserved: formData.is_reserved || undefined,
        transacted_qty: item.transacted_qty,
        new_item_master_id: item.new_item_master_id !== item.item_master_id ? item.new_item_master_id : undefined,
        force_update_item_master: item.new_item_master_id !== item.item_master_id ? item.new_item_master_id : undefined,
        manufacturing_new_phase_id: formData.new_phase_id || undefined,
        adjustment_reason: formData.adjustment_reason || item.adjustment_reason || undefined,
        integration_adjustment_reason:
          formData.integration_adjustment_reason || item.integration_adjustment_reason || undefined,
        received_by_user_id: formData.received_by_user_id || item.received_by_user_id || undefined,
        state_integration_tracking_id: item.state_integration_tracking_id || undefined,
        integration_id_platform_correction: 0,
        add_back_to_parent: item.add_back_to_parent !== undefined ? item.add_back_to_parent : 0,
        packaged_at: dateFields.packaged_at,
        package_created_at: dateFields.package_created_at,
        package_expires_at: dateFields.package_expires_at,
        package_id: includePackageId ? item.package_id : undefined,
        purpose: item.purpose || undefined,
        finished: item.finished || 0,
        finished_at: item.finished && item.finished_dirty ? moment().format('YYYY-MM-DD HH:mm:ss') : undefined,
        medically_compliant: (isWaLeafIntegrator && item.medically_compliant) || 0,
        modality: item.modality,
        tag_requested: formData.tag_requested !== undefined ? formData.tag_requested : item.tag_requested,
        is_test_package: item.is_test_package,
        unit_cost:item.unit_cost,
        unit_cost_all_adjust: item.unit_cost_all_adjust ? 1 : 0
      };
    })
  };
};

export const getActivateInventoryPayload = (formData, timezone) => {
  return {
    event_date: formData.event_date ? convertFormInputDateToDbDate(formData.event_date, timezone) : undefined,
    action: 'activate',
    items: formData.items.map((item) => ({
      id: item.id,
      transacted_qty: item.transacted_qty,
      inventory_location_id: item.inventory_location_id || undefined,
      integration_adjustment_reason: item.integration_adjustment_reason || undefined,
      received_by_user_id: item.received_by_user_id || undefined
    }))
  };
};

export function isReserved(item) {
  return item && item.is_reserved === 1;
}

export function isOnHold(item) {
  return item && item.on_hold === 1;
}

/**
 * Check if found option has available quantity greater than zero
 * @param item
 * @return {boolean}
 */
export function isAvailable(item, isAllowNegativeInventory) {
  if (isAllowNegativeInventory) {
    return true;
  }
  return Boolean(item && item.maxQty > 0);
}

export const isReservedInventoryPackage = createSelector(
  getItems,
  (items) => {
    if (items.length) {
      return items.some((item) => (item.reservations || []).length);
    }

    return false;
  }
);

/**
 * Checks whether packaging/split is allowed for the given item.
 * Leaf restricts any item changes for mapped items unless there are lab results.
 * Assuming all lot tracked medicated items to be mapped
 * @param {object} item
 * @param {boolean} isLeaf
 * @param {boolean} isManufacturing
 * @return {boolean}
 */
export function isItemProcessable(item, isLeaf, isManufacturing = false) {
  return isItemMovable(item, isLeaf, isManufacturing) && !isWaste(item);
}

/**
 *
 * @param item
 * @returns {boolean}
 */
export function isItemProcessableSplitWa(item) {
  return !isWaste(item);
}

/**
 * Check whether quick move is allowed for the given item
 * Leaf restricts any item changes for mapped items unless there are lab results.
 * @param item
 * @param isLeaf
 * @param isManufacturing
 * @return {boolean}
 */
export function isItemMovable(item, isLeaf, isManufacturing = false) {
  if (isLeaf && isMedicated(item) && isManufacturing && item.lot_id) return true;
  return !(isLeaf && isMedicated(item) && item.lot_id && !item.lab_results_id);
}

/**
 * Checks whether packaging/quick move/split is allowed for the given array of items.
 * @param {object[]} items
 * @param {boolean} isLeaf
 * @return {boolean}
 */
export function areItemsProcessable(items, isLeaf) {
  return items && items.every((item) => isItemProcessable(item, isLeaf));
}

/**
 *
 * @param items
 * @param isLeaf
 * @returns {*}
 */
export function areItemsProcessableSplit(items, isLeaf) {
  return items && items.every((item) => isItemProcessableSplitWa(item, isLeaf));
}

/**
 *
 * @param items
 * @param isLeaf
 * @returns {*}
 */
export function validateWaSplit(items, isLeaf) {
  return items && items.every((item) => isItemSplit(item, isLeaf));
}

/**
 *
 * @param item
 * @param isLeaf
 * @returns {boolean}
 */
export function isItemSplit(item, isLeaf) {
  return !(isLeaf && item.medically_compliant_status === 'pending');
}

const getReduxForm = (state) => state.form;
const getFormName = (_, props) => props.formName;

const getModifyPackageFormValues = createSelector(
  [getReduxForm, getFormName],
  (form, formName) => {
    if (form[formName] && form[formName].values) return form[formName].values;
    return {};
  }
);

export const hasValue = (value) => {
  return value === undefined || value === null ? false : typeof value === 'string' ? value.trim() !== '' : true;
};

export const modPackageFormHasGlobalAdjustmentReason = createSelector(
  [getModifyPackageFormValues],
  (values) => {
    return hasValue(values.adjustment_reason);
  }
);

export const modPackageFormHasGlobalIntegrationAdjustmentReason = createSelector(
  [getModifyPackageFormValues],
  (values) => {
    return hasValue(values.integration_adjustment_reason);
  }
);

export const modPackageFormHasAllIntegrationAdjustmentReasons = createSelector(
  [getModifyPackageFormValues],
  (values) =>
    (values &&
      values.packages &&
      values.packages.length > 0 &&
      values.packages.every((p) => p.integration_adjustment_reason)) ||
    false
);

export const modPackageFormHasGlobalValues = createSelector(
  [getModifyPackageFormValues],
  (values) => {
    const globalFields = ['inventory_location_id', 'on_hold', 'is_reserved'];
    return globalFields.reduce((acc, field) => {
      if (acc) return acc;
      if (hasValue(values[field])) acc = true;
      return acc;
    }, false);
  }
);

export const isItemTestable = (item) => Boolean(item && isMedicated(item) && !isWaste(item));

export const getModifyPackagesFlattenedLocations = createSelector(
  [getFlattenedLocations, getLocationsForSharedProducts, getFormPackages],
  (locations, sharedLocations, packages) => {
    const hasSharedItems = packages.some((item) => get(item, 'itemMaster.is_shared_item'));
    return hasSharedItems ? sharedLocations : locations;
  }
);

export const getStartPackagingFlattenedLocations = createSelector(
  [getFlattenedLocations, getLocationsForSharedProducts, getInventoryItem],
  (locations, sharedLocations, inventoryItem) => {
    const isSharedItem = Boolean(get(inventoryItem, 'item_master.is_shared_item', false));
    return isSharedItem ? sharedLocations : locations;
  }
);

export const getInventorySolrReworkQueryAndFilter = (query, filter, inventorySolrReworkEnabled) => {
  let updatedQueryString = query;
  let updatedFilter = filter;

  if (inventorySolrReworkEnabled) {
    const clearedQuery = query.toLowerCase().trim();
    const convertedTerms = convertTerms(clearedQuery);
    updatedQueryString = '';
    updatedFilter = `(name: (${convertedTerms}) OR global_id: (${convertedTerms}) OR package_code: (${convertedTerms}) OR state_integration_tracking_id: (${convertedTerms}) OR lot_number: (${convertedTerms})) AND ${filter}`;
  }

  return { updatedQueryString, updatedFilter };
};

export const getSolrCoreName = createSelector(
  [isFeatureEnabled],
  (isFeatureEnabled) => {
    return isFeatureEnabled('feature_solr_rework_new_inventory') ? 'new_inventory' : 'inventory';
  }
);
