import React from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {isPristine, submit} from 'redux-form';
import {goBack} from 'react-router-redux';
import {I18n} from 'react-redux-i18n';
import {pick} from 'lodash';
import get from 'lodash.get';

import * as itemNames from '../../../../constants/itemNames';
import * as dataNames from '../../../../constants/dataNames';
import {getItem, getUnpaginatedData, putData, getDataByPost} from '../../../../actions/apiActions';
import {unsetItem} from '../../../../actions/itemActions';
import {getModifyCouponInitialValues, getPayload} from '../../../../selectors/couponsSelectors';
import FormWrapper from '../../../common/form/FormWrapper';
import LabelPrinter from '../../../print-services/labels/LabelPrinter';
import ModifyCouponFormWrapper from './ModifyCouponFormWrapper';
import {getFacilities} from '../../../../selectors/facilitiesSelectors';
import {getIntegrationState} from '../../../../selectors/integration/integrationSelectors';
import {MODIFY_COUPON_FORM} from '../../../../constants/forms';
import {isFeatureEnabled} from '../../../../selectors/featureToggles';

export class ModifyCouponPage extends React.PureComponent {
  constructor(props) {
    super(props);

    const print = get(this.props, 'location.query.print');
    this.state = {
      shouldPrint: false,
      showPrintModal: print === '1'
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.props.actions.unsetItem(itemNames.coupon);
    const {params: {id}} = this.props;
    this.props.actions.getItem(
      `/api/coupons/${id}`,
      itemNames.coupon,
      {failed: 'retail.coupons.modify.getCouponFailed'},
      {detailed: 1}
    );
    this.props.actions.getUnpaginatedData('/api/categories', dataNames.categories);
    this.props.actions.getUnpaginatedData('/api/partners', dataNames.partners, {failed:'partners.get.failed'}, {sell_to: 1, purchase_from: 1});
    this.props.actions.getUnpaginatedData('/api/consumer_groups', dataNames.customerGroups);
    const searchParams = {
      filter: 'active:1 AND is_draft:0 AND is_ingredient:0 AND item_master_parent_id:0 AND !subcategory_id:74',
      query:'',
      size:10000,
      sort: 'name asc, display_name asc',
      fields: ['id', 'strain_id', 'category_id', 'subcategory_id', 'name'],
      start: 0
    };
    this.props.actions.getDataByPost(
      '/api/search/item_masters',
      searchParams,
      dataNames.products,
      {failed: 'products.get.failed'}
    );
    this.props.actions.getUnpaginatedData('/api/product_lists', dataNames.productLists);
  }

  onSubmit(coupon) {
    // Get and reset shouldPrint state
    const {shouldPrint} = this.state;
    if (shouldPrint) this.setState({shouldPrint: false});

    const {params: {id}} = this.props;
    const couponPayload = getPayload(coupon, pick(this.props, ['timezone', 'facilities']));
    const putCallback = shouldPrint ?
      () => this.setState({shouldPrint: false, showPrintModal: true}) :
      this.props.actions.goBack;

    this.props.actions.putData(
      `/api/coupons/${id}`,
      couponPayload,
      dataNames.coupons,
      {
        success: 'retail.coupons.modify.success',
        failed: 'retail.coupons.modify.failed',
      },
      null,
      putCallback
    );
  }

  render() {
    const {initialValues, facilities, integrationState, isProductListsFeatureEnabled, params: {id}, actions, isPristine} = this.props;

    return (
      <React.Fragment>
        <FormWrapper title={'retail.coupons.modify.title'} goBack={actions.goBack} className='create-coupon-page'>
          <ModifyCouponFormWrapper
            initialValues={initialValues}
            onSubmit={this.onSubmit}
            facilities={facilities}
            integrationState={integrationState}
            isProductListsFeatureEnabled={isProductListsFeatureEnabled}
            customSubmitSettings={{
              submitAndPrint: {
                text: I18n.t('common.printSku'),
                action: () => {
                  // If it's pristine, no need to submit the form
                  if (isPristine) {
                    this.setState({showPrintModal: true});
                    return;
                  }

                  this.setState(
                    {shouldPrint: true},
                    () => actions.submit(MODIFY_COUPON_FORM)
                  );
                },
                disableFor: {
                  pristine: false,
                  submitting: true,
                  invalid: true
                }
              }
            }}
          />
        </FormWrapper>
        <LabelPrinter
          showModal={this.state.showPrintModal}
          onHide={() => this.setState({showPrintModal: false})}
          labelTag='coupon_2x1'
          payload={{id: [id]}}
        />
      </React.Fragment>
    );
  }
}

ModifyCouponPage.propTypes = {
  actions: PropTypes.shape({
    getItem: PropTypes.func.isRequired,
    getUnpaginatedData: PropTypes.func.isRequired,
    getSearchData: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    putData: PropTypes.func.isRequired,
    unsetItem: PropTypes.func.isRequired,
  }).isRequired,
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  initialValues: PropTypes.object,
  timezone: PropTypes.string.isRequired,
  facilities: PropTypes.array.isRequired,
  integrationState: PropTypes.object.isRequired,
  isProductListsFeatureEnabled: PropTypes.bool.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    integrationState:  getIntegrationState(state),
    initialValues: getModifyCouponInitialValues(state, ownProps),
    timezone: state.timezone,
    facilities: getFacilities(state),
    isPristine: isPristine(MODIFY_COUPON_FORM)(state),
    isProductListsFeatureEnabled: isFeatureEnabled(state)('feature_product_lists')
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {getDataByPost, getItem, getUnpaginatedData, goBack, putData, unsetItem, submit};

  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModifyCouponPage);
