import get from 'lodash.get';
import * as types from '../../constants/actionTypes';

export const isChangeAction = (action, forms, fieldName = false, isTail = false) => {
  return isAction(action, forms, types.REDUX_FORM_CHANGE, fieldName, isTail);
};

export const isBlurAction = (action, forms, fieldName = false, isTail = false) => {
  return isAction(action, forms, types.REDUX_FORM_BLUR, fieldName, isTail);
};

export const isChangeActionOnField = (action, forms, fieldName, isTail = false) => {
  return isChangeAction(action, forms, fieldName, isTail);
};


export const isArrayPush = (action, forms, fieldName = false, isTail = false) => {
  return isAction(action, forms, types.REDUX_FORM_ARRAY_PUSH, fieldName, isTail);
};

export const isArrayPushOnField = (action, forms, fieldName, isTail = false) => {
  return isArrayPush(action, forms, fieldName, isTail);
};

export const isArrayInsert = (action, forms, fieldName) => {
  return isAction(action, forms, types.REDUX_FORM_ARRAY_INSERT, fieldName);
};

export const isArrayRemove = (action, forms, fieldName = false, isTail = false) => {
  return isAction(action, forms, types.REDUX_FORM_ARRAY_REMOVE, fieldName, isTail);
};

export const isArrayRemoveOnField = (action, forms, fieldName, isTail = false) => {
  return isArrayRemove(action, forms, fieldName, isTail);
};

// Original function here mapped to updated version
export const isFormChangeAction = (action, forms) => {
  return isChangeAction(action, forms, false, false);
};

// Original function here mapped to updated version
export const isFormChangeOnField = (action, forms, fieldName, tail) => {
  return isChangeActionOnField(action, forms, fieldName, tail);
};

export const isFormInitializing = (action, forms) => {
  return isAction(action, forms, types.REDUX_FORM_INITIALIZE);
};

export const getActionsFromBatchAction = (action) => {
  return (action.type === types.REDUX_BATCH_ACTION) ? action.payload : [action];
};


// GENERICS USED INTERNALLY

// Base function of all other functions.. could be called directly but others help to self document
export const isAction = (action, forms, actionType, fieldName = false, isTail = false) => {
  const isAction = isReduxAction(action, forms, actionType);
  if(!fieldName || !isAction) return isAction;
  return isReduxActionOnField(action, fieldName, isTail);
};

export const isReduxAction = (action, forms, actionType) => {
  return Boolean(action && action.meta && forms.indexOf(action.meta.form) > -1 && action.type === actionType);
};

export const isReduxActionOnField = (action, fieldName, isTail) => {
  const field = action.meta.field.split('.');
  return field.length > 1 && !isTail
    ? Boolean(field.find(piece => piece === fieldName))
    : fieldName === field.pop();
};

export const getFormArrayIndexFromString = (string, prefix = false) => {
  if (prefix) {
    string = string.split(prefix).pop();
  }
  const regex = /\[[0-9]{0,10}?\]/;
  const found = string.match(regex);
  return parseInt(get(found, '0').replace(']', '').replace('[', ''));
};

export const isField = (field, targetField) => {
  return field.indexOf(targetField) !== -1;
};
