import React from 'react';
import PropTypes from 'prop-types';
import {Col, Row, Button, Card} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {change, Field, formValueSelector, reduxForm} from 'redux-form';
import {SALES_SETTINGS_FORM} from '../../constants/forms';
import PermissionWrapper from '../common/PermissionWrapper';
import ReactSelectInput from '../common/form/ReactSelectInput';
import TextInput from '../common/form/TextInput';
import MultiselectInput from '../common/form/MultiselectInput';
import NumberInput from '../common/form/NumericInput';
import SubmitSection from '../common/form/SubmitSection';
import CheckBoxInput from '../common/form/CheckBoxInput';
import LimitsPanel from './LimitsPanel';
import * as consumerTypes from '../../constants/consumerTypes';
import * as p from '../../constants/permissions';
import {
  getSalesLimitsByConsumerType,
  isResetPatientSalesLimitDisabled, isSalesLimitsAppliedAcrossLoop
} from '../../selectors/salesSettingsSelectors';
import {
  isHypurIntegrator,
  isAeropayIntegratedIntegrator,
  isAlleavesIntegrator,
  isPosabitIntegrator
} from '../../selectors/integration/thirdPartyIntegrationSelectors';
import {isLeafPaConfigPackClosedLoopFacility} from '../../selectors/facilitiesSelectors';
import {isFeatureEnabled} from '../../selectors/featureToggles';
import {postItem, postData} from '../../actions/apiActions';
import {getCurrentUser} from '../../selectors/usersSelectors';
import {validate} from './validate';
import ContentConcealer from '../common/concealers/ContentConcealer';
import {getInventoryComplianceSettings} from '../../selectors/complianceSettingsSelectors';
import AutoPrintSetting from './AutoPrintSetting';

const initialValues = {
  allowUpload: [],
  paymentOptions: []
};

class SalesSettingForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.changePhysicianInfo = this.changePhysicianInfo.bind(this);
    this.changeInactive = this.changeInactive.bind(this);
    this.changeSalesPeriod = this.changeSalesPeriod.bind(this);
    this.handlePosabitClick = this.handlePosabitClick.bind(this);

    this.state = {
      physicianInfoShow: true,
      inactiveShow: 0,
      touchedFlowerFields: false
    };
  }

  componentWillReceiveProps(nextProps){
    // Ensure initial validation errors caught for those set below 1 on flower fields
    if(nextProps.eqFields !== undefined && !this.state.touchedFlowerFields){
      nextProps.eqFields.forEach((field) => {
        nextProps.touch(`flower_${field.category_code}`);
      });
      this.setState({touchedFlowerFields: true});
    }
    if(this.state.inactiveShow === 0 && nextProps.autoExpired !== undefined) {
      this.setState({inactiveShow: (parseInt(nextProps.autoExpired) === 1)});
    }
    this.setState({disableWeighMarginError: nextProps.formSelector('order_enable_weigh_on_hand') !== '1'});
  }

  changePhysicianInfo(value) {
    const {change} = this.props;
    const yesValue = '1';
    this.setState({
      physicianInfoShow: value === yesValue
    });
    change('order_allow_sales_with_temp_paperwork', value);
  }

  changePackagingWorkflow(value) {
    const {change} = this.props;
    change('order_packaging_workflow', value);
  }

  changeInactive(value) {
    const yesValue = 1;
    this.setState({
      inactiveShow: (parseInt(value) === yesValue)
    });
  }

  changeSalesPeriod(value) {
    const {change} = this.props;
    change(`${this.props.patientType}_restrictSalesType`, value);
    if (value === 'visit') {
      change(`${this.props.patientType}_restrictSalesLimit`, 1);
    }
  }

  handlePosabitClick() {
    const {user} = this.props;
    const payload = {
      type: 'posabit_referral',
      entity_type: 'user',
      action: 'User click POSaBIT referral link.',
      entity_id: user.id
    };

    this.props.actions.postItem('/api/audit_logs',
      payload).then(() => {
        window.open('https://www.posabit.com', '_blank');
      }
    ).catch(() => {});
  }

  render() {

    const options = [
      {text: 'Yes', value: '1'},
      {text: 'No', value: '0'}
    ];
    const customerTypeOptions = [
      { text: I18n.t('customers.create.medical'),
        value: consumerTypes.MEDICAL },
      { text: I18n.t('customers.create.recreational'),
        value: consumerTypes.RECREATIONAL },
    ];

    const {handleSubmit, submitting, pristine, patientType, disableResetPatientSalesLimit, settingsMethodIsOverridable,
      inventoryComplianceSettings: {enable_reservations}, integrationState: {isBiotrack, isHemp},
      isAeroPayToggled, isAeroPayIntegratedToggled, isPosabitToggled, isAlleavesToggled, isHypurToggled, isHypurIntegratorEnabled,
      isAeropayIntegratedIntegratorEnabled, isPosabitIntegratorEnabled, isAlleavesIntegratorEnabled, change} = this.props;

    const settings = {
      actionSettings: {
        submit: {
          submitting,
          pristine,
          invalid: false, // we should allow to click button even if form is invalid - so the user can see in which fields there are a validation errors
          text: I18n.t('common.form.save')
        }
      }
    };
    const currentLimitsMethod = this.props.formSelector('order_sales_limit_method');
    const disableRegisterClosingOptions = (this.props.register_closing_is_allowed !== '1');
    const registerClosingOptionsClassName = disableRegisterClosingOptions ? 'disabled-row' : '';
    return (
      <form onSubmit={handleSubmit} className='sales-settings-form'>
        <Row>
          <Col md={6}>
            <LimitsPanel {...this.props}
              change={change}
            />
            <Card>
              <Card.Header>
                {I18n.t('retail.salesSettings.patients')}
              </Card.Header>
              <Card.Body>

                <table className='table table-striped'>
                  <tbody>

                    <tr>
                      <td colSpan={2} style={{paddingRight:'8px'}}>
                        {I18n.t('retail.salesSettings.allowPaperwork')}
                      </td>
                      <td>
                        <Field
                          name='order_allow_sales_with_temp_paperwork'
                          component={ReactSelectInput}
                          props={{
                            options: options,
                            onChange: value => this.changePhysicianInfo(value)
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} style={{paddingRight:'8px'}}>
                        {I18n.t('retail.salesSettings.requireInfo')}
                      </td>
                      <td>
                        <Field
                          name={`${patientType}_order_physician_info`}
                          component={ReactSelectInput}
                          props={{
                            options: options
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} style={{paddingRight:'8px'}}>
                        {I18n.t('retail.salesSettings.packagingWorkflow')}
                      </td>
                      <td>
                        <Field
                          name='order_packaging_workflow'
                          component={ReactSelectInput}
                          props={{
                            options: options,
                            onChange: value => this.changePackagingWorkflow(value)
                          }}
                        />
                      </td>
                    </tr>
                    {
                      isBiotrack || isHemp || disableResetPatientSalesLimit
                        ? null
                        : (<tr className={currentLimitsMethod === 'disabled' ? 'disable-contents' : ''}>
                          <td>{I18n.t('retail.salesSettings.limitsReset')}</td>
                          <td style={{width: '150px', paddingTop:'10px'}}>
                            <Field
                              name={`${patientType}_restrictSalesLimit`}
                              component={NumberInput}
                              disabled={(this.props[`${patientType}_restrictSalesType`] === 'visit') || !settingsMethodIsOverridable}
                              props={{
                                placeholder: '* How Many'
                              }}
                            />
                          </td>
                          <td>
                            <Field
                              name={`${patientType}_restrictSalesType`}
                              component={ReactSelectInput}
                              props={{
                                options: this.props.restrictSalesTimePeriods ? this.props.restrictSalesTimePeriods : [],
                                placeholder: '* Units of Time',
                                onChange: this.changeSalesPeriod,
                                disabled: !settingsMethodIsOverridable
                              }}
                            />
                          </td>
                        </tr>)
                    }
                    <PermissionWrapper permissions={[p.manage_anonymous_orders]}>
                      <tr>
                        <td colSpan={2} style={{paddingRight: '8px'}}>
                          {I18n.t('retail.salesSettings.allowAnonymousOrders')}
                        </td>
                        <td>
                          <Field
                            name='order_allow_anonymous'
                            component={ReactSelectInput}
                            props={{
                              options: [
                                {text: 'Yes', value: true},
                                {text: 'No', value: false}
                              ]
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2} style={{paddingRight: '8px'}}>
                          {I18n.t('retail.salesSettings.requireBirthdateForAnonymousOrders')}
                        </td>
                        <td>
                          <Field
                            name='order_birthdate_for_anonymous_orders'
                            component={ReactSelectInput}
                            props={{
                              options: [
                                {text: 'Yes', value: true},
                                {text: 'No', value: false}
                              ]
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2} style={{paddingRight: '8px'}}>
                          {I18n.t('retail.salesSettings.anonymousCustomerTypes')}
                        </td>
                        <td>
                          <Field
                            name='order_anonymous_customer_types'
                            component={MultiselectInput}
                            props={{
                              options: customerTypeOptions
                            }}
                          />
                        </td>
                      </tr>
                    </PermissionWrapper>
                    <tr>
                      <td colSpan={2} style={{paddingRight:'8px'}}>
                        {I18n.t('retail.salesSettings.recreationalAgeLimit')}
                      </td>
                      <td style={{width: '150px'}}>
                        <Field
                          name={'order_recreational_sales_age_limit'}
                          component={NumberInput}
                          props={{
                            placeholder: '',
                            type: 'integer',
                            fractionPartSize: 0,
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} style={{paddingRight:'8px'}}>
                        {I18n.t('retail.salesSettings.allow_expired_dl_for_rec')}
                      </td>
                      <td style={{width: '150px'}}>
                        <Field
                          name={'order_allow_expired_dl_on_rec'}
                          component={ReactSelectInput}
                          props={{
                            options: options,
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card>
              <Card.Header>
                {I18n.t('retail.salesSettings.system')}
              </Card.Header>
              <Card.Body>
                <table className='table table-striped'>
                  <tbody>
                  <tr>
                    <td style={{paddingRight: '8px'}}>
                      {I18n.t('retail.salesSettings.allowUpload')}
                    </td>
                    <td style={{width: '150px'}}>
                      <Field
                        name='allowUpload'
                        component={ReactSelectInput}
                        props={{
                          options: options,
                          valueKey: 'value',
                          textKey: 'text'
                        }}
                      />
                    </td>
                  </tr>
                    <tr>
                      <td style={{paddingRight:'8px'}}>
                        {I18n.t('retail.salesSettings.autoExpired')}
                      </td>
                      <td>
                        <Field
                          name='autoExpired'
                          component={ReactSelectInput}
                          props={{
                            options: options,
                            onChange: (value) => {
                              this.props.change('autoExpired', value);
                              this.props.touch('setBuffer');
                              this.changeInactive(value);
                            }
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{paddingRight:'8px'}}>
                        {I18n.t('retail.salesSettings.lockOnlineOrderOnConsumerImcompleteMedicalInfo')}
                        <p><small className='text-muted'>{I18n.t('retail.salesSettings.lockOnlineOrderOnConsumerImcompleteMedicalInfoDescription')}</small></p>
                      </td>
                      <td>
                        <Field
                          name='order_allow_api_sales_on_medical_patient'
                          component={ReactSelectInput}
                          props={{
                            options: options,
                            onChange: (value) => {
                              this.props.change('order_allow_api_sales_on_medical_patient',+value);
                            }
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{paddingRight:'8px'}}>
                        {I18n.t('retail.salesSettings.lockPreCheckinOnlineOrder')}
                        <p><small className='text-muted'>{I18n.t('retail.salesSettings.lockPreCheckinOnlineOrderDescription')}</small></p>
                      </td>
                      <td>
                        <Field
                          name='lock_pre_checkin_online_order'
                          component={ReactSelectInput}
                          props={{
                            options: options,
                            onChange: (value) => {
                              this.props.change('lock_pre_checkin_online_order',+value);
                            }
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{paddingRight:'8px'}}>
                        {I18n.t('retail.salesSettings.allowExpirationDateSales')}
                        <p><small className='text-muted'>{I18n.t('retail.salesSettings.allowExpirationDateSalesDescription')}</small></p>
                      </td>
                      <td>
                        <Field
                          name='order_allow_sales_on_id_expiration'
                          component={ReactSelectInput}
                          props={{
                            options: options,
                            onChange: (value) => {
                              this.props.change('order_allow_sales_on_id_expiration',+value);
                            }
                          }}
                        />
                      </td>
                    </tr>
                    {
                      !this.state.inactiveShow
                        ? null
                        : <tr>
                          <td style={{paddingRight:'8px'}}>
                            {I18n.t('retail.salesSettings.setBuffer')}
                          </td>
                          <td>
                            <Field
                              name='setBuffer'
                              component={NumberInput}
                              props={{
                                placeholder: ''
                              }}
                            />
                          </td>
                        </tr>
                    }
                    <tr>
                      <td style={{paddingRight:'8px'}}>
                        {I18n.t('retail.salesSettings.printWithoutPayment')}
                      </td>
                      <td style={{width: '150px'}}>
                        <Field
                          name='printWithoutPayment'
                          component={ReactSelectInput}
                          props={{
                            options: options,
                            valueKey: 'value',
                            textKey: 'text'
                          }}
                        />
                      </td>
                    </tr>
                    <ContentConcealer show={!!enable_reservations}>
                      <tr>
                        <td style={{paddingRight:'8px'}}>
                          {I18n.t('retail.salesSettings.enableReservationsAPI')}
                        </td>
                        <td style={{width: '150px'}}>
                          <Field
                            name='enableReservationsAPI'
                            component={ReactSelectInput}
                            props={{
                              options: options,
                              valueKey: 'value',
                              textKey: 'text'
                            }}
                          />
                        </td>
                      </tr>
                    </ContentConcealer>
                    <tr>
                      <td colSpan={2} style={{paddingRight:'8px'}}>
                        {I18n.t('retail.salesSettings.standardReceiptFooterText')}
                        <div style={{marginTop: '4px'}}>
                          <Field
                            name='standardReceiptFooterText'
                            component={TextInput}
                            props={{
                              placeholder: 'eg. Thank you for your patronage.'
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr className={'sale-setting-background-color'}>
                      <td style={{paddingRight:'8px'}}>
                        {I18n.t('retail.salesSettings.enableWeighOnHand')}
                        <p><small className='text-muted'>{I18n.t('retail.salesSettings.enableWeighOnHandDescription')}</small></p>
                      </td>
                      <td style={{width: '150px'}}>
                        <Field
                          name='order_enable_weigh_on_hand'
                          component={ReactSelectInput}
                          props={{
                            options: options,
                            valueKey: 'value',
                            textKey: 'text'
                          }}
                          onChange={(event, newValue) => {
                            this.setState({
                              disableWeighMarginError: newValue !== '1'
                            });
                          }}
                        />
                      </td>
                    </tr>
                    <tr className={'sale-setting-weight-on-hand'}>
                      <td style={{paddingLeft:'20px'}} >
                        {I18n.t('retail.salesSettings.usePackageQty')}
                        <p><small className='text-muted'>{I18n.t('retail.salesSettings.usePackageQtyDescription')}</small></p>
                        {I18n.t('retail.salesSettings.weighOnHandMarginOfError')}
                      </td>
                      <td style={{verticalAlign: 'bottom'}}>
                        <Field
                          name='order_weigh_on_hand_margin_error'
                          component={NumberInput}
                          props={{
                            fractionPartSize: 3,
                            rightAddon: <span>GR</span>,
                            disabled: this.state.disableWeighMarginError
                          }}
                        />
                      </td>
                    </tr>
                    <PermissionWrapper permissions={[p.manage_labels]}>
                    <tr>
                      <td colSpan={2}>
                        <AutoPrintSetting
                          options={options}
                          labelTags={this.props.labelTags}
                          autoprintIsEnabled={this.props.autoprintIsEnabled}
                          autoprintLabelsChecked={this.props.autoprintLabelsChecked}
                        />
                      </td>
                    </tr>
                    </PermissionWrapper>
                  </tbody>
                </table>
              </Card.Body>
            </Card>

            <Card>
              <Card.Header>
                {I18n.t('retail.salesSettings.paymentsAccepted')}
              </Card.Header>
              <Card.Body>
                <table className='table table-striped'>
                  <tbody>
                    {this.props.paymentsAvailable.map((payment, index) => {
                      if (payment.value === 'aeropay' && !isAeroPayToggled) {
                        return null;
                      }
                      if (payment.value === 'aeropayintegrated' && !isAeroPayIntegratedToggled) {
                        return null;
                      }
                      if (payment.value === 'posabit' && !isPosabitToggled) {
                        return null;
                      }
                      if (payment.value === 'alleaves' && !isAlleavesToggled) {
                        return null;
                      }
                      if (payment.value === 'hypur' && !isHypurToggled) {
                        return null;
                      }

                      if (payment.value === 'hypur') {
                        if (isHypurIntegratorEnabled) {
                          this.props.setFieldValue((`payment_${payment.value}`), '1');
                        } else {
                          this.props.setFieldValue((`payment_${payment.value}`), '');
                        }
                      }

                      if (payment.value === 'aeropayintegrated') {
                        if (isAeropayIntegratedIntegratorEnabled) {
                          this.props.setFieldValue((`payment_${payment.value}`), '1');
                        } else {
                          this.props.setFieldValue((`payment_${payment.value}`), '');
                        }
                      }

                      if (payment.value === 'alleaves') {
                        if (isAlleavesIntegratorEnabled) {
                          this.props.setFieldValue((`payment_${payment.value}`), '1');
                        } else {
                          this.props.setFieldValue((`payment_${payment.value}`), '');
                        }
                      }

                      if (payment.value === 'posabit') {
                        if (isPosabitIntegratorEnabled) {
                          this.props.setFieldValue((`payment_${payment.value}`), '1');
                        } else {
                          this.props.setFieldValue((`payment_${payment.value}`), '');
                        }
                      }

                      return (
                        <tr key={index}>
                          <td style={{width:'36px'}}>
                            <Field
                              name={`payment_${payment.value}`}
                              component={CheckBoxInput}
                              props={{
                                size: 'lg',
                                disabled: payment.setByThirdPartyIntegration,
                              }}
                            />
                          </td>
                          <td style={{verticalAlign: 'middle'}}>
                            {payment.value !== 'other' &&
                              <span>{I18n.t(payment.i18n)}</span>
                            }
                            {payment.value === 'other' &&
                              <span>
                                <Field
                                  name='payment_type_other_label'
                                  component={TextInput}
                                  props={{
                                    isRequired: false,
                                    value: this.props.formSelector('payment_type_other_label') || I18n.t('cart.payment.other'),
                                  }}
                                />
                              </span>
                            }

                            {payment.setByThirdPartyIntegration &&
                              <div className='text-muted' style={{display: 'block'}}>
                                {I18n.t(`retail.salesSettings.disable.${payment.value}`)}
                              </div>
                            }

                            {payment.value === 'posabit' &&
                              <div className='text-muted' style={{display: 'block'}}>
                                {I18n.t('registers.posabit_info_message')}
                                <Button variant='link' onClick={() => this.handlePosabitClick()}>
                                  https://posabit.com
                                </Button>
                              </div>
                            }
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Card.Body>
            </Card>
            <Card>
              <Card.Header>
                {I18n.t('retail.salesSettings.registerSettings')}
              </Card.Header>
              <Card.Body>
                <table className='table table-striped'>
                  <tbody>
                  <tr>
                    <td style={{paddingRight:'8px'}}>
                      {I18n.t('retail.salesSettings.allowRegisterClosing')}
                    </td>
                    <td style={{width: '150px'}}>
                      <Field
                        name='register_closing_is_allowed'
                        component={ReactSelectInput}
                        props={{
                          options: options,
                          valueKey: 'value',
                          textKey: 'text'
                        }}
                        onChange={(event, newValue, previousValue) => {
                          if (previousValue === '1' && newValue !== '1') {
                            this.props.change('register_closing_count_by', '');
                          }
                          if (previousValue !== '1' && newValue === '1') {
                            this.props.change('register_closing_count_by', 'employee');
                          }
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{paddingRight:'8px'}} className={registerClosingOptionsClassName} colSpan={2}>
                      <div className='flex-row'>
                        <div className='flex-row-title'>
                          {I18n.t('retail.salesSettings.registerCountBy')}
                        </div>
                        <div>
                          <label>
                            <Field
                              name='register_closing_count_by'
                              component='input'
                              type='radio'
                              value='employee'
                              props={{
                                disabled: disableRegisterClosingOptions,
                              }}
                            />
                            {I18n.t('retail.salesSettings.countByEmployee')}
                          </label>
                        </div>
                        <div>
                          <label>
                            <Field
                              name='register_closing_count_by'
                              component='input'
                              type='radio'
                              value='verification'
                              props={{
                                disabled: disableRegisterClosingOptions,
                              }}
                            />
                            {I18n.t('retail.salesSettings.countByVerification')}
                          </label>
                        </div>
                        <div>
                          <label>
                            <Field
                              name='register_closing_count_by'
                              component='input'
                              type='radio'
                              value='employee_and_verification'
                              props={{
                                disabled: disableRegisterClosingOptions,
                              }}
                            />
                            {I18n.t('retail.salesSettings.countByBoth')}
                          </label>
                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <SubmitSection settings={settings}/>
      </form>
    );
  }
}

SalesSettingForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired,
  fields: PropTypes.func,
  eqFields: PropTypes.array,
  eqUoms: PropTypes.array,
  paymentsAvailable: PropTypes.array,
  restrictSalesTimePeriods: PropTypes.array,
  valid: PropTypes.bool.isRequired,
  rulesCategories: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  complianceType: PropTypes.string,
  setComplianceType: PropTypes.func,
  showRulesBasedCompliance: PropTypes.bool,
  medical_restrictSalesType: PropTypes.string,
  recreational_restrictSalesTyp: PropTypes.string,
  patientType: PropTypes.string,
  disableResetPatientSalesLimit: PropTypes.bool,
  inventoryComplianceSettings: PropTypes.object,
  labelTags: PropTypes.array,
  autoprintIsEnabled: PropTypes.string,
  autoprintLabelsChecked: PropTypes.bool,
  isHempFacility: PropTypes.bool.isRequired,
  settingsMethodIsOverridable: PropTypes.bool.isRequired,
  isAeroPayToggled: PropTypes.bool.isRequired,
  isPosabitIntegratorEnabled: PropTypes.bool.isRequired,
  isPosabitToggled: PropTypes.bool.isRequired,
  isAlleavesIntegratorEnabled: PropTypes.bool.isRequired,
  isHypurIntegratorEnabled: PropTypes.bool.isRequired,
  isHypurToggled: PropTypes.bool.isRequired,
};

const form = SALES_SETTINGS_FORM;
const selector = formValueSelector(form);

const SalesSettingsReduxForm = reduxForm({
  form,
  initialValues,
  validate,
  enableReinitialize: true
})(SalesSettingForm);


function mapStateToProps(state) {
  const medical_restrictSalesType = selector(state, 'medical_restrictSalesType');
  const recreational_restrictSalesType = selector(state, 'recreational_restrictSalesType');
  return {
    formSelector: (field) => {
      if(state.form === undefined) return undefined;
      if(state.form[form] === undefined) return undefined;
      if(state.form[form].values === undefined) return undefined;
      return state.form[form].values[field];
    },
    setFieldValue: (field, value) => {
      if(state.form === undefined) return undefined;
      if(state.form[form] === undefined) return undefined;
      if(state.form[form].values === undefined) return undefined;
      return state.form[form].values[field] = value;
    },
    patientType: selector(state, 'patient_type'),
    register_closing_is_allowed: selector(state, 'register_closing_is_allowed'),
    autoprintIsEnabled: selector(state, 'order_auto_printing_is_enabled'),
    autoprintLabelsChecked: selector(state, 'order_auto_printing_labels'),
    medical_restrictSalesType,
    recreational_restrictSalesType,
    disableResetPatientSalesLimit: isResetPatientSalesLimitDisabled(state),
    inventoryComplianceSettings: getInventoryComplianceSettings(state) || {},
    isAeroPayToggled: isFeatureEnabled(state)('feature_aeropay'),
    isAeroPayIntegratedToggled: isFeatureEnabled(state)('feature_aeropay_integration'),
    isPosabitToggled: isFeatureEnabled(state)('feature_posabit_payment_integration'),
    isAlleavesToggled: isFeatureEnabled(state)('feature_alleaves_payment_integration'),
    isHypurToggled: isFeatureEnabled(state)('feature_hypur'),
    isLeafPaMmuToggled: isFeatureEnabled(state)('feature_leaf_pa_mmu_limits'),
    isLeafPaConfigPackClosedLoopFacility: isLeafPaConfigPackClosedLoopFacility(state),
    isSalesLimitsAppliedAcrossLoop: isSalesLimitsAppliedAcrossLoop(state),
    salesLimitsByConsumerType: getSalesLimitsByConsumerType(state),
    user: getCurrentUser(state),
    actions: PropTypes.shape({
      postItem: PropTypes.func.isRequired,
      postData: PropTypes.func.isRequired,
    }).isRequired,
    isHypurIntegratorEnabled: isHypurIntegrator(state),
    isAeropayIntegratedIntegratorEnabled: isAeropayIntegratedIntegrator(state),
    isPosabitIntegratorEnabled: isPosabitIntegrator(state),
    isAlleavesIntegratorEnabled: isAlleavesIntegrator(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = Object.assign({},
    {postItem, postData, change});
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesSettingsReduxForm);
