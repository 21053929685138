import {createSelector} from 'reselect';
import get from 'lodash.get';
import forEach from 'lodash.foreach';
import {dispensary, grow} from '../constants/facilityTypes';
import {getIntegrationState} from './integration/integrationSelectors';
import {getActiveFacility, getActiveFacilityModules, getFacilityHasModule} from './facilitiesSelectors';
import {OR, AND, NOT} from '../util/predicates';
import * as p from '../constants/permissions';
import {getCurrentUserPermissions} from '../selectors/usersSelectors';
import {getComplianceSettings} from './complianceSettingsSelectors';
import {getFeatureTogglesByName, isFeatureEnabled} from './featureToggles';

const getModules = (state) => state.modules || [];

const getState = (state) => state;

export const getModeAndFacilityType = createSelector([getIntegrationState, getActiveFacility], (mode, facility) => {
  return {
    mode,
    facility_type: facility.type
  };
});

export const facilityCanPackage = ({mode, facility_type}) =>
  !(mode.isWaLeaf && [dispensary, grow].indexOf(facility_type) > -1) && !(mode.isPaLeaf && [dispensary].indexOf(facility_type) > -1);

export const getFacilityModules = createSelector([getActiveFacilityModules, getModules], (facilityModules, modules) => {
  return facilityModules.map(fm => get(modules.find(m => m.id === fm.module_id), 'module_key', null));
});

export const areNeedlesInHaystack = (authData) => {
  const {haystack, needles, join} = authData;
  return needles.reduce((acc, value) => {
    if(!acc && join === 'or'){
      return haystack.includes(value);
    }else if(acc && join === 'and'){
      return haystack.includes(value);
    }
    return acc;
  }, false);
};

export const areNeedlesNotInHaystack = (authData) => {
  return !areNeedlesInHaystack(authData);
};

export const facilityHasModules = (moduleList) => createSelector([getFacilityModules], (modules) => {
  return {
    haystack: modules,
    needles: moduleList,
    join: 'or'
  };
});

const evaluate = (entitiesList, enabledList, predicate) => {
  const find = m => enabledList.indexOf(m) > -1;

  if (entitiesList === undefined) {
    return true;
  }

  if (predicate === OR) {
    return entitiesList.some(find);
  } else if (predicate === AND) {
    return entitiesList.every(find);
  } else if (predicate === NOT) {
    return !entitiesList.some(find);
  }
};

export const evaluateRouteAccessibility = (accessibility) => createSelector(
  [getFacilityModules, getActiveFacility, getIntegrationState, getFeatureTogglesByName, getState], (enabledModules, currentFacility, integrationState, featureToggles, state) => {
    const a = accessibility;

    if (accessibility === undefined) {
      return true;
    }

    const currentIntegrator = [];
    forEach(integrationState, (isEnabled, integrator) => {
      if (isEnabled) {
        currentIntegrator.push(integrator);
      }
    });

    if (accessibility.evaluate && typeof accessibility.evaluate === 'function') {
      return accessibility.evaluate(enabledModules, currentFacility, integrationState, featureToggles, state);
    }

    const selectors = get(accessibility, 'selectors', false);
    if (Array.isArray(selectors)){
      // Returns true if every selector in array returns true
      return selectors.reduce((acc, selector) => {
        if(!acc) return acc;
        return selector(state);
      }, true);
    }


    return evaluate(a.modules, enabledModules, a.modulesPredicate) &&
      evaluate(a.facilities, [currentFacility.type], a.facilitiesPredicate) &&
      evaluate(a.integrators, currentIntegrator, a.integratorsPredicate);
  }
);

export const isCanadianDispensary = (facility) => {
  return facility.type === dispensary && facility.country_code === 'CA';
};

export const labResultsHasCreatePermission = createSelector(
  [getFacilityHasModule, getComplianceSettings, getCurrentUserPermissions, getIntegrationState],
  (facilityHasModule, complianceSettings, permissions, integrationState) => {
    const {isPaLeaf} = integrationState;

    if (isPaLeaf && !facilityHasModule('TESTING_LAB')) {
      return false;
    }
    const labEditRequireManageLabTesting = get(complianceSettings, 'inv_lab_edit_requires_manage_lab_testing.value', false);
    if (labEditRequireManageLabTesting && !facilityHasModule('TESTING_LAB')) {
      return false;
    }
    const requiredPermission = (labEditRequireManageLabTesting) ? p.manage_lab_testing : p.manage_testing;
    return Boolean(get(permissions, requiredPermission, false));
  });

export const labResultsHasEditPermission = createSelector(
  [getFacilityHasModule, getComplianceSettings, getCurrentUserPermissions],
  (facilityHasModule, complianceSettings, permissions) => {
    const labEditRequireManageLabTesting = get(complianceSettings, 'inv_lab_edit_requires_manage_lab_testing.value', false);
    if (labEditRequireManageLabTesting && !facilityHasModule('TESTING_LAB')) {
      return false;
    }
    const requiredPermission = (labEditRequireManageLabTesting) ? p.manage_lab_testing : p.manage_testing;
    return Boolean(get(permissions, requiredPermission, false));
  });

export const labResultsEditable = (hasPermission) => {
  return hasPermission;
};

export const inventorySyncPermission = createSelector(
  [getIntegrationState, isFeatureEnabled, getCurrentUserPermissions],
  (integrationState, isFeatureEnabled, permissions) => {
    const { isBiotrack, isMetrc } = integrationState;
    return isFeatureEnabled('feature_inventory_sync') && (isBiotrack || isMetrc) && get(permissions, p.manage_inventory_sync, false);
  });

export const isFeatureEnabledProductMasters = createSelector(
  [isFeatureEnabled], (isFeatureEnabled) => {
    return isFeatureEnabled('feature_new_product_master');
  });
