import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import get from 'lodash.get';
import {bindActionCreators} from 'redux';
import {batchActions} from 'redux-batched-actions';
import {goBack, push} from 'react-router-redux';
import {I18n} from 'react-redux-i18n';
import debounce from 'lodash.debounce';
import {change, formValueSelector, SubmissionError} from 'redux-form';
import moment from 'moment';
import * as itemNames from '../../../../constants/itemNames';
import * as dataNames from '../../../../constants/dataNames';
import {
  ensureGetUnpaginatedData,
  getData,
  getItem,
  getUnpaginatedData,
  postData,
  postItem,
  putItem
} from '../../../../actions/apiActions';
import {addSelectedData} from '../../../../actions/selectedDataActions';
import {setData, unsetData} from '../../../../actions/dataActions';
import {setItem, unsetItem} from '../../../../actions/itemActions';
import {fetchMetrcTrackingIdsForSelectInput} from '../../../../actions/integrationActions';
import {getPayLoad} from '../../../../selectors/fillPurchaseOrderSelectors';
import ModalWrapper from '../../../common/ModalWrapper';
import FormWrapper from '../../../common/form/FormWrapper';
import LabelPrinter from '../../../print-services/labels/LabelPrinter'; //eslint-disable-line
import InProgressOverlay from '../../../common/InProgressOverlay';
import * as p from '../../../../constants/permissions';
import {validateCurrentOnHandWeight} from '../../../../actions/complianceSettingsActions';
import FillPurchaseOrderForm from './FillPurchaseOrderForm';
import * as messageTypes from '../../../../constants/messageTypes';
import {addMessage} from '../../../../actions/systemActions';
import {INVENTORY_RECEIPT_FORM} from '../../../../constants/forms';
import {getAllPurchasedItemMastersByPartner} from '../../../../actions/purchaseOrderActions';
import {fetchMetrcTransferPackages} from '../../../../actions/integrations/metrc';
import {handleCanPrintLabels, handleInventoryReceipt, mapStateToPropsHelper} from './helpers';

const formName = INVENTORY_RECEIPT_FORM;
export class ReceiveTransferPage extends React.PureComponent {

  constructor(props, context) {

    super(props, context);
    this.state = {
      formValues: {},
      locked: false,
      saved: false,
      canPrintLabels: false,
      inventoryReceipt: false,
      showLoadingMessage: true,
      showLoader: true,
      showConfirmationModal: false,
      pendingValidation: false
    };
    this.printLabel = this.printLabel.bind(this);
    this.hidePrinter = this.hidePrinter.bind(this);
    this.isInEditMode = this.isInEditMode.bind(this);
    this.redirect = this.redirect.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handlePrintLabels = this.handlePrintLabels.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.createDriver = this.createDriver.bind(this);
    this.createPartner = this.createPartner.bind(this);
    this.resetPackageCodeKey = debounce(this.resetPackageCodeKey.bind(this), 400);
    this.onScannedTrackingId = this.onScannedTrackingId.bind(this);
    this.onShowModal = this.onShowModal.bind(this);
    this.onHide = this.onHide.bind(this);
    this.canReceiveTransfer = this.canReceiveTransfer.bind(this);
    this.getItemMasters = this.getItemMasters.bind(this);
    this.getDetailedPurchaseOrder = this.getDetailedPurchaseOrder.bind(this);
    this.getItemMastersChildItems = this.getItemMastersChildItems.bind(this);
    this.afterPurchaseOrderLoaded = this.afterPurchaseOrderLoaded.bind(this);
    this.validateMetrcPackageStatuses = this.validateMetrcPackageStatuses.bind(this);
  }

  componentWillMount() {
    this.props.actions.batchActions([
      unsetData(dataNames.itemMasters),
      unsetData(dataNames.childItemMasters),
    ]);

    // Queue up all our calls we just fetch and forget
    const asyncs = [
      this.props.actions.getItem('/api/supply/settings', itemNames.supplySettings),
      this.props.actions.ensureGetUnpaginatedData('/api/location_hierarchy', dataNames.locations, undefined),
      this.props.actions.ensureGetUnpaginatedData('/api/drivers/details', dataNames.drivers, null, {active: 1}),
      this.props.actions.ensureGetUnpaginatedData('/api/vehicles', dataNames.vehicles),
      this.props.actions.getUnpaginatedData('/api/prepack_weights/facility', dataNames.prepackWeights),
      this.props.actions.ensureGetUnpaginatedData('/api/uoms', dataNames.uoms),
      this.props.actions.ensureGetUnpaginatedData('/api/pricing_groups', dataNames.pricingGroups, null, {detailed: 1}),
      this.props.actions.ensureGetUnpaginatedData('/api/pricing_weights', dataNames.pricingWeights),
      this.props.actions.getItem('/api/facility_groups_sharings/facility_status', itemNames.facilitySharingStatus),
      this.props.actions.getItem('/api/compliance_settings', itemNames.complianceSettings),
      this.props.actions.ensureGetUnpaginatedData('/api/packages/purposes', dataNames.purposes),
    ];

    // Queue up our calls with some kind of down stream work
    const syncs = [
      this.props.actions.getItem('/api/integration-settings', itemNames.integrationSettings, {failed: 'stateIntegratorSettings.get.failed'}),
      this.props.actions.ensureGetUnpaginatedData('/api/partner_facilities', dataNames.partnerFacilities),
    ];

    if (this.isInEditMode()) {
      syncs.push(this.props.actions.getItem(`/api/receipts/${this.props.params.id}`, itemNames.inventoryReceipt, {}, {detailed: true}));
    }

    // Get and handle data.
    Promise.all(asyncs);
    Promise.all(syncs)
      .then((result) => {
        const [integrationSettings, partnerFacilities, inventoryReceipt] = result; //eslint-disable-line
        handleInventoryReceipt(this, inventoryReceipt);
        if (this.props.integrationState.isMetrc) {
          this.props.actions.fetchMetrcTrackingIdsForSelectInput();
        }
      });

    // Housekeeping
    if (!this.isInEditMode()){
      if (this.props.purchaseOrderId) {
        this.setState({canPrintLabels: this.props.initialValues.status === 'completed'});
        this.getDetailedPurchaseOrder(this.props.purchaseOrderId).then(this.getItemMasters);
      } else {
        this.setState({showLoadingMessage: false, showLoader: false});
      }
    }
  }


  componentWillReceiveProps(nextProps){
    handleCanPrintLabels(this, nextProps);
  }


  componentWillUnmount() {
    this.props.actions.unsetItem(itemNames.inventoryReceipt);
    this.props.actions.unsetItem(itemNames.supplyChainMapping);
    this.props.actions.unsetItem(itemNames.purchaseOrder);
    this.props.actions.unsetData(dataNames.pricingWeights);
  }

  componentDidUpdate(prevProps) {
    if(!prevProps.isReturn && this.props.isReturn){
      this.props.actions.change(formName,'date_received',moment());
    }
  }

  canReceiveTransfer() {
    const {isInitialMode, purchaseOrder, forceMedicatedMapping, supplyChainMapping} = this.props;
    if (!purchaseOrder || !purchaseOrder.id) {
      this.props.actions.addMessage(messageTypes.error, 'purchaseOrders.getListing.failed');
      return false;
    } else if (purchaseOrder.contains_medicated && (!supplyChainMapping.external_id && !this.props.integrationState.isGlobalIdEnabled) && forceMedicatedMapping && !purchaseOrder.is_initial) {
      this.props.actions.addMessage(messageTypes.error, 'purchaseOrders.leafIRMedicatedMessage');
      return false;
    } else if(purchaseOrder.is_initial && !isInitialMode) {
      this.props.actions.addMessage(messageTypes.error, 'purchaseOrders.leafPaInitialInventoryCreationWarning');
      return false;
    } else if (!this.state.locked && purchaseOrder.received && purchaseOrder.transfer_record.status !== 'cancelled') {
      this.props.actions.addMessage(messageTypes.error, ['purchaseOrders.validateIRExists', {ir_id: purchaseOrder.transfer_record.receipt_number}]);
      return false;
    } else if (!this.props.actions.validateCurrentOnHandWeight()) {
      this.props.actions.addMessage(messageTypes.error, 'receipts.create.fail');
      return false;
    }
    return true;
  }

  //@TODO: Undo all this - not sure who was high on crack when they did this - probably me.
  fetchPurchaseOrder(id) {
    const { integrationState, actions } = this.props;

    if (integrationState.isMetrc) {
      const loadChildItemMasters = (data) => {
        return Promise.resolve(actions.setData(data, dataNames.itemMasters));
      };

      const loadMetrcData = (purchaseOrder) => {
        // const partnerId = purchaseOrder.vendor_facility_id;
        actions.getAllPurchasedItemMastersByPartner()
          .then(loadChildItemMasters)
          .then(this.getItemMastersChildItems)
          .then(() => actions.fetchMetrcTransferPackages(id));
      };

      return this
        .getDetailedPurchaseOrder(id)
        .then(loadMetrcData);
    } else {
      return this
        .getDetailedPurchaseOrder(id)
        .then(this.getItemMasters);
    }
  }

  getItemMasters() {
    const ids = this.props.orderItemIds;

    return this.props.actions
      .postData('/api/item_masters/multiple', {ids: ids}, dataNames.itemMasters, undefined, {detailed: 1})
      .then(this.getItemMastersChildItems);
  }

  getItemMastersChildItems() {
    const ids = this.props.itemMasterIds;
    if(!ids.length) return false;

    return this.props.actions
      .getUnpaginatedData('/api/item_masters/children/items', dataNames.childItemMasters, undefined, {ids});
  }

  afterPurchaseOrderLoaded(data) {
    this.setState({
      showLoader: false,
      showLoadingMessage: false
    });

    if (!this.props.editMode && !this.canReceiveTransfer()) {
      this.props.actions.unsetItem(itemNames.purchaseOrder);
      return Promise.reject();
    }

    return Promise.resolve(data);
  }

  getDetailedPurchaseOrder(id){
    const messages = {
      failed: 'cultivation.transfers.get.purchaseOrdersFail'
    };

    return this.props.actions
      .getItem(`/api/purchase_orders/${id}`, itemNames.purchaseOrder, messages, {detailed: 1, include_linked_transfer: 1})
      .then(this.afterPurchaseOrderLoaded)
      .then((purchaseOrder) => {
        const vendorFacilityId = get(purchaseOrder, 'vendor_facility_id', '');
        if (vendorFacilityId && this.props.isLeafPaConfigPackClosedLoopFacility) {
          // Retrieve MJP facility record for partner facility (Needed to determine if this PO is a return)
          this.props.actions.getItem(`/api/partner_facilities/facility_details/${vendorFacilityId}`, itemNames.partnerFacilityDetails);
        }
      });
  }

  saveStateText(){
    return {
      saveAndPrint: this.state.saved ? 'Print Labels' : I18n.t('common.saveAndPrintLabels'),
      submit:this.state.saved ? 'Already saved' : I18n.t('common.form.save'),
      state:this.state.saved
    };
  }

  printLabel(receiptData) {
    return this.onShowModal(receiptData);
  }

  showPrinter(event){
    this.setState({showPrinter:true});
  }

  hidePrinter(event) {
    this.setState({showPrinter: false});
  }

  resetPackageCodeKey(inventoryName, itemMasterId, lotNumber, packageId, parentFieldName){
    if(packageId === undefined || packageId === null) return false;

    if(parentFieldName === undefined) parentFieldName = false;

    const errorKey = `${inventoryName}.packageCodeError`;

    const haveLotNumber = () => {
      if(lotNumber === undefined || lotNumber === null) return false;
      return (lotNumber.trim() !== '');
    };

    const receivedLotNumber = (pkg) => {
      return (pkg.lot !== null && pkg.lot !== undefined);
    };

    const setPackageCodeKey = (itemMasterId, lotNumber, packageId) => {
      const packageKey = `${itemMasterId}__${lotNumber}__${packageId}`;
      this.props.actions.change(formName, `${inventoryName}.package_code_key`, packageKey);
    };

    const haveServerBasedErrors = (pkg) => {
      if(this.props.purchaseOrder.partnerFacility.partner_id !== get(pkg, 'lot.producer_id')) {
        return this.props.actions.change(formName, errorKey, 'facility');
      }

      if(itemMasterId !== pkg.item_master_id) {
        return this.props.actions.change(formName, errorKey, 'itemMaster');
      }

      if(haveLotNumber()) {
        if (get(pkg, 'lot.lot_number') !== lotNumber) {
          return this.props.actions.change(formName, errorKey, 'lotNumber');
        }
      }

      this.props.actions.change(formName, errorKey, false);
      return false;
    };

    this.setState({pendingValidation: true});
    this.props.actions.getItem('/api/packages/by_code', null, {}, {detailed: true, package_codes: [packageId]}, (data) => {
      this.setState({pendingValidation: false});
      const pkg = (data.length > 0) ? data[0] : false;

      if(!pkg){
        this.props.actions.change(formName, errorKey, false);
        return (haveLotNumber()) ? setPackageCodeKey(itemMasterId, lotNumber, packageId) : false;
      }

      if(haveServerBasedErrors(pkg)) return false;

      setPackageCodeKey(itemMasterId, (receivedLotNumber(pkg) ? pkg.lot.lot_number : lotNumber), packageId);

      if(!haveLotNumber() && receivedLotNumber(pkg)){
        this.props.actions.change(formName, `${(parentFieldName ? parentFieldName : inventoryName)}.lot_number`, pkg.lot.lot_number);
      }
    });
  }

  onCancel() {
    const {params: {id}} = this.props;
    this.props.actions.putItem(`/api/receipts/${id}`,
      {status: 'cancelled'},
      itemNames.receipt,
      {failed: 'receipts.modify.failed', success: 'receipts.modify.success'},
      null,
      () => {
        this.props.actions.push('/inventory-receipts');
      }
    );
  }

  onSubmit (formValues) {
    const { integrationState, purchaseOrder } = this.props;

    if ( integrationState.isMetrc ) {
      this.validateMetrcPackageStatuses(formValues);
    }

    // Add connect facility code to formValues. Used for Supply Chain Mapping backend validation
    formValues.connect_facility_code = get(purchaseOrder, 'partnerFacility.connect_facility_code', '');
    //set the incoming transfer id in order to pass it to the backend.
    formValues.incoming_transfer_id = this.props.supplyChainMapping.incoming_transfer_id;

    this.handleSubmit(formValues);
  }

  validateMetrcPackageStatuses(formValues) {

    const INVENTORY_SHIPPED_OR_RETURNED_STATUS = 'shipped';
    const INVENTORY_RECEIPT_COMPLETE_STATUS = 'completed';
    const { integrationState, actions } = this.props;

    if (integrationState.isMetrc && formValues.status === INVENTORY_RECEIPT_COMPLETE_STATUS) {
      const message = I18n.t('metrc.form.inventoryReceiptPackagesError');
      formValues.lines.some((line) => {
        if (get(line, 'metrc_status') === INVENTORY_SHIPPED_OR_RETURNED_STATUS) {
          actions.addMessage(messageTypes.error, message, true);
          throw new SubmissionError({ _error: message });
        }
      });
    }
  }

  handleSubmit(formValues) {
    const {supplyChainMapping, purchaseOrderNumber, integrationState: {isBiotrack}} = this.props;

    if(this.state.locked){
      this.printLabel(this.state.inventoryReceipt);
      return true;
    }

    if (!this.state.saved) { this.setState({showLoader: true}); }

    const getLineTypeAndUom = (line) => {
      const itemMaster = this.props.itemMasters.find((itemMaster) => itemMaster.id === line.item_master_id);
      let line_type = 'bulk';
      let uom = 'ML';
      if(itemMaster) {
        if(itemMaster.inventory_attributes && itemMaster.inventory_attributes.is_prepack && !itemMaster.item_master_parent_id){
          line_type = 'prepack';
          uom = 'GR';
        } else if(itemMaster.uom_type === 'weight'){
          line_type = 'bulk';
          uom = 'GR';
        }else if (itemMaster.uom_type === 'discrete') {
          line_type = 'unit';
          uom = 'EA';
        }
      }
      return {line_type: line_type, uom: uom, itemMaster};
    };

    const items = formValues.lines.map(line => {
      const {uom} = getLineTypeAndUom(line); // originally included all return values
      let orderedQty = line.ordered_qty;
      if (isBiotrack) {
        orderedQty = line.inventory.reduce((qty, lineInventory) => {
          return qty + parseFloat(lineInventory.qty);
        }, 0.00);
      }
      return {
        uom: uom,
        qty: orderedQty,
        item_master_id: line.item_master_id,
        id: line.id
      };
    });

    const toRoute = get(formValues, 'status', 'open') === 'open'
      ? '/inventory-receipts'
      : '/inventory-receipts/inactive';

    const payload = getPayLoad(formValues,
      this.props.lineItemPrices,
      this.props.itemMasters,
      this.props.itemMasterChildren,
      this.props.timezone,
      this.props.initialValues,
      this.props.supplyChainMapping,
      this.props.isReturn,
      this.props.purchaseOrder,
      this.props.integrationState);

    const promises = [
      this.props.actions.postItem(
        '/api/compliance_settings/on_hand_weight/validate',
        {items},
        'none',
        {
          failed: (error) => error && error.response && error.response.data && error.response.data.errors && error.response.data.errors.message
        },
        {}
      )
    ];

    return Promise.all(promises)
      .then(() => {
        if (!this.state.saved) {
          if (!this.isInEditMode()) {

            // If received via platform Supply Chain Mapping - update current facility's PO with mapped Partner's SO
            if (get(supplyChainMapping, 'loaded_from_supply_chain_mapping', false) && get(supplyChainMapping, 'platform_supply_chain_map', false)) {
              payload.supply_chain_mapping_data = {
                vendor_invoice_number : get(supplyChainMapping, 'platform_incoming_transfer_details.sales_order.sales_order_number', '')
              };
            }

            // create the receipt
            this.props.actions.postItem('/api/receipts',
              payload,
              itemNames.receipt,
              {success: 'receipts.create.success', failed: 'receipts.create.fail'},
              {},

              (data) => {
                if (supplyChainMapping && supplyChainMapping.external_id && supplyChainMapping.receipt_id) {
                  const mapping = {
                    external_id: supplyChainMapping.external_id,
                    receipt_id: data.id
                  };
                  this.props.actions.postItem('/api/integrated_transfers/map', mapping);
                }

                // If we are coming from Platform Supply Mapping, we have to link this PO with the mapped incoming Transfer
                // which might be linked to as SO from a totally differnet partner than the one selected in the original PO
                else if (get(supplyChainMapping, 'loaded_from_supply_chain_mapping', false) && get(supplyChainMapping, 'platform_supply_chain_map', false)) {

                  // Update a Sales order given a incoming transfer id
                  this.props.actions.postItem(`/api/sales_orders/link_po`, {
                    partner_purchase_order_number       : purchaseOrderNumber,
                    incoming_transfer_id                : get(supplyChainMapping, 'incoming_transfer_id')
                  });

                }

                // When printing you don't leave the page so lock the form.
                if(data.status === 'completed') {
                  // why inventoryReceipt in state? Because I'm not really sure putting it in redux won't break something
                  // and don't have hours to test it properly.  This solves the need for labels...
                  this.setState({locked: true, inventoryReceipt: data});
                }
                this.state.print
                  ? this.printLabel(data)
                  : this.props.actions.push(toRoute);
                this.setState({showLoader: false});
              })
              .catch(() => this.setState({showLoader: false}));
          } else {
            this.props.actions.putItem(`/api/receipts/${this.props.params.id}`,
              payload,
              itemNames.receipt,
              {success: 'receipts.create.success', failed: 'receipts.create.fail'},
              {},
              (data) => { this.state.print ? this.printLabel(data) : this.props.actions.push(toRoute); }
            ).then(() => this.setState({showLoader: false})).catch(() => this.setState({showLoader: false}));
          }
        } else { this.showPrinter(); }

      }
    ).catch(() => {
      this.setState({showLoader: false});
    });

  }

  redirect () {
    if(this.props.location.query.po !== undefined) return this.props.actions.push('/purchase-orders/active');
    this.props.actions.goBack();
  }

  isInEditMode(){
    return (this.props.route.path.indexOf('create') === -1);
  }

  handlePrintLabels() {
    this.setState({print:true});
  }

  createDriver(event) { }

  createPartner(event) { }

  onShowModal(receiptData = false){
    this.setState({showModal: true, payload: {id: receiptData ? receiptData.id : this.state.inventoryReceipt.id}});
  }

  onHide(){
    this.setState({showModal: false});
  }

  /***
   * When tags are scanned into the tracking id combo the return key (embedded) forces the first list item to be selected
   * We trap the specific case where we should hold on to the value and manually change it in redux
   * Not optimal - should probably swap out the component since can't configure this on enter behavior
   * but is used fairly widely and this prevents app wide change
   * @param newValue
   * @param previousValue
   * @param inventoryName - string used to change the redux form field
   * @returns {boolean}
   */
  onScannedTrackingId(newValue, previousValue, inventoryName){
    const {trackingIds} = this.props;
    if(!previousValue || previousValue.length !== 24) return false;
    const previousValueIsInTrackingIds = trackingIds.find((id) => id.tag === previousValue);
    const newValueIsInTrackingIds = trackingIds.find((id) => id.tag === newValue);
    if(!previousValueIsInTrackingIds && newValueIsInTrackingIds){
      setTimeout(() => {
        this.props.actions.change(formName, `${inventoryName}.state_integration_tracking_id`, previousValue);
      }, 10);
    }
    return true;
  }

  render() {
    const {showLoader, showLoadingMessage} = this.state;

    const {getValue, storageLocations, sharedLocations, initialValues, itemMasterChildren, drivers, vehicles,
      isApplyStorageLocation, itemMasters, purchaseOrder, lineItemPrices, internationalNumberFormat,
      internationalDateFormat, lineItemQuantities, summaryTotals, integrationState, isMetrcTransfersEnabled, timezone,
      trackingIds, uoms, supplyChainMapping, hasPrintPermission, isReturn, inventoryTypes, biotrackSettings,
      displaySourceFields, isLab, formValues} = this.props;

    const existingNotes = this.state.inventoryReceipt ? this.state.inventoryReceipt.notes : [];

    return (
    <div>
      <FormWrapper title={I18n.t('cultivation.transfers.receiveTransfer')} goBack={this.redirect} titleDescription={this.props.purchaseOrder.transfer_record ? this.props.purchaseOrder.transfer_record.receipt_number : ''}>
        <LabelPrinter
          showModal={this.state.showModal}
          onHide={this.onHide}
          labelTag='supply_inventory_receipt_all'
          payload={this.state.payload}
        />

        <InProgressOverlay isActive={showLoader} message={I18n.t(`cultivation.transfers.${showLoadingMessage ? 'loading' : 'saving'}`)} />
        <FillPurchaseOrderForm
          className='invoice-receipt-for-po'
          form={formName}
          getValue={getValue}
          formValues={formValues}
          storageLocations={storageLocations}
          sharedLocations={sharedLocations}
          initialValues={initialValues}
          itemMasterChildren={itemMasterChildren}
          drivers={drivers}
          vehicles={vehicles}
          saveStateText={this.saveStateText()}
          createDriver={this.createDriver}
          onPrintLabels={this.handlePrintLabels}
          isApplyStorageLocation={isApplyStorageLocation}
          itemMasters={itemMasters}
          purchaseOrder={purchaseOrder}
          lineItemPrices={lineItemPrices}
          lineItemQuantities={lineItemQuantities}
          summaryTotals={summaryTotals}
          locked={this.state.locked}
          onSubmit={this.onSubmit}
          onCancel={this.onCancel}
          canPrintLabels={this.state.canPrintLabels}
          resetPackageCodeKey={this.resetPackageCodeKey}
          integrationState={integrationState}
          trackingIds={trackingIds}
          uoms={uoms}
          timezone={timezone}
          receiptStatus={initialValues.status}
          isInEditMode={this.isInEditMode()}
          supplyChainMapping={supplyChainMapping}
          hasPrintPermission={hasPrintPermission}
          onShowModal={this.onShowModal}
          onScannedTrackingId={this.onScannedTrackingId}
          isReturn={isReturn}
          pendingValidation={this.state.pendingValidation}
          inventoryTypes={inventoryTypes}
          biotrackSettings={biotrackSettings}
          isMetrcTransfersEnabled={isMetrcTransfersEnabled}
          existingNotes={existingNotes}
          displaySourceFields={displaySourceFields}
          internationalNumberFormat={internationalNumberFormat}
          internationalDateFormat={internationalDateFormat}
          isLab={isLab}
         />
      </FormWrapper>
      <ModalWrapper
        dialogClassName='modal-sm'
        onHide={() => this.setState({showConfirmationModal: false})}
        okayButton={{
          show: true,
          text: I18n.t('general.yes'),
          variant: 'danger',
          onClick: () => {
            this.setState({showConfirmationModal: false});
            this.handleSubmit(this.state.formValues);
          }
        }}
        cancelButton={{
          show: true,
          text: I18n.t('general.no'),
          variant: 'default',
          onClick: () => this.setState({showConfirmationModal: false})
        }}
        showModal={this.state.showConfirmationModal}
        title={I18n.t('receipts.confirmationModal.title')}
        version={2}
      >
        <p>{I18n.t('receipts.confirmationModal.body')}</p>
      </ModalWrapper>
    </div>
    );
  }
}

ReceiveTransferPage.propTypes = {
  route: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  selectedPurchaseOrders: PropTypes.array.isRequired,
  itemMasterIds: PropTypes.array.isRequired,
  orderItemIds: PropTypes.array.isRequired,
  itemMasterChildren: PropTypes.object.isRequired,
  storageLocations: PropTypes.array.isRequired,
  sharedLocations: PropTypes.array.isRequired,
  drivers: PropTypes.array.isRequired,
  vehicles: PropTypes.array.isRequired,
  itemMasters: PropTypes.array.isRequired,
  getValue: PropTypes.func.isRequired,
  actions: PropTypes.object.isRequired,
  isApplyStorageLocation: PropTypes.bool,
  purchaseOrder: PropTypes.object.isRequired,
  lineItemPrices: PropTypes.array.isRequired,
  lineItemQuantities: PropTypes.array.isRequired,
  summaryTotals: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  integrationState: PropTypes.object.isRequired,
  trackingIds: PropTypes.array.isRequired,
  supplyChainMapping: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
  uoms: PropTypes.array.isRequired,
  pricingGroups: PropTypes.array.isRequired,
  pricingWeights: PropTypes.array.isRequired,
  hasPrintPermission: PropTypes.bool.isRequired,
  isReturn: PropTypes.bool.isRequired,
  inventoryTypes: PropTypes.array.isRequired,
  purchaseOrderId: PropTypes.number,
  isInitialMode: PropTypes.bool.isRequired,
  forceMedicatedMapping: PropTypes.bool.isRequired,
  isMetrcTransfersEnabled: PropTypes.bool,
  purchaseOrderNumber: PropTypes.string,
  displaySourceFields: PropTypes.bool,
  biotrackSettings: PropTypes.object,
  internationalNumberFormat: PropTypes.string.isRequired,
  internationalDateFormat: PropTypes.string.isRequired,
  isLab: PropTypes.bool,
};

const selector = formValueSelector(formName);
function mapStateToProps(state, ownProps) {
  return mapStateToPropsHelper(state, ownProps, selector);
}

function mapDispatchToProps (dispatch) {
  const actions = {goBack, push, getUnpaginatedData, ensureGetUnpaginatedData, getItem, getData, setData, setItem, postData, postItem, putItem, unsetData,
    unsetItem, addSelectedData, fetchMetrcTrackingIdsForSelectInput, change, batchActions, validateCurrentOnHandWeight,
    addMessage, fetchMetrcTransferPackages, getAllPurchasedItemMastersByPartner};

  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (ReceiveTransferPage);
