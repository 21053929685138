/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */

import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {FaAngleDown, FaAngleRight} from 'react-icons/fa';
import TablePageWrapper from '../../../common/grid/TablePageWrapper';
import OrderDetails from './OrderDetails';
import InternationalCurrencyStatic from '../../../common/form/InternationalCurrencyStatic';

const OrdersTable = React.forwardRef((props, ref) => {
  const defaults = {
    data: [],
    per_page: 5
  };

  const settings = Object.assign({}, defaults, props);

  const expandHandler = (row) => {
    props.onExpandOrder(row);
  };

  const expandComponent = (row) => {

    return (
      <OrderDetails
        key={row.id}
        collapseSelf={expandHandler}
        order={row}
        data={row.products}
        ordersWithRefunds={props.ordersWithRefunds}
        refundedOrderIds={props.refundedOrderIds}
        prepackWeights = {props.prepackWeights}
        refundQueue={props.refundQueue}
        onRefundClick={props.onRefundClick}
        nonMedicatedCategoryId={props.nonMedicatedCategoryId}
        restockQueue={props.restockQueue}
        onRestockProduct={props.onRestockProduct}
        onRestockClick={props.onRestockClick}
        onRestockAllClick={props.onRestockAllClick}
        onRefundAllClick={props.onRefundAllClick}
        refundOrder={props.refundOrder}
        page={props.page}
        first={props.first}
        last={props.last}
        getDetailedOrder={props.getDetailedOrder}
        numRecords={props.numRecords}
        registers={props.registers}
        getPaymentModificationPaymentTypes={props.getPaymentModificationPaymentTypes}
        onPaymentSubmit={props.onPaymentSubmit}
        onMmapPaymentSubmit={props.onMmapPaymentSubmit}
        refundedInventoryItems={props.refundedInventoryItems}
        taxes={props.taxes}
        integrationState={props.integrationState}
        refundOrderInitiated={props.refundOrderInitiated}
        restockOrderInitiated={props.restockOrderInitiated}
        setIsRefundAndRestock={props.setIsRefundAndRestock}
        customer={props.customer}
        addMessage={props.addMessage}
      />
    );
  };


  const columns = [
    {
      width: '30px',
      dataSort: false,
      csvFormatter: (cell) => { return ''; },
      formatter: (cell, row) => {
        return props.isExpanded(cell)
          ? (<FaAngleDown/>)
          : (<FaAngleRight/>);
      },
      dataId: 'id',
      searchable: false
    },
    {
      name: 'cultivation.orderHistory.table.orderDate',
      dataId: 'sortable_order_date',
      hidden: false,
      width: '100px',
      dataAlign: 'left',
      headerAlign: 'left',
      formatter: (cell, row) => {
        return row.effective_order_date;
      },
      searchable: false
    },
    {
      name: 'cultivation.orderHistory.table.orderNumber',
      dataId: 'name',
      hidden: false,
      dataSort: true,
      width: '100px',
      dataAlign: 'left',
      headerAlign: 'left',
    },
    {
      name: 'status',
      dataId: 'order_type',
      hidden: false,
      dataSort: true,
      width: '100px',
      dataAlign: 'left',
      headerAlign: 'left',
      formatter: (cell, row) => {
        return I18n.t(`orders.status.${cell}`) + ' - ' + I18n.t(`orders.status.${row.order_status}`);
      },
      searchable: false
    },
    {
      name: 'cultivation.orderHistory.table.totalCollected',
      dataId: 'order_total',
      hidden: false,
      dataSort: true,
      width: '100px',
      dataAlign: 'right',
      headerAlign: 'right',
      searchable: false,
      formatter: (cell, row) => {
        return <InternationalCurrencyStatic>{row.order_total}</InternationalCurrencyStatic>;
      }
    },
    {
      width: '20px',
      dataSort: false,
    },
    {
      name: 'cultivation.orderHistory.table.salesAssociate',
      dataId: 'sales_associate',
      hidden: false,
      dataSort: false,
      width: '100px',
      dataAlign: 'left',
      headerAlign: 'left',
      searchable: false
    },
  ];

  return (
    <TablePageWrapper
      ref={ref}
      data={settings.data}
      ordersWithRefunds={props.ordersWithRefunds}
      columns={columns}
      selectedRows={[]}
      noSelectionMode={true}
      searchPlaceholder={I18n.t('orderNumber')}
      className='order-history-table'
      sort='sortable_order_date desc'
      searchString={props.searchOrderName}
      settingKey={false}
      settings={settings}
      externalSearch={props.handleSearch}
      external={true}
      dataTotalSize={props.dataTotalSize}
      bstProps={{
        trClassName: 'order-history-table-row table-bordered',
        options: {
          sizePerPage: 5,
          onRowClick: expandHandler,
          expandRowBgColor: 'rgb(255, 255, 255)',
          defaultSortName: 'sortable_order_date',
          defaultSortOrder: 'desc'
        },
        selectRow: {},
        expandableRow: () => true,
        expandComponent: expandComponent,
      }}
      handleSelect={() => {}}/>
  );

});

OrdersTable.propTypes = {
  prepackWeights: PropTypes.array,
  ordersWithRefunds: PropTypes.object.isRequired,
  onRestockProduct: PropTypes.func.isRequired,
  refundOrder: PropTypes.object.isRequired,
  isExpanded: PropTypes.func,
  refundedOrderIds: PropTypes.array,
  page: PropTypes.number,
  first: PropTypes.number,
  last: PropTypes.number,
  getDetailedOrder: PropTypes.func.isRequired,
  numRecords: PropTypes.number,
  data: PropTypes.array.isRequired,
  taxes: PropTypes.array.isRequired,
  onExpandOrder: PropTypes.func.isRequired,
  expandedOrders: PropTypes.array.isRequired,
  refundQueue: PropTypes.object.isRequired,
  restockQueue: PropTypes.array.isRequired,
  onRefundClick: PropTypes.func.isRequired,
  onRestockClick: PropTypes.func.isRequired,
  searchOrderName: PropTypes.string,
  registers: PropTypes.array.isRequired,
  getPaymentModificationPaymentTypes: PropTypes.func.isRequired,
  onPaymentSubmit: PropTypes.func.isRequired,
  refundedInventoryItems: PropTypes.array.isRequired,
  integrationState: PropTypes.object.isRequired,
  nonMedicatedCategoryId: PropTypes.number,
  refundOrderInitiated: PropTypes.bool,
  restockOrderInitiated: PropTypes.bool,
  onRestockAllClick: PropTypes.func.isRequired,
  onRefundAllClick: PropTypes.func.isRequired,
  setIsRefundAndRestock: PropTypes.func.isRequired,
  customer: PropTypes.object.isRequired,
  addMessage: PropTypes.func.isRequired
};

export default OrdersTable;
