import {HOME_DELIVERY, CLOSED_LOOP_REGULATOR_ACCESS} from './moduleKeys';
import * as permissions from '../constants/permissions';


export const ANALYTICS_APP = {
  id: 'analytics', name: 'analytics', path: '/reporting/analytics/all',
  glyph: 'stats',
  permissions: [permissions.analytics_reporting],
  paths: [
    {
      name: 'analyticsFavorites',
      path: '/reporting/analytics/favorites'
    },
    {
      name: 'analyticsAllReports',
      path: '/reporting/analytics/all'
    },
    {
      name: 'analyticsRetail',
      path: '/reporting/analytics/retail'
    },
    {
      name: 'analyticsManufacturing',
      path: '/reporting/analytics/manufacturing'
    },
    {
      name: 'analyticsCultivation',
      path: '/reporting/analytics/cultivation'
    },
  ]
};

export const reportingApp = (env) => ({
  id: 'reporting', name: 'reporting',
  glyph: 'signal',
  url: window.location.hostname.includes('leafdatasystems') ? env.utahqlikUrl : env.qlikUrl,
  permissions: ['view_reports', 'view_tier2_reports', 'view_tier3_reports', 'view_tier4_reports']
});

export const APPS = [
  {
    id: 'people', name: 'people',
    glyph: 'user',
    sortIndex: { grow: 1, manufacturing: 1, dispensary: 1 },
    paths: [
      {
        name: 'patientsCreate',
        path: '/patients/create'
      },
      {
        name: 'patientsList',
        path: '/patients'
      },
      {
        name: 'users',
        path: '/users/active'
      },
      {
        name: 'userRoles',
        path: '/users/roles'
      },
      {
        name: 'drivers',
        path: '/drivers/active'
      },
      {
        name: 'vehicles',
        path: '/vehicles'
      },
      {
        name: 'physicians',
        path: '/retail/physicians/active'
      }
    ]
  },
  {
    id: 'checkIn', name: 'checkin', path: '/check-in',
    glyph: 'check',
    sortIndex: { grow: 11, manufacturing: 11, dispensary: 2 },
    paths: [
      {
        name: 'checkIn',
        path: '/check-in'
      }
    ]
  },
  {
    id: 'queue', name: 'queue', path: '/queue/in_store',
    glyph: 'option-horizontal',
    sortIndex: { grow: 11, manufacturing: 11, dispensary: 3 },
    paths: [
      {
        name: 'queueInStore',
        path: '/queue/in_store'
      },
      {
        name: 'queuePickup',
        path: '/queue/pickup'
      },
      {
        name: 'queueDelivery',
        path: '/queue/delivery'
      },
      {
        name: 'queuePayment',
        path: '/queue/payment'
      },

      // TODO: Enable these routes for this ticket: https://akerna.atlassian.net/browse/REBEL-2043
      // {
      //   name: 'queueInStoreMedical',
      //   path: '/queue/in_store/medical'
      // },
      // {
      //   name: 'queueInStoreRecreational',
      //   path: '/queue/in_store/recreational'
      // },
      // {
      //   name: 'queuePickupMedical',
      //   path: '/queue/pickup/medical'
      // },
      // {
      //   name: 'queuePickupRecreational',
      //   path: '/queue/pickup/recreational'
      // },
      // {
      //   name: 'queueDeliveryMedical',
      //   path: '/queue/delivery/medical'
      // },
      // {
      //   name: 'queueDeliveryRecreational',
      //   path: '/queue/delivery/recreational'
      // },
      //
      // {
      //   name: 'queuePaymentMedical',
      //   path: '/queue/payment/medical'
      // },
      // {
      //   name: 'queuePaymentRecreational',
      //   path: '/queue/payment/recreational'
      // }
    ]
  },
  {
    id: 'products', name: 'products', path: '/products',
    glyph: 'th-list',
    sortIndex: { grow: 4, manufacturing: 4, dispensary: 4 },
    paths: [
      {
        name: 'productsCreate',
        path: '/products/create'
      },
      {
        name: 'productsList',
        path: '/products'
      },
      {
        name: 'productsMassModify',
        path: '/products/mass_modify'
      },
      {
        name: 'productMasters',
        path: '/product-masters'
      },
    ]
  },
  {
    id: 'inventory_active', name: 'inventory', path: '/inventory/active',
    glyph: 'th-large',
    sortIndex: { grow: 3, manufacturing: 1, dispensary: 5 },
    paths: [
      {
        name: 'inventory',
        path: '/inventory/active'
      },
    ]
  },
  {
    id: 'inventory', name: 'inventory', path: '/ei/inventory/prerun',
    glyph: 'th-large',
    sortIndex: { grow: 3, manufacturing: 1, dispensary: 5 },
    paths: [
      {
        name: 'inventory',
        path: '/inventory/prerun'
      },
      {
        name: 'finishedProducts',
        path: '/ei/inventory/finished'
      },
      {
        name: 'prerun',
        path: '/ei/inventory/prerun'
      },
      {
        name: 'curing',
        path: '/ei/inventory/curing'
      },
      {
        name: 'infusionConcentrate',
        path: '/ei/inventory/infusionconcentrate'
      },
      {
        name: 'ingredients',
        path: '/inventory/ingredients/active'
      },
      {
        name: 'waste',
        path: '/inventory/waste#'
      },
      {
        name: 'startProcessing',
        path: '/ei/processing/start'
      },
      {
        name: 'startInfusion',
        path: '/ei/infusions/start'
      },
    ],
  },
  {
    id: 'supplyChain', name: 'supplyChain', path: '/supply-chain',
    glyph: 'road',
    sortIndex: { grow: 5, manufacturing: 5, dispensary: 6 },
    paths: [
      {
        name: 'salesOrderCreate',
        path: '/sales-orders/create'
      },
      {
        name: 'purchaseOrderCreate',
        path: '/purchase-orders/create'
      },
      {
        name: 'salesOrderList',
        path: '/sales-orders/active'
      },
      {
        name: 'purchaseOrderList',
        path: '/purchase-orders/active'
      },
      {
        name: 'purchaseOrderMedicatedList',
        path: '/purchase-orders/active/medicated'
      },
      {
        name: 'purchaseOrderNonMedicatedList',
        path: '/purchase-orders/active/non-medicated'
      },
      {
        name: 'inventoryReceiptsList',
        path: '/inventory-receipts'
      },
      {
        name: 'transfersList',
        path: '/transfers/active'
      },
      {
        name: 'supplyChainMappingMetrc',
        path: '/supply-chain-mapping/metrc'
      },
      {
        name: 'supplyChainMapping',
        path: '/supply-chain-mapping/incoming'
      }
    ]
  },
  {
    id: 'delivery', name: 'delivery', path: '/retail/delivery-orders',
    glyph: 'list',
    sortIndex: { grow: 5, manufacturing: 5, dispensary: 7 },
    permissions: ['manage_retail_delivery_orders'],
    modules: [HOME_DELIVERY],
    paths: [
      {
        name: 'deliveryPending',
        path: '/retail/delivery-orders'
      },
      {
        name: 'deliveryOutForDelivery',
        path: '/retail/delivery-orders/out_for_delivery'
      },
      {
        name: 'deliveryCompleted',
        path: '/retail/delivery-orders/completed'
      }
    ]
  },
  {
    id: 'courier_delivery', name: 'delivery', path: '/courier/delivery-orders',
    glyph: 'list',
    sortIndex: { grow: 5, manufacturing: 5, dispensary: 7 },
    permissions: ['manage_courier_deliver_orders'],
  },
  {
    id: 'salesReport', name: 'salesReport', path:'/retail/sales-report/',
    glyph: 'folder-open',
    sortIndex: { grow: 11, manufacturing: 11, dispensary: 8 },
    paths: [
      {
        name: 'salesSummary',
        path: '/retail/sales-report/sales-summary'
      },
      {
        name: 'dailyTransactionsSummary',
        path: '/retail/sales-report/transactions'
      },
      {
        name: 'currentInventorySummary',
        path: '/retail/sales-report/inventory'
      },
      {
        name: 'productSalesSummary',
        path: '/retail/sales-report/products'
      },
      {
        name: 'metrcSalesSummary',
        path: '/retail/sales-report/metrc'
      },
      {
        name: 'cashOnHandSummary',
        path: '/retail/sales-report/cash-on-hand'
      },
      {
        name: 'inventoryTransactionsSummary',
        path: '/retail/sales-report/inventory-transactions'
      },
      {
        name: 'closingReportOpenSummary',
        path: '/retail/sales-report/closing-report/open'
      },
      {
        name: 'closingReportClosedSummary',
        path: '/retail/sales-report/closing-report/closed'
      }
    ]
  },
  {
    id: 'tests', name: 'tests', path: '/test-results/active',
    glyph: 'tint',
    sortIndex: { grow: 6, manufacturing: 6, dispensary: 9 },
    paths: [
      {
        name: 'testResultsList',
        path: '/test-results/active'
      },
      {
        name: 'testResultsHistoricalList',
        path: '/test-results/historical'
      },
      {
        name: 'testResultsAveragesList',
        path: '/test-results/averages'
      },
      {
        name: 'testResultsExportList',
        path: '/test-results/export'
      },
    ]
  },
  {
    id: 'reconciliations', name: 'reconciliations',
    glyph: 'edit',
    sortIndex: { grow: 7, manufacturing: 7, dispensary: 10 },
    paths: [
      {
        name: 'reconciliationsAdjust',
        path: '/reconciliations/adjust'
      },
      {
        name: 'inventoryAudit',
        path: '/inventory/audit'
      },
      {
        name: 'inventorySync',
        path: '/inventory/sync'
      }
    ]
  },
  {
    id: 'accounting', name: 'accounting', path: '/accounting/export',
    glyph: 'briefcase',
    sortIndex: { grow: 9, manufacturing: 9, dispensary: 11 },
    permissions: ['manage_accounting'],
    paths: [
      {
        name: 'accountingExport',
        path: '/accounting/export'
      }
    ]
  },
  {
    id: 'plants', name: 'plants', path: '/plants/propagation',
    glyph: 'leaf',
    sortIndex: { grow: 1, manufacturing: 11, dispensary: 12 },
    paths: [
      {
        name: 'plantsCreate',
        path: '/plants/create'
      },
      {
        name: 'plantsList',
        path: '/plants/propagation'
      },
      {
        name: 'plantsListPropagation',
        path: '/plants/propagation'
      },
      {
        name: 'plantsListVegetation',
        path: '/plants/vegetation'
      },
      {
        name: 'plantsListFlowering',
        path: '/plants/flowering'
      },
      {
        name: 'plantsListInactive',
        path: '/plants/inactive'
      },
    ]
  },
  {
    id: 'harvests', name: 'harvests', path: '/harvests/active',
    glyph: 'scale',
    sortIndex: { grow: 2, manufacturing: 11, dispensary: 12 },
    paths: [
      {
        name: 'harvestsListActive',
        path: '/harvests/active'
      },
      {
        name: 'harvestsListInactive',
        path: '/harvests/inactive'
      }
    ]
  },
  {
    // Hidden if feature_new_packaging_jobs is enabled
    id: 'ei-active-infusion', name: 'activeInfusions', path: '/ei/active-infusions',
    glyph: 'object-align-left',
    sortIndex: { grow: 11, manufacturing: 3, dispensary: 12},
    paths: [
      {
        name: 'infusionsList',
        path: '/ei/active-infusions'
      }
    ]
  },
  {
    // Hidden if feature_new_packaging_jobs is enabled
    id: 'ei-active-processing', name: 'activeProcessing', path: '/ei/active-processing',
    glyph: 'object-align-vertical',
    sortIndex: { grow: 11, manufacturing: 2, dispensary: 12},
    paths: [
      {
        name: 'processingList',
        path: '/ei/active-processing'
      }
    ]
  },
  {
    // Shown if feature_new_packaging_jobs is enabled
    id: 'active-jobs', name: 'activeJobs', path: '/packaging_jobs',
    glyph: 'box-open',
    sortIndex: { grow: 11, manufacturing: 2, dispensary: 12},
    paths: [
      {
        name: 'packagingJobs',
        path: '/packaging_jobs'
      },
      {
        name: 'infusionJobs',
        path: '/active-infusions'
      },
      {
        name: 'processingJobs',
        path: '/active-processing'
      }
    ]
  },
  {
    id: 'integration', name: 'integration', path: '/integration',
    glyph: 'share',
    sortIndex: { grow: 8, manufacturing: 8, dispensary: 12},
    paths: [
      {
        name: 'integrationTracking',
        path: '/integration/tracking'
      },
      {
        name: 'integrationSalesExport',
        path: '/integration/sales-export'
      },
      {
        name: 'integrationApiTransactions',
        path: '/integration/api-transactions'
      }
    ]
  },
  {
    id: 'regulation', name: 'regulation',
    glyph: 'flag',
    sortIndex: { regulator_access: 1 },
    modules: [CLOSED_LOOP_REGULATOR_ACCESS],
    paths: [
      {
        name: 'quarantinedInventory',
        path: '/closed-loop/quarantined-inventory'
      },
      {
        name: 'closedLoopFacilityListing',
        path: '/closed-loop/facilities'
      },
      {
        name: 'closedLoopRegulatedUsersListing',
        path: '/closed-loop/users'
      },
      {
        name: 'closedLoopRegulatedInventoryReceiptsListing',
        path: '/closed-loop/receipts'
      },
      {
        name: 'mmuLimits',
        path: '/closed-loop/mmu-limits'
      },
    ]
  },
  {
    id: 'inspection', name: 'inspection',
    glyph: 'search',
    modules: [CLOSED_LOOP_REGULATOR_ACCESS],
    info: 'nav.comingSoon'
  },
  {
    id: 'setup', name: 'setup', path: '/setup',
    glyph: 'cog',
    paths: [
      {
        name: 'settingsBasic',
        path: '/setup'
      },
      {
        name: 'settingsAdvanced',
        path: '/setup/advanced'
      },
      {
        name: 'settingsLabelManager',
        path: '/labels/manager'
      },
      {
        name: 'settingsPartners',
        path: '/partners/active'
      },
      {
        name: 'settingsIngredients',
        path: '/ingredients/active'
      },
      {
        name: 'settingsAssemblies',
        path: '/ei/assemblies'
      },
      {
        name: 'settingsProcessingTypes',
        path: '/ei/processing-types'
      },
      {
        name: 'settingsCoupons',
        path: '/retail/coupons'
      },
      {
        name: 'settingsRewards',
        path: '/retail/rewards/active'
      }
    ]
  },
  // {
  //   id: 'partners', name: 'partners',
  //   glyph: 'link',
  //   paths: [
  //     {
  //       name: 'MainStem Supplies',
  //       path: 'https://mjfreeway.mainstem.io/shop',
  //       targetBlank: true
  //     }
  //   ]
  // },
  ANALYTICS_APP
];

export const validAppsForRegulatorFacilityType = [
  'regulation',
  'analytics',
  'setup',
  'inspection',
  'people'
];
