import React from 'react';
import get from 'lodash.get';
import map from 'lodash.map';
import filter from 'lodash.filter';
import forEach from 'lodash.foreach';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Button} from 'react-bootstrap';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {push} from 'react-router-redux';
import PermissionWrapper from '../../common/PermissionWrapper';
import * as p from '../../../constants/permissions';
import ReassignConfirmModal from './ReassignConfirmModal';
import {getReassignMode} from '../../../selectors/inventoryReceiptSelectors';
import {applyReassignPackaging} from '../../../actions/inventory-receipt/reassignActions';
import {getPayLoad} from '../../../selectors/fillPurchaseOrderSelectors';


class ReassignSaveButton extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      popup: {
        show: false,
      }
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.getModalProps = this.getModalProps.bind(this);
  }

  toggleModal(flag) {
    this.setState({popup: {show: flag}});
  }

  getModalProps() {
    const {popup} = this.state;
    const {actions, handleSubmit} = this.props;

    return {
      onHide: () => this.toggleModal(false),
      show: popup.show,
      okayButtonProps: {
        show: popup.show,
        onClick: handleSubmit(actions.applyReassignPackaging),
        text: I18n.t('general.confirm')
      },
      cancelButtonProps: {
        show: popup.show,
        onClick: () => this.toggleModal(false),
        text: I18n.t('general.cancel')
      },
    };
  }

  render() {
    const {isReassignMode} = this.props;

    return (isReassignMode) && (
      <PermissionWrapper permissions={[p.manually_adjust_package_item]}>
        <ReassignConfirmModal {...this.getModalProps()} />
        <Button onClick={() => { this.toggleModal(true); }} className='btn btn-primary'>
          {I18n.t('general.save')}
        </Button>
      </PermissionWrapper>
    );
  }
}

ReassignSaveButton.propTypes = {
  actions: PropTypes.object,
  disabled: PropTypes.bool,
  isReassignMode: PropTypes.bool,
};


export default withRouter(connect(
  (state) => ({
    isReassignMode: get(getReassignMode(state), 'enable', false),
  }),
  (dispatch, ownProps) => ({
    actions: {
      applyReassignPackaging() {
        const formValues = ownProps.formValues;

        const items = [];
        const updatedLines = filter(formValues.lines, (line) => line.prev_item_master_id > 0 || line.unit_cost_all_adjust);
        forEach(updatedLines, (line) => {
          forEach(line.inventory, (inv) => {
            const prepack_rows = get(inv, 'prepack_inventory_rows', []);

            if (prepack_rows.length > 0) {
              map(prepack_rows, (row) => {
                items.push({
                  id: get(row, 'inventory_item_id'),
                  line_id: get(row, 'receipt_line_id'),
                  package_id: get(row, 'package_id'),
                  force_transact: true,
                  receipt_line_id: get(row, 'id'),
                  force_update_item_master: get(row, 'item_master_id'),
                });
              });
            } else {
              items.push({
                id: get(inv, 'inventory_item_id'),
                line_id: get(line, 'id'),
                package_id: get(inv, 'package_id'),
                receipt_line_id: get(inv, 'id'),
                force_transact: true,
                force_update_item_master: get(line, 'item_master_id'),
              });
            }
          });
        });

        const { timezone, initialValues, lineTotals, itemMasters, itemMasterChildren, isReturn, purchaseOrder, supplyChainMapping, integrationState } = ownProps;
        const inventoryReceipt = getPayLoad(
          formValues,
          lineTotals,
          itemMasters,
          itemMasterChildren,
          timezone,
          initialValues,
          supplyChainMapping,
          isReturn,
          purchaseOrder,
          integrationState
        );

        const payload = {
          items,
          lines: inventoryReceipt.lines,
        };

        //this should be uncommented when wanting to save
        dispatch(applyReassignPackaging(ownProps.params.id, payload))
          .then(() => {
            dispatch(push('/inventory-receipts/inactive'));
          });
      },
    }
  })
)(ReassignSaveButton));
