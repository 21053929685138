import React from 'react';
import PropTypes from 'prop-types';
import {Col, Row, Button} from 'react-bootstrap';
import {FaMinus} from 'react-icons/fa';
import {Field, FieldArray} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import PrepackLineInventoryRows from './PrepackLineInventoryRows';
import RequestedPrePacks from './RequestedPrePacks';
import TextInput from '../../../../common/form/TextInput';
import InternationalDateTimePickerInput from '../../../../common/form/InternationalDateTimePickerInput';
import StaticPlaceholderInput from '../../../../common/form/StaticPlaceholderInput';
import AddInventoryRow from './common/AddInventoryRow';
import ContentConcealer from '../../../../common/concealers/ContentConcealer';


const PrepackLineInventory = (props) => {

  const {
    fields, line, getValue, partnerFacility, storageLocations, itemMasterChildren, orderLine,
    isLotTracked, locked, editableLinePrice, resetPackageCodeKey, index, touch, itemMaster,
    integrationState, trackingIds, onScannedTrackingId, inventoryTypes, isInitialInventoryCreation,
    onlyEntireQuantityCanBeTransferred, displaySourceFields, hasLinkedTransferDetails, isMetrcStemHoldingsToggled,
    metrcIsImported, change, isReassignMode, hasCostEditPermission
  } = props;

  const {isLeaf, isMetrc, isBiotrack, isCanada, isWaLeaf} = integrationState;
  const inventoryMapped = getValue('contains_medicated') && isLeaf;
  const isMedicated = getValue('contains_medicated');
  const medicatedReceiptRequiresMapping = getValue('medicatedReceiptRequiresMapping');
  const loadedFromSupplyChainMapping = getValue('loadedFromSupplyChainMapping');

  //if there are multiple packages for 1 pp size on 1 item they can choose this and add an expiration date for all related packages
  const updateDateItemExpired = (value, name) => {
    const prePackInventoryRows = getValue(`${name}.prepack_inventory_rows`);

    prePackInventoryRows.forEach((e, idx) => {
      change(`${name}.prepack_inventory_rows[${idx}].date_item_expired`, value);
    });
  };

  const getTotalLineItemPrice = () => {
    let totalSum = 0;
    line.inventory.forEach(item => {
      item.prepack_inventory_rows.forEach(pp_row => {
        totalSum += parseFloat(pp_row.line_total);
      });
    });
    line.line_item_price = totalSum;
    line.unit_price = (totalSum / line.ordered_qty).toFixed(2);
  };


  return (
    <React.Fragment>
      <Row className='prepack-inventory' style={{clear: 'both', borderTop: '1px dotted #ccc', paddingTop: '12px'}}>
        <Col xs={12} style={{padding: '10px', paddingBottom: '16px'}}>
          <ContentConcealer show={!isMedicated || !medicatedReceiptRequiresMapping || !loadedFromSupplyChainMapping}>
            <AddInventoryRow
              line={line}
              push={fields.push}
              partnerFacility={partnerFacility}
              locked={Boolean(locked || inventoryMapped || 0)}
              offset={3}
              integrationState={integrationState}
            />
          </ContentConcealer>
        </Col>
      </Row>
      <Row>
        <Col md={{span: 10, offset: 2}}>
          <RequestedPrePacks orderLine={orderLine} columnWidth={3}/>
        </Col>
      </Row>
      <Row>
        <Col md={{span: 10, offset: 2}}>
          {fields.map((inventoryName, inventoryIndex) => {
            const id = line.inventory && line.inventory[inventoryIndex] && line.inventory[inventoryIndex].id;
            const key = (orderLine && orderLine[inventoryIndex] && orderLine[inventoryIndex].id) || inventoryIndex;
            const inventoryRows = line.inventory && line.inventory[inventoryIndex] && line.inventory[inventoryIndex].prepack_inventory_rows;
            //if there are more than 1 then generate an option to add an expiration date for multiple packages
            const rowLength = inventoryRows.length > 1;
            return (
              <div key={inventoryName}>
                <Row className='prepack-line-inventory' key={key}>

                  <ContentConcealer show={!isMedicated || !medicatedReceiptRequiresMapping || !loadedFromSupplyChainMapping}>
                    {isLotTracked ? <Col md={3}>
                      <Field
                        name={`${inventoryName}.lot_number`}
                        component={TextInput}
                        onBlur={() => {
                          const lotNumber = getValue(`${inventoryName}.lot_number`);
                          fields.forEach((inventoryName) => {
                            touch(`${inventoryName}.package_code`); // Touch so errors show if present
                            const itemMasterId = getValue(`${inventoryName}.item_master_id`);
                            resetPackageCodeKey(inventoryName, itemMasterId, lotNumber, getValue(`${inventoryName}.package_code`), inventoryName);
                          });
                        }}
                        props={{
                          label: I18n.t('cultivation.transfers.form.batch'),
                          disabled: locked,
                          isRequired: true
                        }}
                      />
                    </Col> : null}
                    <Col md={3}>
                      <StaticPlaceholderInput label='Package ID' placeholder='Complete On Inventory Line' />
                    </Col>
                  </ContentConcealer>

                  <Col md={3}>
                    <InternationalDateTimePickerInput
                      name={`${inventoryName}.date_item_created`}
                      props={{
                        label: I18n.t('cultivation.transfers.form.createdDate'),
                        inputProps: {disabled: locked},
                        isRequired: false,
                      }}/>
                  </Col>
                  {/* if there is more than 1 package per PP size */}
                  {rowLength && !locked ?
                    <Col md={9}>
                      <Row>
                        <Col md={12}>
                          <div style={{marginBottom: '7px'}}>{I18n.t('cultivation.transfers.form.expirationDateLinePP', {pp: getValue(`${inventoryName}.prepack_inventory_rows[0].name`)})}</div>
                        </Col>
                        <Col md={3}>
                          <InternationalDateTimePickerInput
                            name={`${inventoryName}.date_item_expired`}
                            props={{
                              inputProps: {disabled: locked},
                              isRequired: false,
                              enableTodayButton: false,
                              onChange: (value) => {
                                change(`${inventoryName}.date_item_expired`, value);
                                updateDateItemExpired(value, `${inventoryName}`);
                              }
                            }}/>
                        </Col>
                      </Row>
                    </Col> : ''}
                  {isMetrc && <Col lg={3}>
                    <InternationalDateTimePickerInput
                      name={`${inventoryName}.date_item_use_by`}
                      props={{
                        label: I18n.t('cultivation.transfers.form.useByDate'),
                        inputProps: {disabled: locked},
                        enableTodayButton: false,
                      }}/>
                  </Col>}
                </Row>
                <Row>
                  {/* We want to pass the global Leaf ID value to the back end without letting the user edit it. */}
                  {isLeaf || isBiotrack ? <Col md={6} style={{display: 'none'}}>
                    <Field
                      name={`${inventoryName}.state_integration_tracking_id`}
                      component={TextInput}
                      props={{
                        label:I18n.t('cultivation.transfers.form.leafGlobalId'),
                        disabled: true,
                      }}
                    />
                  </Col> : null}
                  <div style={{marginTop: '12px'}}>
                    <FieldArray
                      name={`${inventoryName}.prepack_inventory_rows`}
                      fieldName={inventoryName}
                      component={PrepackLineInventoryRows}
                      inventoryRows={inventoryRows || []}
                      line={line}
                      getValue={getValue}
                      storageLocations={storageLocations}
                      isLotTracked={isLotTracked}
                      itemMasterChildren={itemMasterChildren}
                      partnerFacility={partnerFacility}
                      locked={locked}
                      editableLinePrice={editableLinePrice}
                      index={index}
                      touch={touch}
                      resetPackageCodeKey={resetPackageCodeKey}
                      trackingIds={trackingIds}
                      parentFieldName={inventoryName}
                      isInitialInventoryCreation={isInitialInventoryCreation}
                      isBiotrack={isBiotrack}
                      isCanada={isCanada}
                      isMetrc={isMetrc}
                      isWaLeaf={isWaLeaf}
                      inventoryTypes={inventoryTypes}
                      onScannedTrackingId={onScannedTrackingId}
                      itemMaster={itemMaster}
                      onlyEntireQuantityCanBeTransferred={onlyEntireQuantityCanBeTransferred}
                      displaySourceFields={displaySourceFields}
                      hasLinkedTransferDetails={hasLinkedTransferDetails}
                      isMetrcStemHoldingsToggled={isMetrcStemHoldingsToggled}
                      metrcIsImported={metrcIsImported}
                      isReassignMode={isReassignMode}
                      change={change}
                      getTotalLineItemPrice={getTotalLineItemPrice}
                      hasCostEditPermission={hasCostEditPermission}
                    />
                  </div>
                  <ContentConcealer show={!isBiotrack  && (!isMedicated || !medicatedReceiptRequiresMapping || !loadedFromSupplyChainMapping)}>
                    <Col xs={12}>
                      <div className='float-right remove-row'>
                        <Button variant='primary' size='sm' onClick={() => fields.remove(inventoryIndex)} disabled={inventoryMapped || id > 0}>
                          <FaMinus/>
                        </Button>
                      </div>
                    </Col>
                  </ContentConcealer>
                </Row>
              </div>
            );
          })}
        </Col>
      </Row>
    </React.Fragment>
  );
};

PrepackLineInventory.propTypes = {
  fields: PropTypes.object.isRequired,
  line: PropTypes.object.isRequired,
  getValue: PropTypes.func.isRequired,
  itemMasterChildren: PropTypes.array.isRequired,
  storageLocations: PropTypes.array.isRequired,
  itemMasters: PropTypes.array.isRequired,
  orderLine: PropTypes.object.isRequired,
  partnerFacility: PropTypes.object,
  isLotTracked: PropTypes.bool.isRequired,
  locked: PropTypes.bool.isRequired,
  editableLinePrice: PropTypes.bool.isRequired,
  resetPackageCodeKey: PropTypes.func.isRequired,
  itemMaster: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  touch: PropTypes.func.isRequired,
  integrationState: PropTypes.object.isRequired,
  trackingIds: PropTypes.array.isRequired,
  onScannedTrackingId: PropTypes.func.isRequired,
  isInitialInventoryCreation: PropTypes.bool,
  inventoryTypes: PropTypes.array.isRequired,
  onlyEntireQuantityCanBeTransferred: PropTypes.bool.isRequired,
  displaySourceFields: PropTypes.bool.isRequired,
  hasLinkedTransferDetails: PropTypes.bool.isRequired,
  isMetrcStemHoldingsToggled: PropTypes.bool,
  metrcIsImported: PropTypes.bool,
  change: PropTypes.func.isRequired,
  isReassignMode: PropTypes.bool,
  totalLineItemPrice: PropTypes.func,
  hasCostEditPermission: PropTypes.bool.isRequired
};

export default PrepackLineInventory;
