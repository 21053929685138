import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { I18n } from 'react-redux-i18n';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import {getConsumerLimits} from '../../../actions/consumer/limits';
import * as itemNames from '../../../constants/itemNames';
import {addTooltip} from '../../../util/tooltipHelper';

export class MmuLimitClass extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
  }

  componentDidMount() {
    // Only retrieve consumer limits if not yet set or customer ID has changed
    if (!Object.keys(this.props.customerLimits).length || this.props.currentCustomerId !== this.props.customerId) {
      this.props.actions.getConsumerLimits(this.props.customerId)
        .then(() => {
          this.setState('currentCustomerId', this.props.customerId);
        });
    }
  }

  render () {
    const remaining = get(this.props.customerLimits, 'limits.remaining');
    return (
      <React.Fragment>
        {addTooltip(
        <div className='mmu-sign'>
            <div className='mmu-sign-font mmu-limit'>MMU</div>
            <div className='mmu-sign-font mmu-limit-small'>remaining</div>
            <div className={remaining >= 100 ? 'mmu-sign-font mmu-limit-number-small' : 'mmu-sign-font mmu-limit-number'}>{remaining ? remaining.toFixed(1) : <span className='blink'>?</span>}</div>
        </div>, I18n.t('closedLoop.salesSettings.patientLookup.clarification'))}
      </React.Fragment>
    );
  }
}

MmuLimitClass.propTypes = {
  customerId: PropTypes.number.isRequired,
  customerLimits: PropTypes.object,
  currentCustomerId: PropTypes.number,
};

function mapStateToProps(state) {
  return {
    customerLimits: state[itemNames.customerLimits],
    currentCustomerId: state['currentCustomerId']
  };
}
function mapDispatchToProps(dispatch) {
  const actions = Object.assign({}, {getConsumerLimits});
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(MmuLimitClass);
