import {createSelector} from 'reselect';
import get from 'lodash.get';
import {I18n} from 'react-redux-i18n';
import keyBy from 'lodash.keyby';
import * as itemNames from '../../constants/itemNames';
import * as dataNames from '../../constants/dataNames';
import {isPaLeafIntegrator, isLeafIntegrator} from '../integration/leafSelectors';
import {getIntegrationState} from '../integration/integrationSelectors';
import {getTimezone} from '../timezoneSelectors';
import {convertDbDateToFormInputDate} from '../../util/dateHelpers';
import {getArchiveInactiveAfter} from '../plantsSelectors';
import {displayQty} from '../uomsSelectors';
import { isFeatureEnabled } from '../featureToggles';
import {getHighestRankedEntityLock, getIntegrationEntityLocks, parseEntityLocksFromJson} from '../../util/entity_locks';

const getOnActivateClick = (_, props) => props.onActivateClick;
const getOnCreateLot = (_, props) => props.onCreateLot;
const getSelectedHarvestId = (_, props) => props.selectedHarvestId ? props.selectedHarvestId : 0;
const getOnUpdateSearch = (_, props) => props.onUpdateSearch;
const getCanSplitHarvest = (_, props) => props && props.canSplitHarvest !== undefined ? props.canSplitHarvest : false;
const getCanCreateLots = (_, props) => props && props.canCreateLots !== undefined ? props.canCreateLots : true;
const getSettings = state => state.requireLabResultForBatch;

export const getHarvest = state => state[itemNames.harvest];
export const getHarvestMapping = state => state[itemNames.harvestBatchMapping];
export const getLabResults = state => state[dataNames.testResults];
export const getLots = (state) => state[dataNames.lots];

export const getHarvestExternalId = createSelector(
  [getHarvest, getHarvestMapping, getIntegrationState],
  (harvest, mapping, {isLeaf, isMetrc}) => {
    return (isLeaf || isMetrc) && harvest.id === Number(mapping.internal_identifier) && mapping.external_identifier || null;
  }
);

export const getHarvests = state => state[dataNames.harvests];

export const normalizeHarvestBatch = timezone => harvest => {
  return {
    ...harvest,
    harvested_at_display: convertDbDateToFormInputDate(harvest.harvested_at, timezone),
  };
};

export const getNormalizedHarvests = createSelector(
  [getHarvests, getTimezone],
  (harvests, timezone) => harvests.map(normalizeHarvestBatch(timezone))
);

const isServiceFirstHarvestBatchesEnabled = (isFeatureEnabled) => {
  const toggle = 'feature_service_first_harvest_batches_listing';
  return isFeatureEnabled(toggle);
};

export const getHarvestListingData = createSelector(
  [getNormalizedHarvests, getLots, getLabResults, isPaLeafIntegrator, displayQty, isFeatureEnabled],
  (harvests, lots, labResults, isPaLeaf, displayQty, isFeatureEnabled) => {

    const serviceFirstIsEnabled = isServiceFirstHarvestBatchesEnabled(isFeatureEnabled);

    let useHarvestBatches = harvests;
    if (serviceFirstIsEnabled) {
      // Inject packages into harvest batches previously provided by solr cross db queries
      const lotsHash = keyBy(lots, 'source_id');
      useHarvestBatches = harvests.map((harvest) => {
        const packages = get(lotsHash, `${harvest.id}.simple_packages`, []);
        return {
          ...harvest,
          packages
        };
      });
    }

    return useHarvestBatches.reduce(
      (acc, harvest) => {
        const sortDate = harvest && harvest.batch_name ? harvest.batch_name.replace(/\D+/g, '') : '';
        const sortTail = harvest && harvest.batch_name ? harvest.batch_name.split(sortDate).pop() : '';
        const packages = harvest.packages ? (harvest.packages && typeof harvest.packages === 'string') ? JSON.parse(harvest.packages) : harvest.packages : [];

        const findLatestTestResult = (packages) => {
          return packages.reduce((acc, package_data) => {
            const labResult = labResults.find(labResult => labResult.package_id === package_data.package_id && labResult.is_latest_lab_result);
            if (
              (!acc && labResult)
              || (labResult && labResult.testing_date > acc.testing_date)
              || (labResult && labResult.testing_date === acc.testing_date && labResult.id > acc.id)
            ) {
              acc = labResult;
            }
            return acc;
          }, null);
        };

        const getHarvestTestStatus = (packages) => {
          const latest_lab_result = findLatestTestResult(packages);
          return (latest_lab_result && latest_lab_result.status) ? latest_lab_result.status : '';
        };

        const entityLocks = Array.isArray(harvest.entity_locks) ?
          harvest.entity_locks :
          parseEntityLocksFromJson(harvest.entity_locks);

        return acc.concat({
          ...harvest,
          highest_ranked_entity_lock: getHighestRankedEntityLock(entityLocks),
          integration_entity_locks: getIntegrationEntityLocks(entityLocks),
          calculated_moisture_loss: Number(get(harvest, 'calculated_moisture_loss', 0)).toFixed(2),
          sort_date: sortDate,
          sort_tail: sortTail,
          packages,
          has_sample: !!packages.find(package_data => package_data.is_sample),
          test_result_status: getHarvestTestStatus(packages),
          has_test_result: !!packages.find(package_data => labResults.find(testResult => testResult.package_id === package_data.package_id)),
          total_waste_recorded: displayQty(harvest.total_waste_recorded)
        });
      },
      []
    );
  });

export const getHarvestIdsWhereIsLocked = createSelector(
  getHarvestListingData,
  harvestsData => {
    return harvestsData.reduce((lockedIds, harvestData) => {
      if (harvestData.is_locked) lockedIds.push(harvestData.id);
      return lockedIds;
    }, []);
  }
);

export const getSelectedHarvests = state => state.selectedHarvests;
export const getSelectedHarvest = createSelector(
  getSelectedHarvests,
  harvests => harvests[0] || {}
);

export const getSelectedHarvestIds = createSelector(getSelectedHarvests, selectedHarvests => selectedHarvests.map(selectedPlant => selectedPlant.id));

export const getActivateInitialValues = createSelector(
  [getSelectedHarvests],
  harvests => {
    return {
      items: harvests.map(harvest => ({
        id: harvest.id,
        batch_name: harvest.batch_name,
        current_weight: String(harvest.current_weight),
        uom: harvest.uom_display,
        inventory_location_id: harvest.inventory_location_id,
      }))
    };
  }
);

const isCreateLotsDisabled = createSelector(
  getSelectedHarvests, isLeafIntegrator, (harvests, isLeafIntegrator) => {
    return isLeafIntegrator && !harvests.some(harvest => harvest.final_weight_recorded);
  }
);

const isActivateLotsHidden = createSelector([isPaLeafIntegrator, isLeafIntegrator], (isPaLeaf, isLeaf) => {
  return !isPaLeaf && isLeaf;
});

export const getHarvestPlantsHarvests = createSelector(
  [getHarvests, getIntegrationState],
  (harvests, {isBiotrack}) => harvests.filter(harvest => !harvest.final_weight_recorded && !isBiotrack)
);

export const getHarvestsPageTabs = createSelector(
  [isCreateLotsDisabled, isActivateLotsHidden, getOnActivateClick, getIntegrationState, getOnCreateLot, getOnUpdateSearch, getCanSplitHarvest, getCanCreateLots, getSelectedHarvestId, getArchiveInactiveAfter, isFeatureEnabled],
  (isCreateLotsDisabled, isActivateLotsHidden, onActivateClick, integrationState, onCreateLot, updateSearch, canSplitHarvest, canCreateLots, selectedHarvestId, archiveInactiveAfter, isFeatureEnabled) => {

    const serviceFirstIsEnabled = isServiceFirstHarvestBatchesEnabled(isFeatureEnabled);

    return [
      {id: 'activeHarvestsTab', eventKey: 'active', title: 'harvestBatch.nav.active', actions: [
        {id: 'createHarvestPackages', func: onCreateLot, text: 'plants.actions.createLots' , glyph: 'plus-sign', requireSelect: true, disabled: !canCreateLots},
        {id: 'modifyHarvestBatch', path:'/harvests/modify' , text: 'plants.actions.modifyHarvestBatch' , glyph: 'th-list', requireSelect: true},
        {id: 'splitHarvestBatch', path: `/harvests/${selectedHarvestId}/split`, text: 'products.actions.splitHarvestBatch' , glyph: 'duplicate', requireSelect: true, hide: !integrationState.isPaLeaf, disabled: !canSplitHarvest},
        {id: 'addTestResults', path:'/test-results/add' , text: 'products.actions.addTestResults' , glyph: 'tint', requireSelect: false, hide: !integrationState.isMetrc},
        {id: 'updateSearch', hide: serviceFirstIsEnabled, func: updateSearch , text: 'plants.actions.updateSearch' , glyph: 'arrow-up', variant:'warning', requireSelect: false}
      ]},
      {id: 'inactiveHarvestsTab', eventKey: 'inactive', title: 'harvestBatch.nav.inactive', actions: [
        {id: 'activateBatch', hide: isActivateLotsHidden, func: onActivateClick, text: 'harvestBatch.actions.activate', requireSelect: true},
        {id: 'updateSearch', hide: serviceFirstIsEnabled, func: updateSearch , text: 'plants.actions.updateSearch' , glyph: 'arrow-up', variant:'warning', requireSelect: false}
      ],
      description: archiveInactiveAfter > 0 ? I18n.t('cultivation.finishedProduct.archivedInventoryDescription', {days: archiveInactiveAfter}) : undefined
      },
    ];
  }
);

export const getLabResultForHarvestsetting = createSelector([getSettings], (settings) => {
  if (settings && settings['cult_batch_packaging_requires_passed_lab']) {
    return settings['cult_batch_packaging_requires_passed_lab'].value;
  }
  return true;
});
